import sec1Icon1 from '../../../@assets/footerPage/sec1Icon1.svg';
import sec1Icon2 from '../../../@assets/footerPage/sec1Icon2.svg';
import sec1Icon3 from '../../../@assets/footerPage/sec1Icon3.svg';
import sec1Icon4 from '../../../@assets/footerPage/sec1Icon4.svg';
import sec1Icon5 from '../../../@assets/footerPage/sec1Icon5.svg';
import sec1Icon6 from '../../../@assets/footerPage/sec1Icon6.svg';
import sec1Icon7 from '../../../@assets/footerPage/sec1Icon7.svg';
import sec1Icon8 from '../../../@assets/footerPage/sec1Icon8.svg';
import sec2Icon1 from '../../../@assets/footerPage/sec2Icon1.svg';
import sec2Icon2 from '../../../@assets/footerPage/sec2Icon2.svg';
import sec2Icon3 from '../../../@assets/footerPage/sec2Icon3.svg';
import sec2Icon4 from '../../../@assets/footerPage/sec2Icon4.svg';
import sec2Icon5 from '../../../@assets/footerPage/sec2Icon5.svg';
import sec2Icon6 from '../../../@assets/footerPage/sec2Icon6.svg';
import sec2Icon7 from '../../../@assets/footerPage/sec2Icon7.svg';
import { useSelector } from 'react-redux';

const infoData = {
  sec1: [
    {
      img: sec1Icon1,
      title: '가입대상',
      p1: '차량연식 7년 이내, 14만km 이내 차량\n(최초차량등록일 기준)',
      p2: '[가입제외차량] 유상운송차량, 특수차량(엠뷸런스, 통학버스, 튜닝, 특장차 등), 전기차, 친환경차, 신차가격 2억 초과 차량',
      btnName: false,
    },
    {
      img: sec1Icon2,
      title: '보증기간',
      p1: '6개월 10,000km',
      p2: '보증개시는 가입일로부터 적용 보장개시 후 유예기간 1개월 적용(유예기간은 보장기간에 포함) 단, 제조사보증이 남아있을시 제조사보증으로 우선처리',
      btnName: false,
      delay: '200',
    },
    {
      img: sec1Icon3,
      title: '보증범위',
      p1: '5대 주요부품(엔진/변속기/조향장치/제동장치/공조장치)',
      p2: '더 자세한 보장 범위는 아래에서 확인하세요.',
      btnName: '더 알아보기',
      link: '#',
      delay: '400',
    },
    {
      img: sec1Icon4,
      title: '보장한도',
      p1: '국산차 200만원, 수입차 300만원',
      btnName: false,
      delay: '600',
    },
    {
      img: sec1Icon5,
      title: '자기부담금',
      p1: '국산차 10만원/건, 수입차 20만원/건',
      btnName: '견적보기',
      link: '/for-check/step1',
      delay: '800',
    },
    {
      img: sec1Icon6,
      title: '정비네트워크',
      p1: '전국 KB캐피탈 지정정비업체',
      p2: '보증수리시 중고부품 또는 AS용 재생부품이 사용 될 수 있습니다.',
      btnName: false,
      delay: '1000',
    },
    {
      img: sec1Icon7,
      title: '가입상담 및 고장접수처',
      p1: '대표콜센터 1661-0850',
      p2: '고장접수 후 7일 이내 입고 및 수리 진행 통화가능시간 : 평일 오전 9시 ~ 오후 6시',
      btnName: '가입하기',
      link: '#',
      delay: '1200',
    },
    {
      img: sec1Icon8,
      title: '부가서비스',
      p1: '엔진오일 방문교환(1회/수입차 제외)',
      p2: '보장기간 내 사용가능(유예기간 미적용)',
      btnName: false,
      delay: '1400',
    },
  ],
  sec2: [
    {
      title: '엔진',
      p: '실린더블록 및 내부구성품, 크랭크샤프트, 로드 및 메인베어링, 캠베어링, 익스팬션 플러그, 피스톤 및 피스톤링, 커넥팅로드, 캠샤프트, 실린더 헤드, 밸브 및 가이드, 밸브스프링, 로커암 및 푸쉬로드, 풀리, 타이밍체인 및 스프라켓, 타이밍체인 하우징, 흡/배기 매니폴드, 플라이휠, 밸런스샤프트, 하모닉밸런스 및 리테이너 볼트, 크랭크 샤프트폴리, 오일팬, 오일펌프, 오일펌프 및 프레셔 릴리브밸브, 엔진오일쿨러, 오일필터 어댑터 및 하우징, 워터펌프, 써모스탯 및 하우징',
    },
    {
      title: '미션',
      p: '미션케이스 및 내부구성부품, 트랜스퍼케이스 및 내부구성부품, 오일펌프, 밸브바디, 토크컨버터, 샤프트, 밴드, 드럼 기어세트, 베어링, 부싱, 솔레노이드 밸브, 메인샤프트 및 기어세트, 시프트포크, 싱크로나이져링, 오일 레벨게이지 및 튜브, 드라이브 플레이트(오토), 내부디스크(오토)',
    },
    {
      title: '제동장치',
      p: 'ABS모듈, 부스터, 캘리퍼, 마스터/휠 실린더, 브레이크 호스, EPB(전자식주차브레이크)',
    },
    {
      title: '조향장치',
      p: '파워펌프, 스티어링기어',
    },
    {
      title: '일반부펌',
      p: '라디에이터(팬모터), 냉각팬/컨덴서팬, 엔진 아이들러/텐셔너, ECU, TCU, 에어컨 관련 부품(에어컨컴프, 풀리, 파이프, 호스, 에어컨콘덴서, 리시버탱크, 익스팬션밸브, O링류), 히터코어(서미스터, 히터콘트롤유닛, 히터모터, 히터팬), 블로우모터(모드 엑츄에이터, 템퍼레이처센서), 에바포레이터, 고압펌프, 차동장치/액슬하우징, 종감속기어, 피니언기어, 액슬축',
    },
  ],

  sec2Edge: [
    {
      span: '- 소모성 부품)',
      p: '타이밍밸트/팬밸트 등 밸트류 일체, 엔진오일/브레이크오일/리어액슬오일 등 오릴류 일체, 오일필터/에어클리너필터/연료필터/에어컨필터 등 필터류 일체, 스파크플러그/글로우플러그 등 플러그류 일체, 퓨즈류, 벌브(램프)류, 고압케이블, 배터리, 브레이크패드, 라이닝슈, 냉각수, 에어컨가스, 와이퍼블레이드 등',
    },
    {
      span: '- 트림류)',
      p: '타이밍밸트/팬밸트 등 밸트류 일체, 엔진오일/브레이크오일/리어액슬오일 등 오릴류 일체, 오일필터/에어클리너필터/연료필터/에어컨필터 등 필터류 일체, 스파크플러그/글로우플러그 등 플러그류 일체, 퓨즈류, 벌브(램프)류, 고압케이블, 배터리, 브레이크패드, 라이닝슈, 냉각수, 에어컨가스, 와이퍼블레이드 등',
    },
    { p: '- 프론트&리어 시트트랙(레일), 플로어카펫, 매트, 유리일체 등' },
    { p: '- 휠 발란스, 휠 얼라이먼트, 타이어, 타이어위치교환 등' },
    { p: '- 하이브리드 부품' },
    {
      p: '- 가스켓 부위의 누수 및 누유, 통상적인 마모로 인한 누유, 가스켓 접합 부품의 노유',
    },
    { p: '- 외부 충격에 의한 고장, 단순 부식, 변색, 헐거워짐, 벗겨짐 등' },
    {
      p: '- 품질 및 기능상 영향이 없는 감각적 손해(소음, 진동, 냄새, 외관, 작동감각 등)',
    },
  ],

  sec3: [
    {
      img: sec2Icon1,
      title: '콜센터 접수',
      p1: '① 현 주행거리 및 고장증상 확인',
      p2: '② 가입상품별 보장항목 확인 ',
      flow: true,
    },
    {
      img: sec2Icon2,
      title: '지정정비업체 안내',
      p1: '① 고객에게 업체정보 문자발송',
      p2: '② 지정업체에 [차량재원/고장증상/보상담당자] 문자발송 ',
      flow: true,
      delay: '200',
    },
    {
      img: sec2Icon3,
      title: '차량점검',
      p1: '- 보상담당자에게 고장부위/계기판 사진, 소견서, 견적서 발송',
      p2: '- 제조사 보증 가능여부 확인',
      flow: true,
      delay: '400',
    },
    {
      img: sec2Icon4,
      title: '점검내역 검토',
      p1: '- 점검내역 및 보장항목 검토 후 지정업체에 피드백',
      p2: '- 제조사 보증여부 확인',
      flow: true,
      delay: '600',
    },
    {
      img: sec2Icon5,
      title: '보상진행안내',
      p1: '① 보장가능 여부 및 자기부담금 안내 → 제조사 보증에 해당되는 품목일 경우 제조사 보증수리 안내',
      p2: '② 대차가능 상품일 경우 필요시 대차연계',
      flow: true,
      delay: '800',
    },
    {
      img: sec2Icon6,
      title: '수리진행',
      p1: '- 수리 후 청구',
      p2: false,
      flow: true,
      delay: '1000',
    },
    {
      img: sec2Icon7,
      title: '출고',
      p1: '자기부담금 결제',
      p2: false,
      flow: false,
      delay: '1200',
    },
  ],
};

export default infoData;
