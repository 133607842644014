import React from 'react';
import { useNavigate } from 'react-router-dom';
import PathBar from './PathBar';
import styles from './signup.module.css';

function SignUp1() {
  const navigate = useNavigate();
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <PathBar />

        <div className={styles.sign1_title}>
          <h1>서비스 가입절차를 시작합니다.</h1>
          <p>결제는 별도로 진행됩니다. 부담없이 관심있는 서비스를 선택하세요.</p>
        </div>
        <ul className={styles.sign1_infoBox}>
          <li>1. 주식회사 스타오토케어가 운영하는 서비스 입니다.</li>
          <li>2. 자동차 5대 주요계통의 보증 연장프로그램 입니다.</li>
          <li>3. 가입일로 부터 6개월 / 10,000 Km 를 보증합니다.</li>
          <li>4. 고장 접수 후 7일 이내에 입고 및 수리를 진행합니다.</li>
          <li>5. 전국 KB캐피탈 지정 정비업체에서 수행합니다.</li>
        </ul>

        <button type="button" onClick={() => navigate('/sign-up2')} className={styles.btn}>
          다음
        </button>
      </div>
    </section>
  );
}

export default SignUp1;
