import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Banner from './banner/Banner';
import styles from './home.module.css';
import sec1Icon1 from '../../../@assets/home/sec1Icon1.svg';
import sec1Icon2 from '../../../@assets/home/sec1Icon2.svg';
import sec1Icon3 from '../../../@assets/home/sec1Icon3.svg';
import sec2Logo from '../../../@assets/home/sec2Logo.svg';
import sec2Icon1 from '../../../@assets/home/sec2Icon1.svg';
import sec2Icon2 from '../../../@assets/home/sec2Icon2.svg';
import sec2Icon3 from '../../../@assets/home/sec2Icon3.svg';
import sec2Icon4 from '../../../@assets/home/sec2Icon4.svg';
import numb1 from '../../../@assets/home/numb1.svg';
import numb2 from '../../../@assets/home/numb2.svg';

function Home() {
  const profileData = useSelector(({ user }) => user.profile.data);

  return (
    <section className={styles.wrapper}>
      <div className={styles.sec1_wrapper}>
        <div className={styles.sec1_container}>
          <Banner />

          {/* 섹션1_박스 */}
          <div className={styles.box_wrapper}>
            <div className={styles.box_container}>
              <h1>
                <span style={{ color: '#F7AE1D' }}>KB</span>
                <span style={{ color: '#595045' }}> 차차차</span>에서만 누리는
                <br />
                특별한 안심보증 서비스
              </h1>
              <p>자동차 5대 주요계통 (엔진/미션/제동장치/조향장치/일반부품)의 보증연장 프로그램</p>

              <ul className={styles.content_wrap}>
                {contentData.cont1.map((item, i) => {
                  return (
                    <li
                      key={i}
                      data-aos="zoom-in"
                      data-aos-duration="900"
                      data-aos-delay={item.delay}
                    >
                      <img src={item.img} alt="" />
                      <h1>{item.t1}</h1>
                      <p>{item.t2}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* 섹션2 */}
      <div className={styles.sec2_wrapper}>
        <div className={styles.sec2_container}>
          <h1>안심 보증 수리는 이렇게 진행됩니다.</h1>
          <p>안심보증 프로그램 운영사</p>
          <img src={sec2Logo} alt="" />
          <ul className={styles.content2_wrap}>
            {contentData.cont2.map((item, i) => {
              return (
                <li
                  className={styles.cont_wrapper}
                  key={i}
                  data-aos="zoom-in-up"
                  data-aos-duration="900"
                  data-aos-delay={item.delay}
                >
                  <img src={item.icon} alt="" />
                  <h1>{item.title}</h1>
                  <p>
                    <span></span>
                    {item.t1}
                  </p>
                  <p>
                    {item.t2 && <span></span>}
                    {item.t2}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {/* 섹션3 */}
      <div className={styles.sec3_wrapper}>
        <div className={styles.sec3_container}>
          <h1>
            <span style={{ color: '#F7AE1D' }}>KB</span>
            <span style={{ color: '#595045' }}> 차차차</span>
            <br />
            프로모션 패키지 선택
          </h1>
          <p>보증시 1개월 내 신청</p>
          <ul className={styles.sec3_numb_wrap}>
            {contentData.cont3.map((item, i) => {
              return (
                <li key={i} data-aos="zoom" data-aos-duration="1200" data-aos-delay={item.delay}>
                  <Link to="/sign-up1">
                    <img src={item.icon} alt="" />
                    <p>{item.title}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
          <div
            className={styles.sec3_btnBox}
            data-aos="zoom"
            data-aos-duration="1200"
            data-aos-delay="400"
          >
            <Link to="/for-check/step1" className={styles.sec3_btn1}>
              보증서비스 견적보기
            </Link>
            {!profileData && (
              <Link to="/kbc/sign-up1?type=A" className={styles.sec3_btn2}>
                서비스 가입하기
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;

const contentData = {
  cont1: [
    {
      img: sec1Icon1,
      t1: '믿고 맡기는 서비스',
      t2: '전국 KB캐피탈 지정정비업체에 \n믿고 맡길 수 있습니다.',
    },
    {
      img: sec1Icon2,
      t1: '보다 긴 마일리지 적용 보장',
      t2: '가입일로부터 6개월/10,000KM의 \n넉넉한 보증 마일리지 적용',
      delay: '250',
    },
    {
      img: sec1Icon3,
      t1: '신속한 보증 수리',
      t2: '고장접수 후 7일 이내에 \n입고 및 수리를 진행합니다.',
      delay: '500',
    },
  ],
  cont2: [
    {
      icon: sec2Icon1,
      title: '접수 및 지정 업체 안내',
      t1: '현 주행거리 및 고장증상 확인',
      t2: '가입상품별 보장항목 확인',
    },
    {
      icon: sec2Icon2,
      title: '차량점검 및 내역 검토',
      t1: '보상담당자에게 고장부위/계기판 사진, 소견서, 견적서 발송',
      t2: '제조사 보증 가능여부 확인',
      delay: '200',
    },
    {
      icon: sec2Icon3,
      title: '보상진행안내',
      t1: '보장가능 여부 및 자기부담금 안내',
      delay: '600',
    },
    {
      icon: sec2Icon4,
      title: '수리후 출고',
      t1: '수리 후 청구',
      delay: '800',
    },
  ],
  cont3: [
    {
      icon: numb1,
      title: '안심보증프로그램 + 컨디션체크 / 엔진오일 방문점검 서비스(국산차 한정)',
      delay: '100',
    },
    {
      icon: numb2,
      title: '안심보증프로그램 + 새차처럼 / 코일 카 매트 1대분 지급 (택배발송)',
      delay: '300',
    },
  ],
};
