import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './assurance.module.css';
import bg from '../../../../@assets/myPage/bg.png';
import moment from 'moment';
import { numberWithCommas } from '../../../utils/utilityFunctions';

function Assurance() {
  const profileData = useSelector(({ user }) => user.profile.data);
  // console.log(profileData)
  // console.log(profileData.paidAmount)
  // console.log(profileData.paymentDate)
  // console.log(moment(profileData.paymentDate, 'YYYY-MM-DD HH:mm:ss').add(6, 'M').format('YYYY-MM-DD'))
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <h1 className={styles.ok_title}>🎉 가입을 축하합니다!</h1>
        <h1 className={styles.title}>
          연장보증서비스 <span>(EW)</span>
          <br /> 가입 보증서
        </h1>
        <p className={styles.subTitle}>(주)스타오토케어 연증보증 가입상품정보</p>

        {/* 첫번째 데이터 */}
        <div className={styles.sec1_wrap}>
          <ul className={styles.sec1_box}>
            <li>
              <p>상품명</p>
              <h1>Star 안심보증</h1>
            </li>
            <li>
              <p>보증기간</p>
              <h1>
                {moment(profileData.paymentDate, 'YYYY-MM-DD HH:mm:ss')
                  .add(6, 'M')
                  .format('YYYY-MM-DD')}
              </h1>
            </li>
            <li>
              <p>자기부담금</p>
              <h1>{profileData.paidAmount < 400000 ? '10만원' : '20만원'}</h1>
            </li>
            <li>
              <p>보상한도</p>
              <h1>{profileData.paidAmount < 400000 ? '300만원' : '500만원'}</h1>
            </li>
            <li>
              <p>보증만료 주행거리</p>
              <h1>{numberWithCommas(profileData.mileage + 15000)}km</h1>
            </li>
          </ul>
        </div>

        {/* 두번째 데이터 */}
        <h1 className={styles.sec_title}>기본정보</h1>
        <ul className={styles.sec2_wrap}>
          {assuranceDataList.data2.map((item, i) => {
            return (
              <li key={i}>
                <p>{item.title}</p>
                <h1>{profileData[item.name]}</h1>
              </li>
            );
          })}
        </ul>

        {/* 세번째 데이터 */}
        <h1 className={styles.sec_title}>차량정보</h1>
        <ul className={styles.sec2_wrap}>
          {assuranceDataList.data3.map((item, i) => {
            return (
              <li key={i}>
                <p>{item.title}</p>
                <h1>{profileData[item.name]}</h1>
              </li>
            );
          })}
        </ul>

        {/* 버튼 */}
        {/*<div className={styles.btn_wrap}>*/}
        {/*    <button className={styles.btn1}>인쇄</button>*/}
        {/*    <button className={styles.btn2}>다운로드</button>*/}
        {/*</div>*/}
      </div>
    </section>
  );
}

export default Assurance;

const assuranceDataList = {
  data1: [
    { p: '상품명', title: 'Star 안심보증' },
    { p: '보증기간', title: '2021.03.22' },
    { p: '자기부담금', title: '1백만원' },
    { p: '보상한도', title: '3백만원' },
    { p: '보증만료주행거리', title: '30000km' },
  ],

  data2: [
    { name: 'fullName', title: '이름', val: '홍길동' },
    { name: 'birthDate', title: '생년월일', val: '20220329' },
    { name: 'mobile', title: '휴대전화', val: '01012345678' },
    {
      name: 'address',
      title: '고객주소',
      val: '서울시 마포구 노고산동 123길 1563호',
    },
  ],

  data3: [
    { name: 'plateNumber', title: '차량번호', val: '391수 2344' },
    { name: 'modelName', title: '차량명', val: 'SM5' },
    { name: 'vin', title: '차대번호', val: '123123' },
  ],
};
