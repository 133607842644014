import React, {Fragment} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import styles from "./forCheck.module.css";
import logo from "../../../@assets/white-logo.svg";
import backIcon from "../../../@assets/forCheck/white-backIcon.svg";

function ForCheck() {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <section className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.backBox} onClick={() => navigate("/")}>
                    <img src={backIcon} alt=""/>
                    <img src={logo} alt="" className={styles.logo}/>
                </div>
                <h1 className={styles.title}>
                    원터치로 한번에 <br/>
                    확인하는 Star 안심보증 견적
                </h1>

                <div className={styles.pathBar_wrap}>
                    <ul className={styles.pathBar}>
                        {pathData.map((item, i) => {
                            return (
                                <Fragment key={i}>
                                    <li>
                                        <span
                                            style={
                                                location.pathname === item.path
                                                    ? {
                                                        background: "#fff",
                                                        color: "#6CBED7",
                                                        fontWeight: 600,
                                                    }
                                                    : {backgroundColor: "#4C809D", color: "#ADCBD9"}
                                            }
                                        >
                                          {item.n}
                                        </span>
                                        <p
                                            style={
                                                location.pathname === item.path
                                                    ? {color: "#fff", fontWeight: 500}
                                                    : {color: "#ADCBD9"}
                                            }
                                        >
                                            {item.title}
                                        </p>
                                    </li>
                                    <div className={styles.line}/>
                                </Fragment>
                            );
                        })}
                    </ul>
                </div>

                <Outlet/>
            </div>
        </section>
    );
}

export default ForCheck;

const pathData = [
    {key: 1, path: "/for-check/step1", n: "1", title: "차종선택"},
    {key: 2, path: "/for-check/step2", n: "2", title: "주행거리"},
    {key: 3, path: "/for-check/step3", n: "3", title: "보상한도"},
    {key: 4, path: "/for-check/step4", n: "4", title: "견적보기"},
];
