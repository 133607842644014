import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import styles from "../forCheck.module.css";
import checkIcon1 from "../../../../@assets/forCheck/check1.svg";
import checkIcon2 from "../../../../@assets/forCheck/check2.svg";
import checkIcon3 from "../../../../@assets/forCheck/check3.svg";
import checkIcon4 from "../../../../@assets/forCheck/check4.svg";
import {Link, Navigate} from "react-router-dom";

function CheckStep1() {
    const navigate = useNavigate();
    const [carFrom, setCarFrom] = useState(null);
    const [carType, setCarType] = useState(null);

    useEffect(() => {
        if (carFrom && carType) {
            navigate(`/for-check/step2?from=${carFrom}&type=${carType}`);
        }
    }, [carFrom, carType]);
    return (
        <div className={styles.step_wrap}>
            <h2>
                <span>STEP1</span>
                <br/>
                차종을 선택하세요.
            </h2>

            <div className={styles.choice_box}>
                <label>
                    <input
                        type="radio"
                        name="carFrom"
                        value="국산차"
                        checked={carFrom === "국산차"}
                        onChange={(e) => {
                            setCarFrom(e.target.value);
                        }}
                    />
                    <span>국산차</span>
                </label>
                <label>
                    <input
                        type="radio"
                        name="carFrom"
                        value="수입차"
                        checked={carFrom === "수입차"}
                        onChange={(e) => {
                            setCarFrom(e.target.value);
                        }}
                    />
                    <span>수입차</span>
                </label>
            </div>

            <ul className={styles.menu_box}>
                {checkMenu.map((item, i) => {
                    return (
                        <label
                            onClick={() => setCarType(item.title)}
                            className={carType === item.title ? styles.active : undefined}
                            key={i}
                        >
                            <input
                                type="radio"
                                name="carType"
                                value={item.title}
                                defaultChecked={carType === item.title}
                            />
                            <img src={item.img} alt=""/>
                            <p>{item.title}</p>
                            <p>{item.description}</p>
                        </label>
                    );
                })}
            </ul>

            <p className={styles.step1_subP}>
                차종별 대표차량은 아래와 같습니다. <br/>
                <br/>
                경형/소형 : 모닝, 레이, 캐스퍼, 마티스, 스파크, 액센트, 프라이드, 칼로스 등 1300cc 이하 승용차량
                <br/>
                준중형/중형 : 아반떼, K3, 코나, 스토닉, 쏘나타, K5, 크루즈, 말리부, 트레블레이져, SM3, SM5, SM6 등 2000cc 이하 차량
                <br/>
                대형/RV/SUV : 싼타페, 쏘렌토, 그랜져, K7, 제네시스라인업, K9 등 2000cc를 초과하는 승용 및 RV, SUV 차량
                <br/>
                <br/>
                다음 차량은 가입이 제한됩니다.<br/>
                - 유상 운송차량<br/>
                - 특수차량(엠뷸런스,통학버스,튜닝,특장차, 캠핑카 등)<br/>
                - 전기차/수소차<br/>
                - 신차가격 2억 초과 차량<br/>
                - 1.3t 초과 화물차<br/>
                - 자동차등록증상 배기량 3500cc 이상의 수입차<br/>
                - 고성능 수입차(벤츠AMG, BMW M, 아우디 S/RS, 스포츠카)<br/>
            </p>
        </div>
    );
}

export default CheckStep1;

const checkMenu = [
    {img: checkIcon1, title: "경차/소형", description: "1300cc 이하"},
    {img: checkIcon2, title: "준중형/중형", description: "2000cc 이하"},
    {img: checkIcon3, title: "대형/RV/SUV/승합", description: "2000cc 초과"}
    // ,
    // {img: checkIcon4, title: "특대형"},
];
