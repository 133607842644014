import Constants from "../common/Constants";
import axios from "../common/Axios";

export const phoneNumberFormatting = (n, isFormatted = false) => {
    if (isFormatted) return n.replace(/-/g, '').match(/(\d{1,11})/g)[0]
    if (n.substr(0, 2) === '02') {
        if (n.length === 10) return n.replace(/(\d{1,2})(\d{1,4})(\d{1,4})/g, '$1-$2-$3')
        else return n.replace(/(\d{1,2})(\d{1,3})(\d{1,4})/g, '$1-$2-$3')
    } else {
        if (n.length === 11) return n.replace(/(\d{1,3})(\d{1,4})(\d{1,4})/g, '$1-$2-$3')
        else if (n.length === 10) return n.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, '$1-$2-$3')
        else if (n.length === 8) return n.replace(/(\d{1,4})(\d{1,4})/g, '$1-$2')
        else return n
    }
}

export const getQueryValue = (key) => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === key) {
            return decodeURIComponent(pair[1]);
        }
    }

    return ''
}

export const numberWithCommas = (x, isFloat = false, floatCount = 2) => {
    if (!x) return '0'

    const arr = x.toString().split('.')
    if (isFloat) {
        return (
            arr[0]
                .toString()
                .replace(/\D/g, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
            (arr[1] ? '.' + (arr[1] + '00000').substring(0, floatCount) : '.00') +
            (x.toString().charAt(x.length - 1) === '.' ? '.' : '')
        )
    }
    return (
        arr[0]
            .toString()
            .replace(/\D/g, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (arr[1] ? '.' + arr[1] : '')
    )
}