import React, {useState, useEffect} from "react";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import PathBar from "./PathBar";
import {saveUserData} from "../../store/auth/signUpSlice";
import reducer from "../../store/auth";
import withReducer from "../../store/withReducer";
import styles from "./signup.module.css";
import {privateInfoSchema} from "../../config/schemaInfo";
import {getQueryValue} from "../../utils/utilityFunctions";

function SignUp3() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const marketingAgree = getQueryValue('marketingagree')
    let defaultValues = useSelector(({auth}) => auth.signUp.userData);
    if (!defaultValues) {
        defaultValues = {
            fullName: '',
            birthDate: '',
            gender: '',
            email: '',
            password1: '',
            password2: '',
            mobile: '',
            address: '',
            kind: '1'
        }
    }

    const [codeField, setCodeField] = useState(false);
    const {control, formState, handleSubmit, setError, getValues} = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(privateInfoSchema),
    });
    const {isValid, errors} = formState;
    const requestCode = () => {
        setCodeField(true);
        alert("인증 코드를 보냈습니다.");
    };
    const onSubmit = () => {
        let signUpData = getValues()
        signUpData.marketingAgree = marketingAgree
        dispatch(saveUserData(signUpData));
        navigate("/sign-up4");
    };
    useEffect(() => {
        setError();
    }, []);
    return (
        <section className={styles.wrapper}>
            <div className={styles.container}>
                <PathBar/>

                <form className={styles.formBox} onSubmit={handleSubmit(onSubmit)}>
                    {/* 이름 */}
                    <label className={styles.label} style={{marginTop: 0}}>
                        <p>
                            이름 <span>(필수)</span>
                        </p>
                        {errors.fullName?.message && (
                            <p className={styles.errorMsg}>{errors.fullName?.message}</p>
                        )}
                    </label>
                    <Controller
                        name="fullName"
                        control={control}
                        render={({field}) => (
                            <input
                                {...field}
                                type="text"
                                placeholder="이름 입력"
                                className={styles.input_field}
                            />
                        )}
                    />
                    {/* 생년월일 */}
                    <label className={styles.label}>
                        <p>
                            생년월일 <span>(필수)</span>
                        </p>
                        {errors.birthDate?.message && (
                            <p className={styles.errorMsg}>{errors.birthDate?.message}</p>
                        )}
                    </label>
                    <Controller
                        name="birthDate"
                        control={control}
                        render={({field}) => (
                            <input
                                {...field}
                                type="text"
                                minLength="8"
                                maxLength="8"
                                inputMode="decimal"
                                value={getValues("birthDate")}
                                className={styles.input_field}
                                placeholder="생년월일 입력 예) 20220301"
                                onChange={(e) => {
                                    if (e.target.value.match("^[0-9]*$")) {
                                        field.onChange(e);
                                    }
                                }}
                            />
                        )}
                    />
                    {/* 성별 */}
                    <label className={styles.label}>
                        <p>
                            성별 <span>(필수)</span>
                        </p>
                        {errors.gender?.message && (
                            <p className={styles.errorMsg}>{errors.gender?.message}</p>
                        )}
                    </label>
                    <div className={styles.genderBox}>
                        <Controller
                            name="gender"
                            control={control}
                            render={({field}) => (
                                <>
                                    <label htmlFor="male">
                                        <input
                                            {...field}
                                            type="radio"
                                            value="male"
                                            id="male"
                                            checked={getValues("gender") === "male"}
                                            /*     onChange={(e) => {
                                              field.onChange(e);
                                            }} */
                                        />
                                        <span>남</span>
                                    </label>
                                    <label htmlFor="female">
                                        <input
                                            {...field}
                                            type="radio"
                                            value="female"
                                            id="female"
                                            checked={getValues("gender") === "female"}
                                            /*   onChange={(e) => {
                                              field.onChange(e);
                                            }} */
                                        />
                                        <span>여</span>
                                    </label>
                                </>
                            )}
                        />
                    </div>

                    {/* 아이디 */}
                    {/*<label className={styles.label}>*/}
                    {/*    <p>*/}
                    {/*        아이디 <span>(필수)</span>*/}
                    {/*    </p>*/}
                    {/*    {errors.id?.message && (*/}
                    {/*        <p className={styles.errorMsg}>{errors.id?.message}</p>*/}
                    {/*    )}*/}
                    {/*</label>*/}
                    {/*<div className={styles.two_field}>*/}
                    {/*    <Controller*/}
                    {/*        name="id"*/}
                    {/*        control={control}*/}
                    {/*        render={({field}) => (*/}
                    {/*            <input*/}
                    {/*                {...field}*/}
                    {/*                type="text"*/}
                    {/*                placeholder="영문 소문자와 숫자 조합 4~12자리"*/}
                    {/*                className={styles.input_field2}*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    if (!e.target.value.match(/[^0-9a-z]/g)) field.onChange(e);*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        )}*/}
                    {/*    />*/}
                    {/*    <button*/}
                    {/*        type="button"*/}
                    {/*        disabled={errors.id?.message}*/}
                    {/*        style={*/}
                    {/*            errors.id?.message || getValues("id") === ""*/}
                    {/*                ? {backgroundColor: "#ccc"}*/}
                    {/*                : {backgroundColor: "#6CBED7"}*/}
                    {/*        }*/}
                    {/*        onClick={() => alert("사용 가능한 아이디입니다.")}*/}
                    {/*    >*/}
                    {/*        중복확인*/}
                    {/*    </button>*/}
                    {/*</div>*/}

                    {/* 이메일 */}
                    <label className={styles.label}>
                        <p>
                            이메일 <span>(필수)</span>
                        </p>
                        {errors.email?.message && (
                            <p className={styles.errorMsg}>{errors.email?.message}</p>
                        )}
                    </label>

                    <Controller
                        name="email"
                        control={control}
                        render={({field}) => (
                            <input
                                {...field}
                                type="email"
                                placeholder="이메일 입력"
                                className={styles.input_field}
                            />
                        )}
                    />

                    {/* 비밀번호 */}
                    <label className={styles.label}>
                        <p>
                            비밀번호 <span>(필수)</span>
                        </p>
                        {errors.password1?.message && (
                            <p className={styles.errorMsg}>{errors.password1?.message}</p>
                        )}
                    </label>
                    <Controller
                        name="password1"
                        control={control}
                        render={({field}) => (
                            <input
                                {...field}
                                type="password"
                                placeholder="숫자,영문 소문자,특수문자 8~16자로 입력"
                                className={styles.input_field}
                            />
                        )}
                    />

                    {/* 비밀번호 확인*/}
                    <label className={styles.label}>
                        <p>
                            비밀번호 확인 <span>(필수)</span>
                        </p>
                        {errors.password2?.message && (
                            <p className={styles.errorMsg}>{errors.password2?.message}</p>
                        )}
                    </label>

                    <Controller
                        name="password2"
                        control={control}
                        render={({field}) => (
                            <input
                                {...field}
                                type="password"
                                placeholder="숫자,영문 소문자,특수문자 8~16자로 입력"
                                className={styles.input_field}
                            />
                        )}
                    />

                    {/* 휴대폰 번호 */}
                    <label className={styles.label}>
                        <p>
                            휴대폰번호 <span>(필수)</span>
                        </p>
                        {errors.mobile?.message && (
                            <p className={styles.errorMsg}>{errors.mobile?.message}</p>
                        )}
                    </label>
                    <div className={styles.two_field}>
                        <Controller
                            name="mobile"
                            control={control}
                            render={({field}) => (
                                <input
                                    {...field}
                                    type="text"
                                    maxLength="11"
                                    inputMode="decimal"
                                    placeholder="'-' 제외하고 숫자만 입력"
                                    className={styles.input_field2}
                                    value={getValues("mobile")}
                                    onChange={(e) => {
                                        if (e.target.value.match("^[0-9]*$")) {
                                            field.onChange(e);
                                        }
                                    }}
                                />
                            )}
                        />

                        {/* 휴대전화 인증 향후 구현 */}
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    disabled={errors.mobile?.message}*/}
                        {/*    style={*/}
                        {/*        errors.mobile?.message || getValues("mobile") === ""*/}
                        {/*            ? {backgroundColor: "#ccc"}*/}
                        {/*            : {backgroundColor: "#6CBED7"}*/}
                        {/*    }*/}
                        {/*    onClick={requestCode}*/}
                        {/*>*/}
                        {/*    인증요청*/}
                        {/*</button>*/}
                    </div>
                    {/* 인증번호 */}

                    {codeField && (
                        <>
                            <label className={styles.label}>
                                <p>인증번호</p>
                                {errors.code?.message && (
                                    <p className={styles.errorMsg}>{errors.code?.message}</p>
                                )}
                            </label>
                            <Controller
                                name="code"
                                control={control}
                                render={({field}) => (
                                    <input
                                        {...field}
                                        type="text"
                                        maxLength="4"
                                        inputmode="decimal"
                                        placeholder="인증번호 입력"
                                        className={styles.input_field}
                                        value={getValues("code")}
                                        onChange={(e) => {
                                            if (e.target.value.match("^[0-9]*$")) {
                                                field.onChange(e);
                                            }
                                        }}
                                    />
                                )}
                            />
                        </>
                    )}

                    {/* 거주지 */}
                    <label className={styles.label}>
                        <p>
                            거주지 <span>(필수)</span>
                        </p>
                        {errors.address?.message && (
                            <p className={styles.errorMsg}>{errors.address?.message}</p>
                        )}
                    </label>

                    <Controller
                        name="address"
                        control={control}
                        render={({field}) => (
                            <input
                                {...field}
                                type="text"
                                className={styles.input_field}
                                placeholder="예) 서울특별시 강남구 테헤란로 1길 11 xx빌딩 0동 000호"
                            />
                        )}
                    />

                    {/* 고객 형태 */}
                    <label className={styles.label}>
                        <p>
                            고객 타입 <span>(필수)</span>
                        </p>
                        {errors.kind?.message && (
                            <p className={styles.errorMsg}>{errors.kind?.message}</p>
                        )}
                    </label>
                    <div className={styles.genderBox}>
                        <Controller
                            name="kind"
                            control={control}
                            render={({field}) => (
                                <>
                                    <label htmlFor="person">
                                        <input
                                            {...field}
                                            type="radio"
                                            value="1"
                                            id="person"
                                            checked={getValues("kind") === "1"}
                                            /*     onChange={(e) => {
                                              field.onChange(e);
                                            }} */
                                        />
                                        <span>개인고객</span>
                                    </label>
                                    <label htmlFor="company">
                                        <input
                                            {...field}
                                            type="radio"
                                            value="2"
                                            id="company"
                                            checked={getValues("kind") === "2"}
                                            /*   onChange={(e) => {
                                              field.onChange(e);
                                            }} */
                                        />
                                        <span>판매상사</span>
                                    </label>
                                </>
                            )}
                        />
                    </div>

                    {/* 제출 버튼 */}
                    <button
                        className={styles.btn}
                        style={
                            !isValid
                                ? {backgroundColor: "#ccc"}
                                : {backgroundColor: "#6CBED7"}
                        }
                    >
                        다음
                    </button>
                </form>
            </div>
        </section>
    );
}

export default withReducer("auth", reducer)(SignUp3);
