import React from 'react'
import styles from './announce.module.css'

function Announce2() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <h1>개인정보처리방침</h1>
        <p>
          당사의 귀책사유로 인한 개인정보 유출로 고객님에게 발생한 손해에 대해 관계 법령 등에 따라
          보상 받으실 수 있습니다.
        </p>
      </div>
    </section>
  )
}

export default Announce2
