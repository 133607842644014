import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import styles from './common.module.css';
import logo from '../../@assets/logo.svg';
import logoChaChaCha from '../../@assets/logo_chachacha.png';
import userIcon from '../../@assets/coomon/userIcon.svg';
import upIcon from '../../@assets/coomon/upIcon.svg';
import downIcon from '../../@assets/coomon/downIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { closeUserMenu, onToggleLogin, openUserMenu } from '../store/app/toggleSlice';
import Login from '../pages/login/Login';
import { NO_HEADER } from '../config/routeInfo';
import { setProfile } from '../store/user/profileSlice';
import Constants from './Constants';
import { requestPost } from '../utils/apiFunctions';

function Header() {
  const menuRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isHeaderHidden = NO_HEADER.includes(location.pathname);
  const home = location.pathname === '/';
  const menuHandler = useSelector(({ app }) => app.toggle.userMenu);
  const profileDataOriginal = useSelector(({ user }) => user.profile.data);
  let profileData = _.cloneDeep(profileDataOriginal);
  const loginToggleHandler = () => {
    dispatch(onToggleLogin());
  };
  const menuOpenHandler = async () => {
    dispatch(openUserMenu());

    // 헤더의 사용자 메뉴를 클릭해서 메뉴가 보이면
    // 사용자 상태를 체크하기 위해 서버에 요청하여 profileData 를 갱신함
    if (profileData && profileData.email) {
      const response = await getUserState(profileData.email);
      if (response.data) {
        if (response.data.state) profileData.state = response.data.state;
        if (response.data.paidAmount) profileData.paidAmount = response.data.paidAmount;
        dispatch(setProfile(profileData));
      }
    }
  };
  const logoutHandler = () => {
    profileData = null;
    dispatch(setProfile(profileData));
  };
  const getUserState = async (email) => {
    const params = {
      email: email,
    };

    return await requestPost(Constants.API.user.getState, params);
  };

  // 스크롤 시 헤더 감지 이벤트
  const [navActive, setNavActive] = useState(false);
  const scrollActive = () => {
    if (window.scrollY >= 14) {
      setNavActive(true);
    } else {
      setNavActive(false);
    }
  };
  window.addEventListener('scroll', scrollActive);

  //유저 메뉴 모달 외부  닫기 이벤트
  useEffect(() => {
    if (!menuHandler) return;

    const handleClose = (e) => {
      if (menuRef.current?.contains(e.target) && !menuRef.current?.contains(e.target)) return;
      dispatch(closeUserMenu());
    };

    window.addEventListener('click', handleClose);
    return () => window.removeEventListener('click', handleClose);
  }, [dispatch, menuHandler]);

  return (
    <>
      {/* sac 회원가입  */}
      {!isHeaderHidden && (
        <header className={styles.header_layer}>
          <div
            className={
              home
                ? `${
                    navActive
                      ? `${styles.h_wrapper_home} ${styles.h_wrapper_home_active}`
                      : `${styles.h_wrapper_home}`
                  } ${styles.h_wrapper}`
                : `${styles.h_wrapper}`
            }
          >
            <div className={styles.h_container}>
              <NavLink to="/">
                <img src={logo} alt="" />
              </NavLink>

              {/* 상황에 따른 정보 변동 =>  메뉴 */}
              {profileData ? (
                <div className={styles.user_box} onClick={menuOpenHandler} ref={menuRef}>
                  <img src={userIcon} alt="" />
                  <span>{profileData.fullName}</span>
                  {menuHandler ? <img src={upIcon} alt="" /> : <img src={downIcon} alt="" />}

                  {menuHandler && (
                    <div className={styles.userMenu_wrap}>
                      <Link to="/my-page">내 가입정보</Link>
                      {profileData.state === Constants.COMMON_CODE.USER_STATE.WAITING_PAYMENT && (
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault();
                            navigate('/pay', {
                              state: {
                                paidAmount: profileData.paidAmount,
                                fullName: profileData.fullName,
                              },
                            });
                          }}
                        >
                          결제하기
                        </Link>
                      )}
                      <Link to="/my-change">정보수정</Link>
                      <Link to="/" onClick={logoutHandler}>
                        로그아웃
                      </Link>
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.signUp_box}>
                  <NavLink to="/sign-up1" className={styles.signUp}>
                    회원가입
                  </NavLink>
                  <button type="button" onClick={loginToggleHandler} className={styles.login}>
                    로그인
                  </button>
                </div>
              )}
            </div>
          </div>
        </header>
      )}

      {/* kbc 회원가입 */}
      {location.pathname === '/kbc/sign-up1' && (
        <header className={styles.header_layer}>
          <div
            className={
              home
                ? `${
                    navActive
                      ? `${styles.h_wrapper_home} ${styles.h_wrapper_home_active}`
                      : `${styles.h_wrapper_home}`
                  } ${styles.h_wrapper}`
                : `${styles.h_wrapper}`
            }
          >
            <div className={styles.h_container_kbc}>
              <NavLink to="/kbc/sign-up1">
                <img src={logoChaChaCha} alt="차차차 로고" width="95px" />
              </NavLink>
              <div className={styles.h_container_kbc_collabo}>X</div>
              <NavLink to="/kbc/sign-up1">
                <img src={logo} alt="SAC 로고" width="160px" />
              </NavLink>
            </div>
          </div>
        </header>
      )}

      <Login />
    </>
  );
}

export default Header;
