import {combineReducers} from "@reduxjs/toolkit";
import auth from "./auth";
import user from "./user";
import app from "./app";

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const persistConfig = {
    key: "root",
    storage
    // ,
    // whitelist: ["user"],
    // blacklist -> 그것만 제외합니다
};

const reducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        user,
        app,
        auth,
        ...asyncReducers,
    });
    return combinedReducer(state, action);
};

// export default createReducer;

export default function createReducer() {
    const rootReducer = reducer();
    return persistReducer(persistConfig, rootReducer);
}
