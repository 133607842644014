import React from 'react'
import {NavLink, Outlet} from 'react-router-dom'
import styles from './qna.module.css'

function Qna() {
    return (
        <section className={styles.wrapper}>
            <div className={styles.container}>
                <h1 className={styles.title}>자주 묻는 질문</h1>
                <div className={styles.nav_wrap}>
                    <NavLink
                        to="q1"
                        style={({isActive}) => ({
                            color: isActive ? '#6CBED7' : '#ccc',
                            border: isActive ? '1px solid #EAF8FC' : '1px solid #ccc',
                            backgroundColor: isActive ? '#EAF8FC' : '#fff',
                        })}
                    >
                        가입방법
                    </NavLink>
                    <NavLink
                        to="q2"
                        style={({isActive}) => ({
                            color: isActive ? '#6CBED7' : '#ccc',
                            border: isActive ? '1px solid #EAF8FC' : '1px solid #ccc',
                            backgroundColor: isActive ? '#EAF8FC' : '#fff',
                        })}
                    >
                        상품안내
                    </NavLink>
                    <NavLink
                        to="q3"
                        style={({isActive}) => ({
                            color: isActive ? '#6CBED7' : '#ccc',
                            border: isActive ? '1px solid #EAF8FC' : '1px solid #ccc',
                            backgroundColor: isActive ? '#EAF8FC' : '#fff',
                        })}
                    >
                        보증수리
                    </NavLink>
                    <NavLink
                        to="q4"
                        style={({isActive}) => ({
                            color: isActive ? '#6CBED7' : '#ccc',
                            border: isActive ? '1px solid #EAF8FC' : '1px solid #ccc',
                            backgroundColor: isActive ? '#EAF8FC' : '#fff',
                        })}
                    >
                        결제/환불
                    </NavLink>
                    <NavLink
                        to="q5"
                        style={({isActive}) => ({
                            color: isActive ? '#6CBED7' : '#ccc',
                            border: isActive ? '1px solid #EAF8FC' : '1px solid #ccc',
                            backgroundColor: isActive ? '#EAF8FC' : '#fff',
                        })}
                    >
                        기타
                    </NavLink>
                </div>
                <Outlet/>
            </div>
        </section>
    )
}

export default Qna
