const Qdata = {
    q1: [
        {
            q: '스타안심보증 가입 방법은 무엇인가요?',
            a: '보내드린 안심보증 URL의 가입절차를 확인부탁드립니다.\n\n' +
                '고객님께서 입력하신 정보를 토대로 가입여부 확인하여, 콜센타에서 안내전화 드립니다.\n' +
                '가입조건에 문제가 없을시, 결제가 진행되며, 결제완료시 정상 가입이 완료됩니다.',
        }
    ],
    q2: [
        {
            q: '어떤 상품이 있나요?',
            a: '상품은 상품안내 페이지를 참조하시기 바랍니다.',
        }
    ],
    q3: [
        {
            q: '보증 수리 절차는 어떻게 진행되나요?',
            a: '차량의 고장발생시, 센타로 연락주시면, 차고지 인근의 제휴업체 확인하여 안내드립니다. \n' +
                '체휴업체에서 차량 점검후, 안심보증에서 보증하는 항목에 대해서만 정비 보상이 제공됩니다.\n' +
                '차량등록증. 성능기록부. 정비견적서 확인후 이상 없을시, 정상진행되며\n' +
                '자부담금을 제외한 정비비용은 당센타에서 처리해드립니다.\n',
        }
    ],
    q4: [
        {
            q: '상품 결제는 어떻게 하나요?',
            a: '가입조건에 해당하는 차량에 대하여, 센타에서 안내콜을 진행합니다.\n' +
                '가입진행의 웹사이트를 통하여 최종 결제가 진행됩니다.\n' +
                '연계 PG사를 통해 온라인 결제로 진행됩니다. ',
        }
    ]
}

export default Qdata
