import React from 'react'
import styles from './announce.module.css'

function Announce3() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <h1>환불정책</h1>
        <p>
            보증항목에 대한 보상처리가 판매자의 제공정보와 상이한 경우 가입 30일 이내에 한하여 결제방식에 따라 승인취소 또는 계좌입금 처리를 해드립니다.
        </p>
      </div>
    </section>
  )
}

export default Announce3
