import Constants from '../../common/Constants';
import { createSlice } from '@reduxjs/toolkit';
// import { FAKE_USER_INFO } from '../../config/profileInfo';
import { onToggleLogin } from '../app/toggleSlice';
import { setProfile } from '../user/profileSlice';
import { requestPost } from '../../utils/apiFunctions';

export const submitLogin =
  ({ email, password }) =>
  async (dispatch) => {
    // if (FAKE_USER_INFO.id == id && FAKE_USER_INFO.password == password) {
    //     dispatch(loginSuccess());
    //     dispatch(onToggleLogin());
    //     dispatch(setProfile(FAKE_USER_INFO));
    // } else {
    //     dispatch(loginError());
    // }

    const params = {
      email: email,
      password: password,
    };

    try {
      const response = await requestPost(Constants.API.user.login, params);
      if (response && response.success) {
        dispatch(loginSuccess());
        dispatch(onToggleLogin());
        dispatch(setProfile(response.data));
      } else {
        dispatch(setProfile(null));
        dispatch(loginError());
        alert(response.msg);
      }
    } catch (e) {
      alert(e.msg);
    }
  };

const initialState = {
  success: false,
  error: false,
};

const loginSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.success = true;
      state.error = false;
    },
    loginError: (state, action) => {
      state.success = false;
      state.error = true;
    },
    resetError: (state, action) => {
      state.error = false;
    },
  },
});

export const { loginSuccess, loginError, resetError } = loginSlice.actions;

export default loginSlice.reducer;
