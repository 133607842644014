import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import styles from "./myPage.module.css";
import callIcon from "../../../@assets/myPage/callIcon.svg";
import linkIcon from "../../../@assets/myPage/linkIcon.svg";
import {Link, useNavigate} from "react-router-dom";
import Constants from "../../common/Constants";
import {numberWithCommas} from "../../utils/utilityFunctions";
import {submitLogin} from "../../store/auth/loginSlice";

function MyPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileData = useSelector(({user}) => user.profile.data);
    // useEffect(() => {
    //     dispatch(submitLogin({email: profileData.email, password: '1111'}));
    // }, [])

    return (
        <section className={styles.wrapper}>
            <div className={styles.container}>
                <h1 className={styles.title}>내 가입정보</h1>
                <p className={styles.subTitle}>
                    고객님이 가입하신 서비스는 다음과 같습니다.
                </p>
                <p className={styles.titleLink}>
                    보장범위 <img src={linkIcon} alt=""/>
                </p>

                {/* 보증 정보 값 */}
                <ul className={styles.valBox1}>
                    <li>
                        <p>보증기간</p>
                        <h1>
                            6개월 <span>(1만5천 Km)</span>
                        </h1>
                    </li>
                    <li>
                        <p>보상한도</p>
                        <h1>
                            {profileData.paidAmount < 400000 ? '300만원' : '500만원'}
                        </h1>
                    </li>
                    <li>
                        <p>자기부담금</p>
                        <h1>
                            {profileData.paidAmount < 400000 ? '10만원' : '20만원'}
                        </h1>
                    </li>
                </ul>

                {profileData.state === Constants.COMMON_CODE.USER_STATE.SUBSCRIPTION_REVIEW && <button className={styles.valBtn}>가입 승인 중</button>}
                {profileData.state === Constants.COMMON_CODE.USER_STATE.WAITING_PAYMENT && <button
                    className={styles.valBtn}
                    onClick={() => {
                        navigate(
                            "/pay",
                            {
                                state: {
                                    paidAmount: profileData.paidAmount,
                                    fullName: profileData.fullName,
                                    userId: profileData.id
                                }
                            }
                        );
                    }}
                >
                    결제 대기 중 ({numberWithCommas(profileData.paidAmount)} 원 결제하기)
                </button>}

                {profileData.state === Constants.COMMON_CODE.USER_STATE.REGISTRATION_COMPLETE && <button
                    className={styles.valBtn}
                    onClick={() => {
                        navigate("/assurance");
                    }}
                >
                    내 보증서 보기
                </button>}

                {/* 콜센터 안내 */}
                <div className={styles.call_wrap}>
                    <div className={styles.call_title}>
                        <p>
                            <img src={callIcon} alt=""/>
                            대표콜센터
                        </p>
                        <h1>1661-0850</h1>
                    </div>
                    {/*{profileData.state === Constants.COMMON_CODE.USER_STATE.REGISTRATION_COMPLETE && <Link to="/assurance-info">보증수리 접수</Link>}*/}
                </div>

                {/* 내 정보 데이터 */}
                <ul className={styles.valBox2}>
                    {profileDataList.dataBox2.map((item, i) => {
                        return (
                            <li key={i}>
                                <p>{item.title}</p>
                                <h1>
                                    {profileData[item.name]}{" "}
                                    {item.spanVal && <span>{item.spanVal}</span>}
                                </h1>
                            </li>
                        );
                    })}
                </ul>

                {/* 버튼 */}
                <div className={styles.btn_wrap}>
                    <button
                        className={styles.btn1}
                        onClick={() => navigate("/my-change")}
                    >
                        개인정보 수정
                    </button>
                    {profileData.state === Constants.COMMON_CODE.USER_STATE.SUBSCRIPTION_REVIEW && <button
                        className={styles.btn2}
                        onClick={() => navigate("/cardata-change")}
                    >
                        차량정보 수정
                    </button>}
                </div>
            </div>
        </section>
    );
}

export default MyPage;

const profileDataList = {
    dataBox1: [
        {key: "보장기간", val: "6개월", spanVal: "1만5천km"},
        {key: "보장한도", val: "2백만원"},
        {key: "자기부담금", val: "1십만원"},
    ],
    dataBox2: [
        {name: "fullName", title: "이름"},
        {name: "birthDate", title: "생년월일"},
        {name: "mobile", title: "휴대전화"},
        {name: "email", title: "이메일"},
        {name: "plateNumber", title: "차량번호"},
        {name: "modelName", title: "차량명"},
        {name: "modelYear", title: "연식"},
        {name: "mileage", title: "주행거리", spanVal: "km"},
    ],
};
