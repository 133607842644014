import React, {useState} from 'react'
import styles from '../qna.module.css'
import Qdata from './Qdata'
import dropUpIcon from '../../../../@assets/drop-up-icon.svg'
import dropDownIcon from '../../../../@assets/drop-down-icon.svg'

function Q1() {
    const [isDrop, setIsDrap] = useState(false)

    const toggle = index => {
        if (isDrop === index) {
            return setIsDrap(null)
        }

        setIsDrap(index)
    }

    return (
        <ul className={styles.q_wrapper}>
            {Qdata.q1.map((item, i) => {
                return (
                    <li
                        className={
                            isDrop === i
                                ? `${styles.q_box_wrapper} ${styles.q_box_wrapper_active}`
                                : `${styles.q_box_wrapper}`
                        }
                        key={i}
                        onClick={() => {
                            toggle(i)
                        }}
                    >
                        <div className={styles.q_box_container}>
                            <div className={styles.titleBox}>
                                <h1
                                    style={
                                        isDrop === i
                                            ? {fontWeight: 500, color: '#333'}
                                            : {fontWeight: 400, color: '#666'}
                                    }
                                >
                                    {item.q}
                                </h1>
                                {isDrop === i ? (
                                    <img src={dropUpIcon} alt=""/>
                                ) : (
                                    <img src={dropDownIcon} alt=""/>
                                )}
                            </div>
                            {isDrop === i && <p>{
                                item.a.split('\n').map( (line, key) => {
                                    return (<span key={key}>{line}<br/></span>)
                                })}</p>}
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

export default Q1
