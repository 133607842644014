import { createSlice } from '@reduxjs/toolkit';
import { FAKE_USER_INFO } from '../../config/profileInfo';
import { requestPost } from '../../utils/apiFunctions';
import Constants from '../../common/Constants';
import { onToggleLogin } from '../app/toggleSlice';
import { loginSuccess } from '../auth/loginSlice';

export const checkPassword =
  ({ id, password }) =>
  async (dispatch) => {
    // console.log(id)
    // console.log(password)
    // if (FAKE_USER_INFO.password === password) {
    //     return true;
    // } else {
    //     return false;
    // }

    const params = {
      email: id,
      password: password,
    };

    const response = await requestPost(Constants.API.user.login, params);
    if (response) {
      dispatch(setProfile(response.data));
    }
    return !!response.data;
  };

const initialState = { data: null };

const profileSlice = createSlice({
  name: 'user/profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      // console.log(action.payload)
      state.data = action.payload;
    },
  },
  extraReducers: {},
});
export const { setProfile } = profileSlice.actions;
export default profileSlice.reducer;
