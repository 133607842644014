import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./signup.module.css";

function PathBar() {
  const location = useLocation();
  return (
    <div className={styles.path_header}>
      <h5> 서비스 가입</h5>
      <ul className={styles.pathBar}>
        {pathData.map((item, i) => {
          return (
            <li key={i}>
              <span
                style={
                  location.pathname === item.path
                    ? { background: "#6CBED7" }
                    : { backgroundColor: "#e2e2e2" }
                }
              >
                {item.n}
              </span>
              <p
                style={
                  location.pathname === item.path
                    ? { color: "#6CBED7", fontWeight: 500 }
                    : { color: "#e2e2e2" }
                }
              >
                {item.title}
              </p>
            </li>
          );
        })}
        <div className={styles.line} />
      </ul>
    </div>
  );
}

export default PathBar;

const pathData = [
  { path: "/kbc/sign-up1", n: "1", title: "가입안내" },
  { path: "/kbc/sign-up2", n: "2", title: "약관동의" },
  { path: "/kbc/sign-up3", n: "3", title: "회원정보" },
  { path: "/kbc/sign-up4", n: "4", title: "차량정보" },
  { path: "/kbc/sign-up5", n: "5", title: "가입완료" },
];
