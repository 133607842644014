import axios from 'axios';

// const BASE_URL = 'http://localhost:8084'; // 로컬
// const BASE_URL = 'http://141.164.36.231:8080/sacews'; // http
// const BASE_URL = 'https://www.starautoews.com:8443/sacews'; // https

// saams v2
// const BASE_URL = 'https://www.starautoews.co.kr:8443/saams/ews';

// saams v2, saams.war build 후 sacews.war 로 rename 후 배포
// const BASE_URL = 'https://www.starautoews.co.kr:8443/sacews/ews';

// saams v3
// const BASE_URL = 'http://localhost:8085/v1/ews'; // 로컬
const BASE_URL = 'https://www.starautoews.co.kr:8443/sacews/v1/ews'; // => 현(2024.07.24 14:14:00) 배포 버전

const axiosInstance = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Content-Type': 'application/json',
  },
  baseURL: BASE_URL,
  withCredentials: true,
});

export default axiosInstance;
