import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './login.module.css';
import xBtnIcon from '../../../@assets/xBtn.svg';
import { onToggleLogin } from '../../store/app/toggleSlice';
import { submitLogin, resetError } from '../../store/auth/loginSlice';
import { loginSchema } from '../../config/schemaInfo';

function Login() {
  const dispatch = useDispatch();

  const menuRef = useRef();
  const loginOpen = useSelector(({ app }) => app.toggle.toggleLogin);
  const error = useSelector(({ auth }) => auth.login.error);

  useEffect(() => {
    if (!loginOpen) return;

    const handleClose = (e) => {
      if (menuRef.current?.contains(e.target)) return;
      dispatch(onToggleLogin());
    };

    window.addEventListener('click', handleClose);
    return () => {
      window.removeEventListener('click', handleClose);
    };
  }, [dispatch, loginOpen]);

  const { control, formState, handleSubmit, getValues, reset } = useForm({
    mode: 'onSubmit',
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(loginSchema),
  });
  const { isValid, errors } = formState;

  const loginHandlerToggle = () => {
    reset({ email: '', password: '' });
    dispatch(resetError());
    dispatch(onToggleLogin());
  };
  const onSubmit = () => {
    dispatch(submitLogin(getValues()));
  };

  return (
    <div className={`${loginOpen && styles.layer}`}>
      <section className={`${styles.wrapper} ${loginOpen && styles.wrapper_active}`}>
        <div className={styles.container} ref={menuRef}>
          <h1>
            서비스 이용을 위해
            <br />
            로그인을 해주세요.
          </h1>
          <img src={xBtnIcon} alt="" className={styles.xBtn} onClick={loginHandlerToggle} />
          <form className={styles.formBox} onSubmit={handleSubmit(onSubmit)}>
            <label className={styles.label}>
              이메일 {errors.email?.message && <span>[{errors.email?.message}]</span>}
            </label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  placeholder="이메일을 입력하세요."
                  className={styles.input_field}
                />
              )}
            />

            <label className={styles.label}>비밀번호</label>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="password"
                  className={styles.input_field}
                  placeholder="비밀번호를 입력하세요."
                />
              )}
            />
            {/*<input type="checkbox" id="remember" className={styles.checkBox}/>*/}
            {/*<label htmlFor="remember">아이디 저장</label>*/}

            {/* 에러메시지 */}

            {(!isValid || errors.email || errors.password || !error) && (
              <div className={styles.errorBox}>
                <p>입력된 이메일/비밀번호를 확인해주세요</p>
              </div>
            )}

            <button
              type="submit"
              disabled={!isValid}
              style={isValid ? { backgroundColor: '#6CBED7' } : { backgroundColor: '#ccc' }}
            >
              로그인
            </button>
          </form>
          {/*<div className={styles.forgetBox} onClick={loginHandlerToggle}>*/}
          {/*    <Link to="/find-id">아이디 찾기</Link>*/}
          {/*    <span/>*/}
          {/*    <Link to="/find-password">비밀번호 찾기</Link>*/}
          {/*</div>*/}
          <div>
            <br />
            <br />
          </div>

          <div className={styles.signUp_box}>
            <h2>아직 서비스를 가입하지 않았다면?</h2>
            <Link to="/sign-up1" className={styles.sighUp_btn} onClick={loginHandlerToggle}>
              서비스 가입하기
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
