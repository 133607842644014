export default Object.freeze({
  API: {
    user: {
      login: '/user/login',
      register: '/user/register',
      modify: '/user/modify',
      getState: '/user/getState',
    },
    car: {
      register: '/car/register',
      modify: '/car/modify',
    },
    payment: {
      authorization: '/payment/authorization',
    },
    initialization: {
      siteMenuList: `/manager/site/menu/list`,
      commonCodeList: '/manager/common/code/list',
      labelList: `/manager/common/label/list`,
    },
    account: {
      login: '/user/account/login',
      signup: '/user/account/signup',
      findPassword: '/user/account/pwd/find',
    },
    home: {
      homeInfo: '/user/common/home',
    },
    explore: {
      atmFilterList: '/user/explore/atm/filter/list',
      atmList: '/user/explore/atm/list',
      atmDetailInfo: '/user/explore/atm/info',
      atmCompare: '/user/explore/atm/compare/info',
      partsFilterList: '/user/explore/parts/filter/list',
      partsList: '/user/explore/parts/list',
      partsDetailInfo: '/user/explore/parts/info',
      softwareFilterList: '/user/explore/sw/filter/list',
      softwareList: '/user/explore/sw/list',
      softwareDetailInfo: '/user/explore/sw/info',
      professionalServicesFilterList: '/user/explore/ps/filter/list',
      professionalServicesList: '/user/explore/ps/list',
      professionalServicesDetailInfo: '/user/explore/ps/info',
      managedServicesFilterList: '/user/explore/ms/filter/list',
      managedServicesList: '/user/explore/ms/list',
      managedServicesDetailInfo: '/user/explore/ms/info',
    },
    order: {
      // common
      registerOrder: '/user/order/register',
      registerOrderFavorite: '/user/order/favorite/register',
      registerOrderCart: '/user/order/cart/register',
      // atm options list
      atmOptionsList: '/user/order/atm/options/list',
      atmMaterialCode: '/user/order/atm/material/info',
      materialInfo: '/user/order/material/info',
      registerAtmQuote: '/user/order/atm/quote/register',
      // parts options list
      partsOptionsList: '/user/order/parts/options/list',
      // registerPartsFavorite: '/user/order/parts/favorite/register',
      // registerPartsOrderCart: '/user/order/parts/cart/register',
      // registerPartsOrder: '/user/order/parts/register',
      // software input list
      softwareInputList: '/user/order/sw/input/list',
      // registerSoftwareFavorite: '/user/order/sw/favorite/register',
      // registerSoftwareOrderCart: '/user/order/sw/cart/register',
      // ps
      professionalServicesDetailInfo: '/user/order/ps/info',
      professionalServicesOptionsList: '/user/order/ps/sub/list',
      // registerProfessionalServicesFavorite: '/user/order/ps/favorite/register',
      // registerProfessionalServicesOrderCart: '/user/order/ps/cart/register',
      // ms
      managedServiceInputList: '/user/order/ms/input/list',
      updateManagedServiceOthersInfo: '/user/order/update',
    },
    checkout: {
      // shipping
      shippingInputList: '/user/order/shipping/input/list',
      registerShippingInfo: '/user/order/shipping/register',
      // payment
      verifyPromotionCode: '/user/order/promotion/verify',
      registerPaymentInfo: '/user/order/payment/register',
      // Verify & Submit Order
      submitOrder: '/user/order/submit',
      // trustcommerce
      requestToken: '/user/order/trust/token',
    },
    resources: {
      productCategoryList: '/user/resource/category/list',
      productDocumentList: '/user/resource/document/list',
      productSoftwareList: '/user/resource/sw/list',
      troubleShootingList: '/user/resource/trouble/list',
      faqList: '/user/resource/faq/list',
    },
    about: {
      newsList: '/user/about/news/list',
      newsInfo: '/user/about/news/info',
      eventList: '/user/about/event/list',
      eventInfo: '/user/about/event/info',
    },
    // account: {
    //     doLogin: '/crai/api/v1/account/login',
    //     doLoginV1: '/user/account/login',
    //     doLogout: '/crai/api/v1/account/logout',
    //     getRequestStatus: '/crai/api/v1/account/status',
    //     findId: '/crai/api/v1/account/findid',
    //     findPassword: '/crai/api/v1/account/findpwd',
    //     findCompany: '/crai/api/v1/account/findcmpny',
    //     registerCompany: '/crai/api/v1/account/cmpny/register',
    //     uploadDocumentFiles: '/crai/api/v1/account/cmpny/register',
    //     getNiceEncData: '/crai/api/v1/account/nice',
    //     getNiceDecData: '/crai/api/v1/account/niceres',
    //     idDuplicationCheck: '/crai/api/v1/account/idcheck',
    //     registerAdministrator: '/crai/api/v1/account/admin/register',
    //     registerUser: '/crai/api/v1/account/mber/register',
    //     getUserList: '/crai/api/v1/account/mber/list',
    //     getUserRightsInfo: '/crai/api/v1/account/mber/auth/info',
    //     modifyUserRightsInfo: '/crai/api/v1/account/mber/auth/modify',
    //     getAdministratorInfo: '/crai/api/v1/account/admin/info',
    //     modifyAdministratorInfo: '/crai/api/v1/account/admin/modify',
    //     getCompanyInfo: '/crai/api/v1/account/cmpny/info'
    // },
    review: {
      getReviewDetailInfo: '/crai/api/v1/review/detail',
      getMovieReviewInfo: '/crai/api/v1/review/movie/info',
      requestMovieReview: '/crai/api/v1/review/movie/req',
      modifyMovieReview: '/crai/api/v1/review/movie/modify',
      deleteMovieReview: '/crai/api/v1/review/movie/delete',
      getReviewList: '/crai/api/v1/review/list',
      getReviewCost: '/crai/api/v1/review/cost',
      chargeReview: '/crai/api/v1/review/charge',
    },
    myMenu: {
      myPageInfo: '/user/mypage/info',
      getMyFavoritesAtm: '/user/mypage/favorite/atm',
      getMyFavoriteParts: '/user/mypage/favorite/parts',
      getMyFavoriteSoftware: '/user/mypage/favorite/swsvc',
      editMyFavoriteName: '/user/mypage/favorite/rename',
      addToCartMyFavoriteItems: '/user/mypage/favorite/cart',
      deleteMyFavoriteItems: '/user/mypage/favorite/delete',
      getOrderList: '/user/mypage/order/list',
      getOrderDetail: '/user/mypage/order/info',
      cancelOrder: '/user/mypage/order/cancel',
      getMyProfile: '/user/mypage/profile',
      editMyProfile: '/user/mypage/profile/modify',
      changePwd: '/user/mypage/profile/pwd',
      postFeedback: '/user/mypage/order/feedback',
      getRetailWarrantyList: '/user/mypage/warranty/list',
      getEndCustomerList: '/user/mypage/endcustomer/list',
      registerEndCustomer: '/user/mypage/endcustomer/register',
      getMyRmaList: '/user/mypage/rma/list',
      getMyRmaTrackingInfo: '/user/mypage/rma/tracking/info',
      registerRmaPayment: '/user/mypage/rma/payment/register',
      registerRmaInfoCallback: '/user/mypage/rma/tracking/callback',
      getMySettingList: '/user/mypage/setting/list',
      updateMySetting: '/user/mypage/setting/update',
      getQuoteList: '/user/mypage/quote/list',
      deleteQuotes: '/user/mypage/quote/delete',
      getPartnersOrder: '/user/mypage/partners/order',
      getOrderTrackingInfo: '/user/mypage/order/tracking/info',
      getCartList: '/user/mypage/cart/list',
      cartDelete: '/user/mypage/cart/delete',
      cartOrder: '/user/mypage/cart/order',
      getFinancialDashBord: '/user/mypage/financial/dashboard',
      verifyAtmSerialNumber: '/user/mypage/serial/verify',
      registerInstallation: '/user/mypage/install/register',
    },
    support: {
      getRmaTypeListInfo: '/user/rma/type/list',
      getRmaUserOrderList: '/user/rma/order/list',
      getRmaAtmPartsVerify: '/user/rma/warranty/verify',
      getRmaItemListInfo: '/user/rma/item/list',
      registerRmaWarranty: '/user/rma/warranty/register',
      registerRmaFreightDamageMain: '/user/rma/freight/main/register',
      registerRmaFreightDamageSub: '/user/rma/freight/item/register',
      registerRmaPartRepairExchange: '/user/rma/reex/register',
      submitRmaInfo: '/user/rma/submit',
    },
    common: {
      getShippingList: '/user/common/shipping/list',
      registerShippingList: '/user/common/shipping/register',
      modifyShippingList: '/user/common/shipping/modify',
      deleteShippingList: '/user/common/shipping/delete',
    },
    manager: {
      getPartCategory: '/manager/parts/category/list',
      getLocationTechiniciansList: '/manager/support/technician/list',
      getTechinicalAnnouncementList: '/manager/support/techann/list',
      getAtmOptionsList: '/manager/atm/options/list',
      getAtmOptionsCategoryList: '/manager/atm/options/category/list',
      getAtmMaterialList: '/manager/atm/material/list',
    },
    getUserInfo: '/gsims/api/v1/account/info',
    registerUser: '/gsims/api/v1/account/register',
    editUser: '/gsims/api/v1/account/modify',
    findId: '/gsims/api/v1/account/findid',
    findPassword: '/gsims/api/v1/account/findpwd',

    // common
    getCompanyList: '/gsims/api/v1/company/list',
    getUserList: '/gsims/api/v1/account/userinfo',
    getCategoryList: '/gsims/api/v1/category/list',

    // dashboard
    getDashboardInfo: '/gsims/api/v1/dashboard',

    // request
    getRequestList: '/gsims/api/v1/request/list',
    registerRequest: '/gsims/api/v1/request/register',
    editRequest: '/gsims/api/v1/request/modify',
    completeRequest: '/gsims/api/v1/request/complete',
    deleteRequest: '/gsims/api/v1/request/delete',
    getRequestInfo: '/gsims/api/v1/request/info',
    assignRequestOwner: '/gsims/api/v1/request/assign',
    editRequestProcess: '/gsims/api/v1/request/process',
    attachRequestFile: '/gsims/api/v1/request/file/add',
    deleteRequestFile: '/gsims/api/v1/request/file/delete',

    // problem
    getProblemList: '/gsims/api/v1/problem/list',
    registerProblem: '/gsims/api/v1/problem/register',
    editProblem: '/gsims/api/v1/problem/modify',
    getProblemInfo: '/gsims/api/v1/problem/info',
    deleteProblem: '/gsims/api/v1/problem/delete',

    // contract
    getContractList: '/gsims/api/v1/contract/list',
    registerContract: '/gsims/api/v1/contract/register',
    editContract: '/gsims/api/v1/contract/modify',
    getContractInfo: '/gsims/api/v1/contract/info',
    deleteContract: '/gsims/api/v1/contract/delete',
    attachContractFile: '/gsims/api/v1/contract/file/add',
    deleteContractFile: '/gsims/api/v1/contract/file/delete',
    getSubContractInfo: '/gsims/api/v1/contract/sub/info',

    // document common
    attachDocumentFile: '/gsims/api/v1/document/file/add',
    deleteDocumentFile: '/gsims/api/v1/document/file/delete',

    // report
    getReportList: '/gsims/api/v1/document/check/list',
    registerReport: '/gsims/api/v1/document/check/register',
    getReportInfo: '/gsims/api/v1/document/check/info',
    deleteReport: '/gsims/api/v1/document/check/delete',
    editReport: '/gsims/api/v1/document/check/modify',

    // technical data
    getTechnicalDataList: '/gsims/api/v1/document/tech/list',
    registerTechnicalData: '/gsims/api/v1/document/tech/register',
    getTechnicalDataInfo: '/gsims/api/v1/document/tech/info',
    deleteTechnicalData: '/gsims/api/v1/document/tech/delete',
    editTechnicalData: '/gsims/api/v1/document/tech/modify',

    // meeting minutes data
    getMeetingMinutesList: '/gsims/api/v1/document/meeting/list',
    registerMeetingMinutes: '/gsims/api/v1/document/meeting/register',
    getMeetingMinutesInfo: '/gsims/api/v1/document/meeting/info',
    deleteMeetingMinutes: '/gsims/api/v1/document/meeting/delete',
    editMeetingMinutes: '/gsims/api/v1/document/meeting/modify',

    // sla category
    getSlaRatingCategoryList: '/gsims/api/v1/sla/category/list',
    registerSlaRatingCategory: '/gsims/api/v1/sla/category/register',
    deleteSlaRatingCategory: '/gsims/api/v1/sla/category/delete',
    registerSlaRatingItem: '/gsims/api/v1/sla/item/register',
    deleteSlaRatingItem: '/gsims/api/v1/sla/item/delete',
    modifySlaRatingItem: '/gsims/api/v1/sla/item/modify',

    // sla rating template
    getSlaTemplateList: '/gsims/api/v1/sla/template/list',
    getSlaTemplateInfo: '/gsims/api/v1/sla/template/info',
    saveTemplateInfo: '/gsims/api/v1/sla/template/save',

    // sla rating input
    getSlaRatingInfo: '/gsims/api/v1/sla/evaluation/info',
    registerSlaRatingInfo: '/gsims/api/v1/sla/evaluation/register',
    editSlaRatingInfo: '/gsims/api/v1/sla/evaluation/modify',

    // sla result
    getSlaResultInfo: '/gsims/api/v1/sla/evaluation/result',

    // system category
    editCategory: '/gsims/api/v1/category/modify',
    addCategory: '/gsims/api/v1/category/add',
    deleteCategory: '/gsims/api/v1/category/delete',
    deleteCategoryItem: '/gsims/api/v1/category/item/delete',

    // system user
    deleteUser: '/gsims/api/v1/account/delete',
    approvalUser: '/gsims/api/v1/account/confirm',
    resetUserPassword: '/gsims/api/v1/account/resetpwd',

    // system company
    registerCompany: '/gsims/api/v1/company/register',
    editCompany: '/gsims/api/v1/company/modify',
    deleteCompany: '/gsims/api/v1/company/delete',

    commonCodeValueList: '/manager/common/code/value/list',
  },
  COMMON_CODE: {
    API_PREFIX: process.env.VUE_APP_API_PREFIX,
    USER_STATE: {
      // 가입 승인 중
      SUBSCRIPTION_REVIEW: 1,
      // 결제 대기 중
      WAITING_PAYMENT: 2,
      // 승인 완료
      REGISTRATION_COMPLETE: 3,
    },
  },
  SERVER_CODE: {
    success: 0,
    invalidSessionId: 1006,
    account: {
      doLogin: {
        invalidParameter: '401',
        notRegisteredId: '1001',
        invalidPassword: '1002',
        notConfirmedId: '1003',
      },
      findCompany: {
        noCompanyInfo: 400,
      },
    },
    getDashboardInfo: {
      noDashboardInformation: '1001',
    },
    unauthenticatedUser: 'HS401_UNAUTHORIZED',
    notFound: 'HS404_NOT_FOUND',
    conflictData: 'HS1001_DUPLICATED_EXCEPTION',
    callPushFailed: 'HS1002_PUSH_FAILURE',
    invalidMobileNumber: 'HS10003_INVALID_MOBILE',
    employeesDayoff: 'HS2001_EMPLOYEES_DAYOFF',
    mismatchMobileNumber: 'HS2003_MISMATCH_MOBILE',
    alreadyLogin: 'HS2004_ALREADY_LOGIN',
    unregisterWorkingInfo: 'HS2005_UNREGISTER_WORKING_INFO',
    notWorkingTime: 'HS2006_NOT_WORKING_TIME',
    mobileNotLogin: 'HS2007_MOBILE_NOT_LOGIN',
  },
  ETC: {
    VALIDATION_TYPE_POOL: ['INPUT-CUSTOM', 'SELECT-CUSTOM', 'RADIO-BUTTON-CUSTOM'],
  },
});
