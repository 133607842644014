import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './common.module.css';
import fLogo from '../../@assets/fLogo.svg';
// import fb from "../../@assets/coomon/fb.svg";
// import insta from "../../@assets/coomon/insta.svg";
// import twi from "../../@assets/coomon/twi.svg";
// import lin from "../../@assets/coomon/lin.svg";
import { NO_FOOTER } from '../config/routeInfo';

function Footer() {
  const locaion = useLocation();
  const isFooterHidden = NO_FOOTER.includes(locaion.pathname);

  return (
    <>
      {!isFooterHidden && (
        <footer className={styles.f_wrapper}>
          <div className={styles.f_container}>
            <div className={styles.f_side_menu}>
              <ul>
                <li>상품안내</li>
                <NavLink to="/service-info">상품소개</NavLink>
                <NavLink to="/for-check/step1">견적보기</NavLink>
              </ul>

              <ul>
                <li>About Us</li>
                <a href="https://starautocaregroup.modoo.at/" target="_blank">
                  회사소개
                </a>
              </ul>

              <ul>
                <li>고객 지원</li>
                <NavLink to="/qna/q1">자주 묻는 질문</NavLink>
                <NavLink to="/info">문의하기</NavLink>
              </ul>
            </div>

            <div className={styles.f_info}>
              <img src={fLogo} alt="" />
              <p>
                회사명 . 주식회사 스타오토케어 / 대표자 . 주재형
                <br />
                주소 . 스타오토케어 집중화센타
                <br />
                경기도 용인시 처인구 포곡읍 금어로 276
                <br />
                사업자등록번호 . 318-87-00616
                <br />
                통신사업자번호 . 제 2021-용인처인-00297 호
                <br />
                Tel . 1661-0850
                <br />
                월요일 ~ 금요일 : 09:30 ~ 17:30(점심시간 12:00 ~ 13:00)
                <br />
                주말, 공휴일 휴무
              </p>

              <div className={styles.info_navBox}>
                <NavLink to="/announce1">이용약관</NavLink>
                <NavLink to="/announce2">개인정보처리방침</NavLink>
                <NavLink to="/announce3">환불정책</NavLink>
              </div>

              {/*<div className={styles.socialBox}>*/}
              {/*    <a>*/}
              {/*        <img src={fb} alt=""/>*/}
              {/*    </a>*/}
              {/*    <a>*/}
              {/*        <img src={insta} alt=""/>*/}
              {/*    </a>*/}
              {/*    <a>*/}
              {/*        <img src={twi} alt=""/>*/}
              {/*    </a>*/}
              {/*    <a>*/}
              {/*        <img src={lin} alt=""/>*/}
              {/*    </a>*/}
              {/*</div>*/}

              <span>All rights reserved by © StarAutoCare 2022</span>
            </div>
          </div>
        </footer>
      )}
    </>
  );
}

export default Footer;
