import React, { useCallback, useEffect, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styles from "./info.module.css";
import { infoDeskSchema } from "../../config/schemaInfo";

function Info() {
  const navigate = useNavigate();
  const { control, formState, handleSubmit, setError, getValues } = useForm({
    mode: "onChange",
    resolver: yupResolver(infoDeskSchema),
  });
  const { isValid, errors, isDirty, isSubmitted } = formState;
  const ref = useRef();
  const handleResizeHeight = useCallback(() => {
    if (ref === null || ref.current === null) {
      return;
    }
    ref.current.style.height = "";
    ref.current.style.height = ref.current.scrollHeight + "px";
  }, []);

  const submitHandler = () => {
    if (isValid) {
      alert("문의하기가 완료되었습니다.");
      navigate("/");
    }
  };
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <h1>스타연장보증 문의하기</h1>
        <p>문의를 남겨주시면 24시간 내에 답변을 드립니다.</p>

        <form className={styles.formBox} onSubmit={handleSubmit(submitHandler)}>
          <label className={styles.label}>
            이름
            {errors.fullName?.message && (
              <span>{errors.fullName?.message}</span>
            )}
          </label>
          <Controller
            name="fullName"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                placeholder="이름을 입력하세요"
                className={styles.input_field}
              />
            )}
          />

          <label className={styles.label}>
            이메일
            {errors.email?.message && <span>{errors.email?.message}</span>}
          </label>

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="email"
                className={styles.input_field}
                placeholder="이메일을 입력하세요."
              />
            )}
          />

          <label className={styles.label}>
            전화번호
            {errors.mobile?.message && <span>{errors.mobile?.message}</span>}
          </label>
          <Controller
            name="mobile"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                maxLength="11"
                inputmode="decimal"
                placeholder="'-'를 제외한 번호를 입력하세요."
                className={styles.input_field}
                value={getValues("mobile")}
                onChange={(e) => {
                  if (e.target.value.match("^[0-9]*$")) {
                    field.onChange(e);
                  }
                }}
              />
            )}
          />

          <span className={styles.line} />
          <label className={styles.label}>
            제목{errors.title?.message && <span>{errors.title?.message}</span>}
          </label>

          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className={styles.input_field}
                placeholder="제목을 입력하세요."
              />
            )}
          />

          <label className={styles.label}>
            내용{errors.body?.message && <span>{errors.body?.message}</span>}
          </label>
          <div className={styles.textarea_field}>
            <Controller
              name="body"
              control={control}
              render={({ field }) => (
                <textarea
                  {...field}
                  className={styles.input_field_area}
                  rows="1"
                  placeholder="내용을 입력하세요."
                  onChange={(e) => {
                    field.onChange(e);
                    handleResizeHeight();
                  }}
                  ref={(e) => {
                    field.ref(e);
                    ref.current = e;
                  }}
                />
              )}
            />
          </div>

          <div className={styles.btnBox}>
            <button
              type="button"
              className={styles.btn1}
              onClick={() => navigate(-1)}
            >
              취소
            </button>
            <button
              type="submit"
              className={styles.btn2}
              style={
                isValid
                  ? { backgroundColor: "#6CBED7" }
                  : { backgroundColor: "#ccc" }
              }
            >
              확인
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Info;
