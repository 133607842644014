import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import styles from '../forCheck.module.css'
import {getQueryValue} from "../../../utils/utilityFunctions";

function CheckStep2() {
    const carFrom = getQueryValue('from')
    const carType = getQueryValue('type')
    const [typing, setTyping] = useState('')
    const navigate = useNavigate()
    return (
        <div className={styles.step_wrap}>
            <h2>
                <span>STEP2</span>
                <br/>
                주행거리를 입력하세요.
            </h2>

            <div className={styles.step2_input_wrap}>
                <input
                    type="number"
                    placeholder="주행거리 입력"
                    value={typing}
                    onChange={e => setTyping(e.target.value)}
                />
                <p>Km</p>
            </div>

            <p>
                최초등록일 기준 7년 이내 및 14만Km 이내 차량
            </p>

            <div className={styles.step2_btn_wrap}>
                <button
                    disabled={!typing || parseInt(typing) > 140000}
                    type="button"
                    style={typing && parseInt(typing) < 140000 ? {background: '#4fb4d4'} : {background: '#448397'}}
                    onClick={() => navigate(`/for-check/step3?from=${carFrom}&type=${carType}&mileage=${typing}`)}
                >
                    다음
                </button>
            </div>
        </div>
    )
}

export default CheckStep2
