import { combineReducers } from "@reduxjs/toolkit";
import login from "./loginSlice";
import signUp from "./signUpSlice";
import find from "./findSlice";

const authReducers = combineReducers({
    login,
    signUp,
    find,
});

export default authReducers;
