import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import styles from "../forCheck.module.css";
import {getQueryValue, numberWithCommas} from "../../../utils/utilityFunctions";

function CheckStep4() {
    const navigate = useNavigate();
    const profileData = useSelector(({user}) => user.profile.data);
    const carFrom = getQueryValue('from')
    const carType = getQueryValue('type')
    const mileage = getQueryValue('mileage')
    let quotePrice = 0

    if (carFrom.indexOf('국산차') >= 0) {
        if (carType.indexOf('경차') >= 0) {
            quotePrice = 219000
        } else if (carType.indexOf('중형') >= 0) {
            quotePrice = 292000
        } else if (carType.indexOf('대형') >= 0) {
            quotePrice = 365000
        }
    } else if (carFrom.indexOf('수입차') >= 0) {
        if (carType.indexOf('경차') >= 0) {
            quotePrice = 494000
        } else if (carType.indexOf('중형') >= 0) {
            quotePrice = 714000
        } else if (carType.indexOf('대형') >= 0) {
            quotePrice = 1044000
        }
    }

    useEffect(() => {
        if (!carFrom || !carType) {
            alert('차량정보를 확인할 수 없습니다.')
            navigate(`/for-check/step1`);
        } else if (parseInt(mileage) > 140000 ) {
            alert('안심보증에 가입이 불가한 주행거리입니다.')
            navigate(`/for-check/step1`);
        } else if (quotePrice === 0) {
            alert('안심보증 견적가를 산정할 수 없습니다.')
            navigate(`/for-check/step1`);
        }
    }, [carFrom, carType, mileage, quotePrice]);

    return (
        <div className={styles.step_wrap}>
            <h2>
                <span>STEP4</span>
                <br/>
                고객님을 위한 Star 안심보증 견적가는?
            </h2>
            <div className={styles.sec4_value}>
                <h3>{numberWithCommas(quotePrice)}</h3> &nbsp;&nbsp;
                <span>원 입니다.</span>
            </div>
            {profileData ? (
                <button className={styles.sec4_btn} onClick={() => navigate("/")}>
                    홈으로 돌아가기
                </button>
            ) : (
                <button
                    className={styles.sec4_btn}
                    onClick={() => navigate("/sign-up1")}
                >
                    스타연장보증 가입하기
                </button>
            )}
        </div>
    );
}

export default CheckStep4;
