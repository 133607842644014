import React, { useEffect, useState } from 'react'
import styles from '../forCheck.module.css'
import logo from '../../../../@assets/white-logo.svg'
import backIcon from '../../../../@assets/forCheck/white-backIcon.svg'
import { useNavigate } from 'react-router-dom'
import { ThreeDots } from 'react-loader-spinner'

// KB 차차차 외부에서 접속한 페이지
function OutSideCheckPage() {
  const [isLoading, setIsLoaing] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaing(false)
    }, 1000)
  })

  const navigate = useNavigate()
  return (
    <>
      <section section className={styles.wrapper}>
        {isLoading && (
          <div className={styles.loading_wrap}>
            <ThreeDots
              height="100"
              width="100"
              color="white"
              ariaLabel="loading"
            />
          </div>
        )}

        <div className={styles.container}>
          <div className={styles.backBox} onClick={() => navigate(-1)}>
            <img src={backIcon} alt="" />
            <img src={logo} alt="" className={styles.logo} />
          </div>
          <h1 className={styles.title}>
            원터치로 한번에 <br />
            확인하는 Star 안심보증 견적
          </h1>

          <ul className={styles.outP_wrap}>
            {valueData.map((item, i) => {
              return (
                <li key={i}>
                  <div className={styles.outP_box}>
                    <p className={styles.outside_page_title}>{item.key}</p>
                    <div className={styles.outside_page_value}>
                      {item.val}
                      {item.valType && <span>Km</span>}
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>

          <h2 className={styles.outP_title}>
            <span>STEP4</span>
            <br />
            고객님을 위한 Star 안심보증 견적가는?
          </h2>
          <div className={styles.sec4_value}>
            <h3>164,000</h3> &nbsp;&nbsp;
            <span>원 입니다.</span>
          </div>
          <button
            className={styles.sec4_btn}
            onClick={() => navigate('/sign-up1')}
          >
            스타연장보증 가입하기
          </button>
        </div>
      </section>
    </>
  )
}

export default OutSideCheckPage

const valueData = [
  { key: '차량번호', val: '123가1234', valType: false },
  { key: '차량명', val: '제네시스 GV90', valType: false },
  { key: '연식', val: '2022년식', valType: false },
  { key: '차대번호', val: '123FJ2DSE11212KD', valType: false },
  { key: '주행거리', val: '123FJ2DSE11212KD', valType: true },
]
