import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import reducer from '../../store/auth';
import withReducer from '../../store/withReducer';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveUserData, saveCarData } from '../../store/auth/signUpSlice';
import PathBar from './PathBar';
import styles from './signup.module.css';
import { carInfoSchema } from '../../config/schemaInfo';
import { requestPost, requestPostWithFormData } from '../../utils/apiFunctions';
import Constants from '../../common/Constants';

function SignUp4() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const defaultValues = useSelector(({auth}) => auth.signUp.carData);
  // const signupUserData = useSelector(({auth}) => auth.signUp.userData);

  const { defaultValues, signupUserData } = useSelector(({ auth }) => ({
    defaultValues: auth.signUp.carData,
    signupUserData: auth.signUp.userData,
  }));

  const { control, formState, handleSubmit, getValues, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(carInfoSchema),
  });

  const { isValid, errors } = formState;
  const contractImgRef = useRef(null);
  const dashboardImgRef = useRef(null);
  const [contractImg, setContractImg] = useState(null);
  const [dashboardImg, setDashboardImg] = useState(null);

  const contractImgRefClick = () => {
    contractImgRef.current.click();
  };
  const dashboardImgRefClick = () => {
    dashboardImgRef.current.click();
  };

  const submitSignupUser = async () => {
    const params = {
      email: signupUserData.email,
      password: signupUserData.password1,
      mobile: signupUserData.mobile,
      fullName: signupUserData.fullName,
      birthDate: signupUserData.birthDate,
      gender: signupUserData.gender,
      address: signupUserData.address,
      kind: signupUserData.kind,
      state: 1, // default 가입 승인 중
      marketingAgree: signupUserData.marketingAgree === 'false' ? 0 : 1,
      medium: 1, // 1: online, 2: offline
    };

    return await requestPost(Constants.API.user.register, params);
  };

  const submitSignupCar = async (signupCarData) => {
    let formData = new FormData();
    formData.append('userId', signupCarData.userId);
    // formData.append('promotionPackage', signupCarData.promotionPackage);
    formData.append('promotionPackage', 0);
    formData.append('plateNumber', signupCarData.plateNumber);
    formData.append('modelName', signupCarData.modelName);
    formData.append('modelYear', signupCarData.modelYear);
    formData.append('vin', signupCarData.vin);
    formData.append('purchaseDate', signupCarData.purchaseDate);
    formData.append('mileage', signupCarData.mileage);
    formData.append('contractImage', signupCarData.contractImg);
    formData.append('dashboardImage', signupCarData.dashboardImg);
    formData.append('trader', signupCarData.trader);
    formData.append('businessNumber', signupCarData.businessNumber);

    return await requestPostWithFormData(Constants.API.car.register, formData);
  };

  // useEffect(() => {}, [getValues('contractImg'), getValues('dashboardImg')]);
  const onClickPrevButton = () => {
    dispatch(saveUserData(signupUserData));
    dispatch(saveCarData(getValues()));
    navigate('/kbc/sign-up3?type=' + (signupUserData.kind === 3 ? 'A' : 'B'));
  };

  const onSubmit = async () => {
    try {
      let signupCarData = getValues();

      // 사용자 등록
      const responseSingupUser = await submitSignupUser();
      if (!responseSingupUser || !responseSingupUser.success) {
        alert(responseSingupUser.msg);
        dispatch(saveUserData(signupUserData));
        dispatch(saveCarData(signupCarData));
        return;
      }

      signupCarData.userId = responseSingupUser.data.id;

      // 차량 등록
      const responseSingupCar = await submitSignupCar(signupCarData);
      if (!responseSingupCar || !responseSingupCar.success) {
        alert(responseSingupCar.msg);
        dispatch(saveUserData(signupUserData));
        dispatch(saveCarData(signupCarData));
        return;
      }

      dispatch(saveUserData());
      dispatch(saveCarData());

      navigate('/kbc/sign-up5?type=' + (signupUserData.kind === 3 ? 'A' : 'B'));
    } catch (e) {
      alert(e.msg);
    }
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <PathBar />
        <form className={styles.formBox} onSubmit={handleSubmit(onSubmit)}>
          {/* 차량번호 */}
          <label className={styles.label} style={{ marginTop: 0 }}>
            <p>
              차량번호 <span>(필수)</span>
            </p>
            {errors.plateNumber?.message && (
              <p className={styles.errorMsg}>{errors.plateNumber?.message}</p>
            )}
          </label>
          <Controller
            name="plateNumber"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                maxLength="8"
                className={styles.input_field}
                placeholder="차량번호 입력 예)123가4567"
              />
            )}
          />
          {/* 차량명 */}
          <label className={styles.label}>
            <p>
              차량명 <span>(필수)</span>
            </p>
            {errors.modelName?.message && (
              <p className={styles.errorMsg}>{errors.modelName?.message}</p>
            )}
          </label>
          <Controller
            name="modelName"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className={styles.input_field}
                placeholder="차량명 입력 예)SM5"
              />
            )}
          />
          {/* 연식 */}
          <label className={styles.label}>
            <p>
              연식 <span>(필수)</span>
            </p>
            {errors.modelYear?.message && (
              <p className={styles.errorMsg}>{errors.modelYear?.message}</p>
            )}
          </label>
          <Controller
            name="modelYear"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                minLength="4"
                maxLength="4"
                inputMode="decimal"
                value={getValues('modelYear')}
                className={styles.input_field}
                placeholder="예)2010"
                onChange={(e) => {
                  if (e.target.value.match('^[0-9]*$')) {
                    field.onChange(e);
                  }
                }}
              />
            )}
          />

          {/* 차대번호*/}
          <label className={styles.label}>
            <p>
              차대번호 <span>(필수)</span>
            </p>
            {errors.vin?.message && <p className={styles.errorMsg}>{errors.vin?.message}</p>}
          </label>
          <Controller
            name="vin"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                maxLength="17"
                type="text"
                value={getValues('vin')}
                className={styles.input_field}
                onChange={(e) => {
                  if (e.target.value.match('^[0-9a-zA-Z]*$')) {
                    field.onChange(e);
                  }
                }}
                placeholder="차대번호를 입력하세요."
              />
            )}
          />

          {/* 프로모션 패키지*/}
          {/* <label className={styles.label}>
            <p>
              프로모션 패키지 <span>(필수)</span>
            </p>
            {errors.promotionPackage?.message && (
              <p className={styles.errorMsg}>{errors.promotionPackage?.message}</p>
            )}
          </label>
          <Controller
            name="promotionPackage"
            control={control}
            render={({ field }) => (
              <select
                {...field}
                value={getValues('promotionPackage')}
                className={styles.input_field2}
                onChange={(e) => {
                  setValue('promotionPackage', e.target.value);
                  field.onChange(e);
                }}
              >
                <option value="">프로모션 패키지를 선택하세요.</option>
                <option value="1">
                  안심보증프로그램 + 컨디션체크 / 엔진오일교환 방문점검(국산차 한정)
                </option>
                <option value="2">안심보증프로그램 + 새차처럼 / 코일 카 매트 1대분 지급</option>
              </select>
            )}
          /> */}

          {/* 구입일자*/}
          <label className={styles.label}>
            <p>
              구매일자 <span>(필수)</span>
            </p>
            {errors.purchaseDate?.message && (
              <p className={styles.errorMsg}>{errors.purchaseDate?.message}</p>
            )}
          </label>

          <Controller
            name="purchaseDate"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                minLength="10"
                maxLength="10"
                inputMode="decimal"
                value={getValues('purchaseDate')}
                className={styles.input_field}
                placeholder="YYYY-MM-DD (일,월,연 순으로 입력하세요.)"
                onChange={(e) => {
                  if (e.target.value.match('^[0-9]*$') || e.target.value.match('[-]')) {
                    let formattedValue = e.target.value
                      .replace(/-/g, '')
                      .replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
                    setValue('purchaseDate', formattedValue);
                    // field.onChange(e);
                  }
                }}
              />
            )}
          />
          {/* 계약시 주행거리*/}
          <label className={styles.label}>
            <p>
              계약시 주행거리(km) <span>(필수)</span>
            </p>
            {errors.mileage?.message && (
              <p className={styles.errorMsg}>{errors.mileage?.message}</p>
            )}
          </label>
          <Controller
            name="mileage"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                prefix="km"
                inputMode="decimal"
                value={getValues('mileage')}
                className={styles.input_field}
                placeholder="km단위로 정확히 입력하세요."
                onChange={(e) => {
                  if (e.target.value.match('^[0-9]*$')) {
                    field.onChange(e);
                  }
                }}
              />
            )}
          />

          {/* 계약서 사진 */}
          <label className={styles.label} htmlFor="button-file">
            <p>계약서 사진</p>
            {errors.contractImg?.message && (
              <p className={styles.errorMsg}>{errors.contractImg?.message}</p>
            )}
          </label>
          <div className={styles.two_field}>
            <div
              className={styles.input_field2}
              style={contractImg ? { color: '#333' } : { color: '#ccc' }}
            >
              <Controller
                name="contractImg"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <input
                    accept="image/*"
                    className="hidden"
                    id="button-file"
                    ref={contractImgRef}
                    type="file"
                    onChange={async (e) => {
                      function readFileAsync() {
                        return new Promise((resolve, reject) => {
                          const file = e.target.files[0];
                          if (!file) {
                            return;
                          }
                          const reader = new FileReader();
                          reader.onload = () => {
                            resolve(file);
                          };
                          reader.onerror = reject;
                          reader.readAsBinaryString(file);
                        });
                      }

                      const newImage = await readFileAsync();
                      onChange(newImage);
                      setContractImg(newImage);
                    }}
                  />
                )}
              />
              {contractImg ? contractImg.name : '계약서 사진을 등록하세요.'}
            </div>
            <button type="button" onClick={contractImgRefClick}>
              찾아보기
            </button>
          </div>

          {/* 계기판 사진 */}
          <label className={styles.label}>
            <p>계기판 사진</p>
            {errors.dashboardImg?.message && (
              <p className={styles.errorMsg}>{errors.dashboardImg?.message}</p>
            )}
          </label>
          <div className={styles.two_field}>
            <div
              className={styles.input_field2}
              style={dashboardImg ? { color: '#333' } : { color: '#ccc' }}
            >
              <Controller
                name="dashboardImg"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <input
                    accept="image/*"
                    className="hidden"
                    id="button-file"
                    ref={dashboardImgRef}
                    type="file"
                    onChange={async (e) => {
                      function readFileAsync() {
                        return new Promise((resolve, reject) => {
                          const file = e.target.files[0];
                          if (!file) {
                            return;
                          }
                          const reader = new FileReader();
                          reader.onload = () => {
                            resolve(file);
                          };
                          reader.onerror = reject;
                          reader.readAsBinaryString(file);
                        });
                      }

                      const newImage = await readFileAsync();
                      onChange(newImage);
                      setDashboardImg(newImage);
                    }}
                  />
                )}
              />
              {dashboardImg ? dashboardImg.name : '계기판 사진을 등록하세요.'}
            </div>
            <button type="button" onClick={dashboardImgRefClick}>
              찾아보기
            </button>
          </div>

          {/* 매매상사명*/}
          {/* <label className={styles.label}>매매상사명</label>
          <Controller
            name="trader"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className={styles.input_field}
                placeholder="매매상사명 입력"
              />
            )}
          /> */}
          {/* 사업자번호 */}
          {/* <label className={styles.label}>사업자번호</label>
          <Controller
            name="businessNumber"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                inputMode="decimal"
                maxLength="10"
                value={getValues('businessNumber')}
                className={styles.input_field}
                onChange={(e) => {
                  if (e.target.value.match('^[0-9]*$')) {
                    field.onChange(e);
                  }
                }}
                placeholder="사업자번호 '-' 제외 입력"
              />
            )}
          /> */}

          <div style={{ display: 'flex' }}>
            <button
              type="button"
              className={styles.btn}
              style={({ backgroundColor: '#6CBED7' }, { marginRight: '20px' })}
              onClick={onClickPrevButton}
            >
              이전
            </button>
            <button
              type="submit"
              className={styles.btn}
              저
              style={isValid ? { backgroundColor: '#6CBED7' } : { backgroundColor: '#ccc' }}
            >
              다음
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default withReducer('auth', reducer)(SignUp4);
