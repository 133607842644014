import React, {Fragment, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import styles from "./serviceIntro.module.css";
import infoData from "./serviceIntroData";
import flowIcon from "../../../@assets/footerPage/flowIcon.svg";
import {useSelector} from "react-redux";

function ServiceIntro() {
    const navigate = useNavigate();
    const assuranceInfoRef = useRef();
    const profileData = useSelector(({user}) => user.profile.data);
    return (
        <section className={styles.wrapper}>
            {/* 섹션1 */}
            <div className={styles.sec1_wrapper}>
                <div className={styles.sec1_container}>
                    <h1>
                        <span>Star 안심보증</span>
                        <br/>
                        서비스를 소개합니다.
                    </h1>
                    <ul className={styles.sec1_wrap}>
                        {infoData.sec1.map((item, key) => {
                            return (
                                <li key={key} data-aos="zoom-in" data-aos-delay={item.delay}>
                                    <div className={styles.sec1_content}>
                                        <img src={item.img} alt=""/>
                                        <div className={styles.titleBox}>
                                            <h2>{item.title}</h2>
                                            <h3>{item.p1}</h3>
                                            {item.p && <p>{item.p2}</p>}
                                        </div>
                                        {item.btnName && item.btnName !== '가입하기' && item.btnName !== '더 알아보기' && (
                                            <button
                                                className={styles.sec_content_btn}
                                                onClick={() => navigate(item.link)}
                                            >
                                                {item.btnName}
                                            </button>
                                        )}
                                        {item.btnName && item.btnName === '더 알아보기' && (
                                            <button
                                                className={styles.sec_content_btn}
                                                onClick={() => assuranceInfoRef.current?.scrollIntoView({ behavior: 'smooth' })}
                                            >
                                                {item.btnName}
                                            </button>
                                        )}
                                        {item.btnName && item.btnName === '가입하기' && !profileData && (
                                            <button
                                                className={styles.sec_content_btn}
                                                onClick={() => navigate(item.link)}
                                            >
                                                {item.btnName}
                                            </button>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    {!profileData && (
                        <Link to="/sign-up1" className={styles.signUp_btn}>
                            서비스 가입하기
                        </Link>
                    )}
                </div>
            </div>

            {/* 섹션2 */}
            <div className={styles.sec2_wrapper} ref={assuranceInfoRef}>
                <div className={styles.sec2_container}>
                    <h1>보증범위</h1>
                    <ul className={styles.sec2_wrap}>
                        {infoData.sec2.map((item, key) => {
                            return (
                                <li key={key}>
                                    <div className={styles.sec2_content}>
                                        <h2>{item.title}</h2>
                                        <p>{item.p}</p>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>

                    <h2 className={styles.edge_title}>보장제외 항목</h2>
                    <ul className={styles.sec2_edge_wrap}>
                        {infoData.sec2Edge.map((item, key) => {
                            return (
                                <li key={key}>
                                    {item.span && <span>{item.span}</span>}
                                    <p>{item.p}</p>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            {/* 섹션3 */}
            <div className={styles.sec3_wrapper}>
                <div className={styles.sec3_container}>
                    <h1 className={styles.sec3_title}>보증 수리 절차를 확인하세요!</h1>
                    <ul className={styles.sec3_wrap}>
                        {infoData.sec3.map((item, key) => {
                            return (
                                <Fragment key={key}>
                                    <li key={key} data-aos="fade-up" data-aos-delay={item.delay}>
                                        <div className={styles.sec3_content}>
                                            <img src={item.img} alt=""/>
                                            <div className={styles.sec3_titleBox}>
                                                <h1>{item.title}</h1>
                                                <p>{item.p1}</p>
                                                <p>{item.p2}</p>
                                            </div>
                                        </div>
                                    </li>

                                    {item.flow && (
                                        <img
                                            key={`${key}-arrow`}
                                            src={flowIcon}
                                            alt=""
                                            className={styles.flow}
                                            data-aos="fade-up"
                                            data-aos-delay={item.delay}
                                        />
                                    )}
                                </Fragment>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default ServiceIntro;
