import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PathBar from './PathBar';
import styles from './signup.module.css';
import linkIcon from '../../../@assets/signup/linkIcon.svg';
import { onToggleLogin } from '../../store/app/toggleSlice';
import { useDispatch } from 'react-redux';
import { getQueryValue } from '../../utils/utilityFunctions';

const informedSchema = yup.object().shape({
  all: yup.boolean().default(false),
  termsOfUse: yup.boolean().isTrue('동의가 필요합니다').default(false),
  privacy: yup.boolean().isTrue('동의가 필요합니다').default(false),
  warranty: yup.boolean().isTrue('동의가 필요합니다').default(false),
  privacyOffer: yup.boolean().isTrue('동의가 필요합니다').default(false),
  marketing: yup.boolean().default(false),
});

const defaultValues = {
  all: false,
  termsOfUse: false,
  privacy: false,
  warranty: false,
  privacyOffer: false,
  marketing: false,
};

function SignUp2() {
  const navigate = useNavigate();
  const enterType = getQueryValue('type');
  const {
    control,
    formState,
    formState: { isValid, errors },
    handleSubmit,
    setError,
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(informedSchema),
  });

  useEffect(() => {
    if (
      getValues(['termsOfUse', 'privacy', 'warranty', 'privacyOffer', 'marketing']).includes(false)
    ) {
      setValue('all', false);
    } else {
      setValue('all', true);
    }
  }, [formState]);

  const onClickPrevButton = () => {
    navigate('/kbc/sign-up1?type=' + enterType);
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <PathBar />

        <form>
          <div className={styles.checkForm}>
            <div className={styles.allCheck_box}>
              <Controller
                name="all"
                control={control}
                render={({ field }) => (
                  <>
                    <input
                      {...field}
                      type="checkbox"
                      id="all"
                      checked={getValues('all')}
                      onChange={(e) => {
                        field.onChange(e);
                        if (e.target.checked) {
                          setValue('termsOfUse', true);
                          setValue('privacy', true);
                          setValue('warranty', true);
                          setValue('privacyOffer', true);
                          setValue('marketing', true);
                        } else {
                          reset(defaultValues);
                        }
                      }}
                      className={styles.checkBox}
                    />
                    <label htmlFor="all">
                      전체동의
                      <span style={{ fontSize: 13, color: '#0099ff' }}>(필수/선택항목)</span>
                    </label>
                  </>
                )}
              />
            </div>

            <div className={styles.check_box}>
              <div className={styles.check_title}>
                <Controller
                  name="termsOfUse"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="checkbox"
                        id="termsOfUse"
                        checked={getValues('termsOfUse')}
                        className={styles.checkBox}
                      />
                      <label htmlFor="termsOfUse">
                        이용약관 <span style={{ fontSize: 13, color: '#F13838' }}>(필수)</span>
                      </label>
                    </>
                  )}
                />
              </div>
            </div>
            <div className={styles.info_desk}>
              <b>1. 서비스 대상 차량</b>
              <br />
              - 차량구입일 현재 최초 제조사 출고일로부터 연식 7년 이내 및 주행거리 14만km이내 차량
              승용자동차, 12인승 이하 승합자동차, 1.4톤 미만 화물자동차.
              <br />
              - 아래의 차량은 서비스 대상에서 제외 됩니다.
              <br />
              → 유상운송차량, 특수차량(엠뷸런스, 통학버스, 튜닝, 특장차, 캠핑카 등), 전기차, 수소차,
              신차가격 2억 초과 차량, 고성능 수입차(벤츠 AMG모델, BMW M시리즈, 아우디 S/RS급, 포르쉐
              전차종, 마세라티 전차종, 브랜드별 스포츠카).
              <br />
              <br />
              <b>2. 서비스 범위</b>
              <br />
              귀하가 구입하신 중고자동차를 제조사에서 교부한 취급 설명서의 점검 및 정비주기와
              사용지침에 따라 정상적으로 관리, 사용한 상태에서 본 안내서에 규정하는 서비스기간 및
              거리 이내인 자동차는 그 자동차를 구성하는 엔진, 미션, 제동장치, 조향장치, 에어컨 본체
              부품의 결함에 의한 고장임이 밝혀진 경우 해당부품을 수리 및 교환하여 드립니다.
              <br />
              <br />
              <b>3. 서비스 조건</b>
              <br />
              - 제조사 보증기간 내 차량 : 면책기간 만료시점 또는 제조사별 차량보증기간 종료시점 중
              나중에 종료된 일자로부터 6개월
              <br />
              - 제조사 보증기간 종료 차량 : 면책기간 만료 후 6개월 지난차량 또는 구입 후
              주행거리1만km초과차량
              <br />
              - 면책기간 : 서비스가입일(소유권 이전 등록시점)부터 30일
              <br />
              ※ 본 서비스 가입동의서에 최초 기입된 주행거리는 수정이 불가합니다.
              <br />
              ※ 제조사보증기간이 남아있는 경우, 제조사보증으로 우선수리를 받아야 합니다.
              스타오토케어 안심보증 서비스 이용약관
              <br />
              <br />
              <b>4. 고객부담금</b>
              <br />
              중고차 고객 부담금 국산:100,000원(VAT 포함), 수입 : 200,000원(VAT 포함)은 수리를 위해
              정비공장 입고 시마다 고객님계서 부담해야 하는 기본 금액입니다. 수리비용이 고객부담금을
              초과 시 본 서비스를 통해 보상해 드립니다.
              <br />
              ※ 단, 고장발생시점의 부품가격에 따라 일부변동 될 수 있습니다.
              <br />
              <br />
              <b>5. 서비스 한도</b>
              <br />
              총 보상가능 한도는 국산차 최고 200만원(VAT 포함), 수입차 최고 300만원(VAT 포함)으로,
              해당금액을 초과한 수리비용에 대해서는 고객이 부담해야 합니다. 단, 수리 횟수에 대한
              제한은 없습니다.
              <br />
              <br />
              <b>6. 서비스 대상 부품</b>
              <br />
              서비스 대상 부품은 가입하는 상품에 따라 상이하므로 「상품안내서」에 명시된 각 상품의
              서비스 대상 부품을 확인하여 부시기 바랍니다.
              <br />
              ※ 보증수리시 중고부품 또는 AS용 재생부품이 사용 될 수 있습니다.
              <br />
              <br />
              <b>7. 서비스 제외 부품</b>
              <br />
              1) 소모성 부품 타이밍밸트/팬밸트 등 밸트류 일체, 엔진오일/브레이크오일/리어액슬오일 등
              오일류 일체, 오일필터/에어클리너필터/연료필터/에어컨필터 등 필터류 일체, 스파크플러그/
              글로우플러그 등 플러그류 일체, 퓨즈류, 벌브(램프)류, 고압케이블, 배터리, 브레이크패드,
              라이닝슈, 냉각수, 에어컨가스, 와이퍼블레이드 등
              <br />
              2) 트림류 : 휠, 휠커버, 브레이크 드럼&디스크, 웨더스트립, 도어몰딩류, 판금, 범퍼,
              차체외장 일체(판넬포함), 도어아웃사이드 핸들, 프론트&리어 시트, 헤드레스트,,
              프론트&리어 시트트랙(레일), 플로어카펫, 매트, 유리일체 등
              <br />
              3) 휠 발란스, 휠 얼라이먼트, 타이어, 타이어 위치교환 등
              <br />
              4) 하이브리드 관련 부품
              <br />
              5) 배출가스 관련 부품
              <br />
              <br />
              <b>8. 서비스 대상 부품의 수리 및 교환에 의한 책임제도</b>
              <br />
              서비스 대상 부품의 결함으로 인하여 발생된 고장은 해당 부품의 교환 또는 수리 이외의
              어떠한 것에 대해서도 책임을 지지 아니합니다.
              <br />
              <br />
              <b>9. 서비스에서 제외되는 사항 서비스</b>
              <br />
              기간일지라도 다음 각 사항에 해당하는 경우에는 서비스가 제공되지 않습니다.
              <br />
              1) 본 안내서의 중고차 연장보증 서비스 개시일 이전부터 존재한 고장.
              <br />
              2) 부품, 공임 등 직접 비용을 제외한 교통비, 운반비, 운휴손실비, 유류비 및 기타
              간접비용 일체
              <br />
              3) 구조변경, 엔진 및 변속기 튜닝, ECU 및 매핑 및 그 외 자동차 성능 및 안전에 영향을
              줄만하다고 인정되는 변형 및 개조에 의한 고장
              <br />
              4) 자동차 제조사의 신차 보증수리 항목, 배출가스 규제 관련 부품, 서비스 캠페인, 리콜
              캠페인, 소비자 지원 프로그램에서 수리하는 별도 항목. 제 1 장 총칙
              <br />
              5) 서비스 제외 부품의 손상으로 인하여 서비스 대상 부품의 수리가 발생한 경우.
              <br />
              6) 일반적인 품질 및 기능상 영향이 없다고 인정되는 감각적인 불만사항(즉 이음, 잡음,
              소음, 진동, 냄새, 외관, 작동감각 등)
              <br />
              7) 신차 출고시 장착사양과 다르게 고객이 임의로 교체 장착한 부품의 고장 및 그 부품으로
              연관되어 발생한 고장
              <br />
              8) 도난으로 인한 수리사항 발생시나 사고로 이한 수리 발생시.
              <br />
              9) 자동차 제조사에서 지정한 오일 및 지정한 오일과 계열 및 점도가 동등한 오일 이외의
              것을 사용하여 발생한 고장이나, 오일 교환 주기 미준수 및 미보충으로 인한 고장임이
              확실한 경우.
              <br />
              10) 비포장 도로 및 과속방지턱 등 도로상 돌출부위와의 출돌로 인한 차량 하체의 파손 및
              해당 파손에 기인하는 고장인 경우.
              <br />
              11) 고객이 고의 로 고장낸 경우
              <br />
              12) 적재량 초과, 화재, 천재지변에 의한 고장
              <br />
              13) 경구, 언덕오르기, 속도 등 내구시험으로 발생된 고장
              <br />
              14) 주행거리계의 고장 또는 임의 변조로 정확한 주행거리를 확인 할 수 없는 경우.
              <br />
              15) 자동차보험이 보장하거나 보장하였을 수리 발생시
              <br />
              16) ㈜스타오토케어가 지정하지 않은 정비업체에 의한 수리비용.
              <br />
              17) 보험사의 수리비용보상보험 약관에서 정한 면책사항에 해당하는 경우.
              <br />
              18) 가스켓 부위의 누수 및 누유 통상적인 마모로 인한 누유, 가스켓 접합 부품의 누유.
              <br />
              19) 외부 충격에 의한 고장, 단순 부식, 변색, 헐거워짐, 벗겨짐 등
              <br />
              20) 블랙박스, 네비게이션(별도 장착), 썬팅 등의 용품의 고장
              <br />
              <br />
              <b>10. 차량 소유자의 의무</b>
              <br />
              1) 차량은 항상 자동차 제조사 취급설명서에 규정된 점검 치 정비주기와 사용법에 따라
              관리, 사용하여야 합니다.
              <br />
              2) 부적절한 점검, 정비나 부품의 사용은 차량의 구성품 성능을 저하시키며, 치명적인
              손상을 초래하므로 규정된 내용의 점검 및 정비를 실시하여야 합니다.
              <br />
              <br />
              <b>11. 서비스의 실시</b>
              <br />
              1) 서비스는 ㈜스타오토케어 콜센터(1661-0850)으로 접수 후 안내 절차에 따라 제공 받을 수
              있으며, 제휴정비업체로 입고하여 진행해주셔야 합니다. 임의로 수리한 사항은 서비스
              대상에서 제외됩니다.
              <br />
              2) 콜센터 접수 후 안내 받은 제휴정비업체에 접수일로부터 7일 이내에 입고 및 수리 진행이
              이루어져야하며, 부득이하게 기한이 지난 경우에는 콜센터 문의 후 안내에 따라 주시기
              바랍니다.
              <br />
              3) 해당부품의 교체가 필요한 경우 재생품, 중고품으로 보증수라가 진행되는 것을 원칙으로
              하며, 해당 부품의 수급이 원할하지 않을 경우 수리 시간이 지연될 수도 있습니다.
              <br />
              4) 정비대차는 제공되지 않습니다.
              <br />
              5) 픽업(Pick-up), 긴급충동 및 견인서비스는 본 서비스에서는 제공되지 않습니다.
              <br />
              <br />
              <b>12. 서비스의 중도 종료</b>
              <br />
              본 서비스는 다음 각 사항에 해당하는 경우에는 서비스 기간 만료 시점 이전에 자동
              종료됩니다.
              <br />
              1) KB캐피탈 중고차론, 중고차리스 대출 개시일로부터 6개월 이내에 대출금 전액을 중도에
              상환 할 경우, 상환 시점에 즉시 종료
              <br />
              2) KB캐피탈 중고차론, 중고차리스 대출 개시일로부터 6개월 이내에 대출을 취소한 경우,
              대출 취소 시점에 즉시 종료
              <br />
              3) 차량 매매, 기증 등으로 인해 소유자가 변경되는 경우에는 변경시점에서 즉시 종료 ※
              제조사 무상 보증기간 만료 전인 차량도 위 내용과 동일하게 적용 됩니다. ※
              ㈜스타오토케어에서는 상기 기술한 서비스 사항에 한하여 성실하게 처리해 드릴 예정입니다.
              수리 후 문의사항 발생시 ㈜스타오토케어(1661-0850)으로 연락바랍니다. ※ 전화상담 운영
              시간 : 월 ~ 금요일 9:00~18:00(토 · 일요일, 법정휴무일은 휴무) ※ 본 서비스는 KB캐피탈
              중고차론, 중고차리스 서비스 가입일부터 유효합니다. ※ 긴급출동 및 견인, 렌터카 서비스는
              제공되지 않습니다. ※ 제조사 보증 만료 시점의 경우 제조사에서 정한 차종별 무상보증 만료
              시점 기준에 따릅니다. (단, 별도 명기 시 예외)
              <br />
              <br />
              <b>13. 환불정책</b>
              <br />
              상품 구매 확정일 이후 7일 이내 서비스를 철회하실 경우 전액 환불 가능합니다.
            </div>

            <div className={styles.check_box} style={{ marginTop: 40 }}>
              <div className={styles.check_title}>
                <Controller
                  name="privacy"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="checkbox"
                        id="privacy"
                        checked={getValues('privacy')}
                        className={styles.checkBox}
                      />
                      <label htmlFor="privacy">
                        개인정보 수집 및 이용안내&nbsp;
                        <span style={{ fontSize: 13, color: '#F13838' }}>(필수)</span>
                      </label>
                    </>
                  )}
                />
              </div>

              <p className={styles.check_info}>
                개인정보 수집 및 이용에 대한 동의를 거부할 수 있으며, 이 경우 회원가입이 제한됩니다.
              </p>
            </div>
            <div className={styles.info_desk}>
              <b>1. 수집하는 개인정보의 항목 및 수집방법</b>
              <br />
              <br />
              가. 수집하는 개인정보의 항목 “회사”는 회원가입, 원활한 고객상담, 각종 서비스의 제공을
              위해 아래와 같은 항목의 개인정보를 수집하고 있습니다.
              <br />
              1) 회원가입 및 서비스가입 : 이름, 휴대전화번호, 아이디, 비밀번호, 생년월일, E-mail,
              주소, 차량정보, 차량구입정보
              <br />
              <br />
              나. 개인정보의 수집방법
              <br />
              “회사”는 [개인정보 수집ㆍ이용 동의]에 대해 「동의」또는 「동의하지 않음」버튼을 클릭할
              수 있는 절차를 마련하여, 「동의」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로
              보며, 다음과 같은 방법으로 개인정보를 수집합니다.
              <br />
              1) 홈페이지, 서면 양식, 팩스, 전화, E-mail, 이벤트 응모, 배송요청
              <br />
              2) 협력회사로부터의 제공
              <br />
              3) 생성정보 수집 툴을 통한 수집
              <br />
              4) 이벤트나 추가 회원정보 수집을 통해 이용자의 관심분야에 대한 세부화된 항목의 정보는
              수집될 수 있습니다. 또한 서비스 이용과정이나 사업 처리 과정에서 아래와 같은 정보들이
              생성되어 수집될 수 있습니다.
              <br />
              ① 이용자의 브라우저 종류 및 OS, 검색어, 서비스 이용 기록 : 서비스이용에 대한 통계분석
              <br />
              ② IP Address, 방문 일시, 서비스 이용기록, 불량 이용 기록, 쿠키 : 불법/부정이용 방지
              <br />③ 결제 기록 : 전자금융거래기록보관 다. “회사”는 서비스 제공에 필요한 최소한의
              개인정보만 수집하며, 이용자의 기본적 인권을 침해할 우려가 있는 민감한 정보(인종, 종교,
              사상, 출신지, 본적지, 정치적 성향 및 범죄기록, 건강상태 등)는 수집하지 않습니다. 다만,
              이용자가 수집에 동의하시는 경우는 예외로 합니다.
              <br />
              <br />
              <b>2. 개인정보의 수집 및 이용목적</b>
              <br />
              가. 회원제 서비스 이용에 따른 본인여부 식별(확인), 회원가입
              <br />
              나. 고지사항 전달, 본인 의사 확인, SMS 서비스 가입 확인, 불만 처리 등 원활한 의사소통
              경로의 확보, 분쟁 조정을 위한 기록 보존 등 고객 CS 업무 처리
              <br />
              다. 스타안심보증 홈페이지를 이용한 구매 업무
              <br />
              라. 스타안심보증 홈페이지 이용에 대한 고객 문의 상담 업무
              <br />
              마. 서비스 개발 및 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성
              확인, 이벤트 및 광고성 정보 제공, 접속빈도 파악, 이용자의 서비스이용에 대한 통계,
              KB캐피탈 이용고객여부 확인, 오프라인(직거래센터)에서의 매매업자 회원 및 광고 관리 외
              제휴 상품 및 서비스에 대한 제반 마케팅(대행포함) 활동 (EM, SMS, TM, DM 발송 등 마케팅,
              판촉행사 및 이벤트, 사은행사 소개 등 포함)
              <br />
              <br />
              <b>3. 개인정보의 보유 및 이용기간 “회사”는</b>
              <br />
              「개인정보의 처리 목적」이 달성될 때까지 개인정보를 보유합니다. 단, 정보주체로부터
              사전에 동의를 받은 경우 또는 상법 등 관련법규에 의하여 보존할 필요성이 있는 다음의
              경우에는 개인정보를 일정기간 동안 보유합니다. 가. “회사”는 고객이 탈회하거나, 고객을
              제명하는 경우 권리남용, 악용방지, 권리침해/명예훼손 분쟁 및 수사협조 의뢰에 대비하여
              이용계약 해지일로부터 3년 동안 개인정보를 보존합니다. 나. “회사”는 다음의 정보에
              대해서 명시한 기간 동안 보존합니다.
              <br />
              1) 계약 또는 청약철회 등에 관한 기록 보존 - 보존이유 : 전자상거래 등에서의 소비자
              보호에 관한 법률 - 보존기간 : 5년
              <br />
              2) 대금결제 및 재화 등의 공급에 관한 기록 보존 - 보존이유 : 전자상거래 등에서의 소비자
              보호에 관한 법률 - 보존기간 : 5년
              <br />
              3) 소비자의 불만 또는 분쟁처리에 관한 기록 보존 - 보존이유 : 전자상거래 등에서의
              소비자 보호에 관한 법률 - 보존기간 : 3년
              <br />
              4) 기타 고객의 동의를 받은 경우 - 보존기간 : 동의를 받은 기간까지
            </div>

            <div className={styles.check_box} style={{ marginTop: 40 }}>
              <div className={styles.check_title}>
                <Controller
                  name="warranty"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="checkbox"
                        id="warranty"
                        checked={getValues('warranty')}
                        className={styles.checkBox}
                      />
                      <label htmlFor="warranty">
                        KB보증(무료혜택) 규정 안내&nbsp;
                        <span style={{ fontSize: 13, color: '#F13838' }}>(필수)</span>
                      </label>
                    </>
                  )}
                />
              </div>
            </div>
            <div className={styles.info_desk}>
              <b>1. 서비스 대상 차량</b>
              <br />
              - 차량구입일 현재 최초 제조사 출고일로부터 연식 7년 이내 및 주행거리 14만km이내 차량
              승용자동차, 12인승 이하 승합자동차, 1.4톤 미만 화물자동차.
              <br />
              - 아래의 차량은 서비스 대상에서 제외 됩니다.
              <br />
              → 유상운송차량, 특수차량(엠뷸런스, 통학버스, 튜닝, 특장차, 캠핑카 등), 전기차, 수소차,
              신차가격 2억 초과 차량, 고성능 수입차(벤츠 AMG모델, BMW M시리즈, 아우디 S/RS급, 포르쉐
              전차종, 마세라티 전차종, 브랜드별 스포츠카).
              <br />
              <br />
              <b>2. 서비스 범위</b>
              <br />
              귀하가 구입하신 중고자동차를 제조사에서 교부한 취급 설명서의 점검 및 정비주기와
              사용지침에 따라 정상적으로 관리, 사용한 상태에서 본 안내서에 규정하는 서비스기간 및
              거리 이내인 자동차는 그 자동차를 구성하는 엔진, 미션, 제동장치, 조향장치, 에어컨 본체
              부품의 결함에 의한 고장임이 밝혀진 경우 해당부품을 수리 및 교환하여 드립니다.
              <br />
              <br />
              <b>3. 서비스 조건</b>
              <br />
              - 제조사 보증기간 내 차량 : 면책기간 만료시점 또는 제조사별 차량보증기간 종료시점 중
              나중에 종료된 일자로부터 6개월
              <br />
              - 제조사 보증기간 종료 차량 : 면책기간 만료 후 6개월 지난차량 또는 구입 후
              주행거리1만km초과차량
              <br />
              - 면책기간 : 서비스가입일(소유권 이전 등록시점)부터 30일
              <br />
              ※ 본 서비스 가입동의서에 최초 기입된 주행거리는 수정이 불가합니다.
              <br />
              ※ 제조사보증기간이 남아있는 경우, 제조사보증으로 우선수리를 받아야 합니다.
              스타오토케어 안심보증 서비스 이용약관
              <br />
              <br />
              <b>4. 고객부담금</b>
              <br />
              중고차 고객 부담금 국산:100,000원(VAT 포함), 수입 : 200,000원(VAT 포함)은 수리를 위해
              정비공장 입고 시마다 고객님계서 부담해야 하는 기본 금액입니다. 수리비용이 고객부담금을
              초과 시 본 서비스를 통해 보상해 드립니다.
              <br />
              ※ 단, 고장발생시점의 부품가격에 따라 일부변동 될 수 있습니다.
              <br />
              <br />
              <b>5. 서비스 한도</b>
              <br />
              총 보상가능 한도는 국산차 최고 200만원(VAT 포함), 수입차 최고 300만원(VAT 포함)으로,
              해당금액을 초과한 수리비용에 대해서는 고객이 부담해야 합니다. 단, 수리 횟수에 대한
              제한은 없습니다.
              <br />
              <br />
              <b>6. 서비스 대상 부품</b>
              <br />
              서비스 대상 부품은 가입하는 상품에 따라 상이하므로 「상품안내서」에 명시된 각 상품의
              서비스 대상 부품을 확인하여 부시기 바랍니다.
              <br />
              ※ 보증수리시 중고부품 또는 AS용 재생부품이 사용 될 수 있습니다.
              <br />
              <br />
              <b>7. 서비스 제외 부품</b>
              <br />
              1) 소모성 부품 타이밍밸트/팬밸트 등 밸트류 일체, 엔진오일/브레이크오일/리어액슬오일 등
              오일류 일체, 오일필터/에어클리너필터/연료필터/에어컨필터 등 필터류 일체, 스파크플러그/
              글로우플러그 등 플러그류 일체, 퓨즈류, 벌브(램프)류, 고압케이블, 배터리, 브레이크패드,
              라이닝슈, 냉각수, 에어컨가스, 와이퍼블레이드 등
              <br />
              2) 트림류 : 휠, 휠커버, 브레이크 드럼&디스크, 웨더스트립, 도어몰딩류, 판금, 범퍼,
              차체외장 일체(판넬포함), 도어아웃사이드 핸들, 프론트&리어 시트, 헤드레스트,,
              프론트&리어 시트트랙(레일), 플로어카펫, 매트, 유리일체 등
              <br />
              3) 휠 발란스, 휠 얼라이먼트, 타이어, 타이어 위치교환 등
              <br />
              4) 하이브리드 관련 부품
              <br />
              5) 배출가스 관련 부품
              <br />
              <br />
              <b>8. 서비스 대상 부품의 수리 및 교환에 의한 책임제도</b>
              <br />
              서비스 대상 부품의 결함으로 인하여 발생된 고장은 해당 부품의 교환 또는 수리 이외의
              어떠한 것에 대해서도 책임을 지지 아니합니다.
              <br />
              <br />
              <b>9. 서비스에서 제외되는 사항 서비스</b>
              <br />
              기간일지라도 다음 각 사항에 해당하는 경우에는 서비스가 제공되지 않습니다.
              <br />
              1) 본 안내서의 중고차 연장보증 서비스 개시일 이전부터 존재한 고장.
              <br />
              2) 부품, 공임 등 직접 비용을 제외한 교통비, 운반비, 운휴손실비, 유류비 및 기타
              간접비용 일체
              <br />
              3) 구조변경, 엔진 및 변속기 튜닝, ECU 및 매핑 및 그 외 자동차 성능 및 안전에 영향을
              줄만하다고 인정되는 변형 및 개조에 의한 고장
              <br />
              4) 자동차 제조사의 신차 보증수리 항목, 배출가스 규제 관련 부품, 서비스 캠페인, 리콜
              캠페인, 소비자 지원 프로그램에서 수리하는 별도 항목. 제 1 장 총칙
              <br />
              5) 서비스 제외 부품의 손상으로 인하여 서비스 대상 부품의 수리가 발생한 경우.
              <br />
              6) 일반적인 품질 및 기능상 영향이 없다고 인정되는 감각적인 불만사항(즉 이음, 잡음,
              소음, 진동, 냄새, 외관, 작동감각 등)
              <br />
              7) 신차 출고시 장착사양과 다르게 고객이 임의로 교체 장착한 부품의 고장 및 그 부품으로
              연관되어 발생한 고장
              <br />
              8) 도난으로 인한 수리사항 발생시나 사고로 이한 수리 발생시.
              <br />
              9) 자동차 제조사에서 지정한 오일 및 지정한 오일과 계열 및 점도가 동등한 오일 이외의
              것을 사용하여 발생한 고장이나, 오일 교환 주기 미준수 및 미보충으로 인한 고장임이
              확실한 경우.
              <br />
              10) 비포장 도로 및 과속방지턱 등 도로상 돌출부위와의 출돌로 인한 차량 하체의 파손 및
              해당 파손에 기인하는 고장인 경우.
              <br />
              11) 고객이 고의 로 고장낸 경우
              <br />
              12) 적재량 초과, 화재, 천재지변에 의한 고장
              <br />
              13) 경구, 언덕오르기, 속도 등 내구시험으로 발생된 고장
              <br />
              14) 주행거리계의 고장 또는 임의 변조로 정확한 주행거리를 확인 할 수 없는 경우.
              <br />
              15) 자동차보험이 보장하거나 보장하였을 수리 발생시
              <br />
              16) ㈜스타오토케어가 지정하지 않은 정비업체에 의한 수리비용.
              <br />
              17) 보험사의 수리비용보상보험 약관에서 정한 면책사항에 해당하는 경우.
              <br />
              18) 가스켓 부위의 누수 및 누유 통상적인 마모로 인한 누유, 가스켓 접합 부품의 누유.
              <br />
              19) 외부 충격에 의한 고장, 단순 부식, 변색, 헐거워짐, 벗겨짐 등
              <br />
              20) 블랙박스, 네비게이션(별도 장착), 썬팅 등의 용품의 고장
              <br />
              <br />
              <b>10. 차량 소유자의 의무</b>
              <br />
              1) 차량은 항상 자동차 제조사 취급설명서에 규정된 점검 치 정비주기와 사용법에 따라
              관리, 사용하여야 합니다.
              <br />
              2) 부적절한 점검, 정비나 부품의 사용은 차량의 구성품 성능을 저하시키며, 치명적인
              손상을 초래하므로 규정된 내용의 점검 및 정비를 실시하여야 합니다.
              <br />
              <br />
              <b>11. 서비스의 실시</b>
              <br />
              1) 서비스는 ㈜스타오토케어 콜센터(1661-0850)으로 접수 후 안내 절차에 따라 제공 받을 수
              있으며, 제휴정비업체로 입고하여 진행해주셔야 합니다. 임의로 수리한 사항은 서비스
              대상에서 제외됩니다.
              <br />
              2) 콜센터 접수 후 안내 받은 제휴정비업체에 접수일로부터 7일 이내에 입고 및 수리 진행이
              이루어져야하며, 부득이하게 기한이 지난 경우에는 콜센터 문의 후 안내에 따라 주시기
              바랍니다.
              <br />
              3) 해당부품의 교체가 필요한 경우 재생품, 중고품으로 보증수라가 진행되는 것을 원칙으로
              하며, 해당 부품의 수급이 원할하지 않을 경우 수리 시간이 지연될 수도 있습니다.
              <br />
              4) 정비대차는 제공되지 않습니다.
              <br />
              5) 픽업(Pick-up), 긴급충동 및 견인서비스는 본 서비스에서는 제공되지 않습니다.
              <br />
              <br />
              <b>12. 서비스의 중도 종료</b>
              <br />
              본 서비스는 다음 각 사항에 해당하는 경우에는 서비스 기간 만료 시점 이전에 자동
              종료됩니다.
              <br />
              1) KB캐피탈 중고차론, 중고차리스 대출 개시일로부터 6개월 이내에 대출금 전액을 중도에
              상환 할 경우, 상환 시점에 즉시 종료
              <br />
              2) KB캐피탈 중고차론, 중고차리스 대출 개시일로부터 6개월 이내에 대출을 취소한 경우,
              대출 취소 시점에 즉시 종료
              <br />
              3) 차량 매매, 기증 등으로 인해 소유자가 변경되는 경우에는 변경시점에서 즉시 종료 ※
              제조사 무상 보증기간 만료 전인 차량도 위 내용과 동일하게 적용 됩니다. ※
              ㈜스타오토케어에서는 상기 기술한 서비스 사항에 한하여 성실하게 처리해 드릴 예정입니다.
              수리 후 문의사항 발생시 ㈜스타오토케어(1661-0850)으로 연락바랍니다. ※ 전화상담 운영
              시간 : 월 ~ 금요일 9:00~18:00(토 · 일요일, 법정휴무일은 휴무) ※ 본 서비스는 KB캐피탈
              중고차론, 중고차리스 서비스 가입일부터 유효합니다. ※ 긴급출동 및 견인, 렌터카 서비스는
              제공되지 않습니다. ※ 제조사 보증 만료 시점의 경우 제조사에서 정한 차종별 무상보증 만료
              시점 기준에 따릅니다. (단, 별도 명기 시 예외)
              <br />
              <br />
              <b>13. 환불정책</b>
              <br />
              상품 구매 확정일 이후 7일 이내 서비스를 철회하실 경우 전액 환불 가능합니다.
            </div>

            <div className={styles.check_box} style={{ marginTop: 40 }}>
              <div className={styles.check_title}>
                <Controller
                  name="privacyOffer"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="checkbox"
                        id="privacyOffer"
                        checked={getValues('privacyOffer')}
                        className={styles.checkBox}
                      />
                      <label htmlFor="privacyOffer">
                        개인정보 제3자 제공 동의&nbsp;
                        <span style={{ fontSize: 13, color: '#F13838' }}>(필수)</span>
                      </label>
                    </>
                  )}
                />
              </div>
            </div>
            <div className={styles.info_desk}>
              다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
              <br />
              <br />
              1. 개인정보를 제공받는 자 : KB캐피탈
              <br />
              <br />
              2. 제공받는 자의 개인정보 이용목적 : 캐피탈 이용 확인, 보증수리 가입확인, 수리내역확인
              <br />
              <br />
              3. 제공하는 개인정보 항목 : 성명, 생년월일, 이메일, 휴대폰번호, 주소, 차량계약내용,
              차량정보
              <br />
              <br />
              4. 제공받는 자의 보유 및 이용기간 : 회원탈퇴시 또는 개인정보 제3자 제공 철회 시까지
              <br />
              <br />
            </div>

            <div className={styles.check_box} style={{ marginTop: 40 }}>
              <div className={styles.check_title}>
                <Controller
                  name="marketing"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="checkbox"
                        id="marketing"
                        checked={getValues('marketing')}
                        className={styles.checkBox}
                      />
                      <label htmlFor="marketing">
                        마케팅 정보 수신 및 활용&nbsp;
                        <span style={{ fontSize: 13, color: '#797979' }}>(선택)</span>
                      </label>
                    </>
                  )}
                />
              </div>

              <p className={styles.check_info}>
                동의를 거부할 수 있습니다. 단, 미동의시 KB보증(무료혜택) 서비스가 제공하는 혜택
                알림을 받으실 수 없습니다.
              </p>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <button
              type="button"
              className={styles.btn}
              style={({ backgroundColor: '#6CBED7' }, { marginRight: '20px' })}
              onClick={onClickPrevButton}
            >
              이전
            </button>
            <button
              type="button"
              onClick={() =>
                navigate(
                  '/kbc/sign-up3?type=' + enterType + '&marketingagree=' + getValues('marketing')
                )
              }
              disabled={!isValid}
              className={styles.btn}
              style={isValid ? { backgroundColor: '#6CBED7' } : { backgroundColor: '#ccc' }}
            >
              다음
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default SignUp2;
