import { createSlice } from "@reduxjs/toolkit";
import { FAKE_USER_INFO } from "../../config/profileInfo";

export const submitFindPassword = ({ id, fullName }) => async (dispatch) => {
  if (FAKE_USER_INFO.id == id && FAKE_USER_INFO.fullName == fullName) {
    dispatch(findSuccess());
  } else {
    dispatch(findError());
  }
};

const initialState = {
  success: false,
  error: false,
};

const findSlice = createSlice({
  name: "auth/find",
  initialState,
  reducers: {
    findSuccess: (state, action) => {
      state.success = true;
      state.error = false;
    },
    findError: (state, action) => {
      state.success = false;
      state.error = true;
    },
    resetError: (state, action) => {
      state.error = false;
    },
  },
});

export const { findSuccess, findError, resetError } = findSlice.actions;

export default findSlice.reducer;
