import * as yup from 'yup';

/* 회원 가입시 개인 정보 validation */

export const privateInfoSchema = yup.object().shape({
  fullName: yup.string().required('이름을 입력하세요.'),
  birthDate: yup.string().length(8, '8자리로 입력해주세요').required('생년월일을 입력하세요.'),
  gender: yup
    .string()
    .equals(['male', 'female'], '성별을 선택하세요.')
    .required('성별을 선택하세요.'),
  // id: yup
  //     .string()
  //     .required("아이디를 입력해주세요.")
  //     .min(4, "4자 이상 입력해주세요.")
  //     .max(12, "12자 이하로 입력해주세요.")
  //     .matches("(?=.*[a-z])", "영문 소문자를 포함해주세요."),
  //     // .matches("(?=.*?[0-9])", "숫자를 포함해주세요."),
  password1: yup
    .string()
    .required('비밀번호를 입력하세요')
    .min(8, '8자 이상 입력해주세요.')
    .max(16, '16자 이하로 입력해주세요.')
    .matches('(?=.*[!@#$%^&*])', '특수문자를 포함해주세요.')
    // .matches("(?=.*[A-Z])", "영문 대문자를 포함해주세요.")
    .matches('(?=.*[0-9])', '숫자를 포함해주세요.')
    .matches('(?=.*[a-z])', '영문 소문자를 포함해주세요.'),
  password2: yup
    .string()
    .required('비밀번호를 확인해주세요.')
    .equals([yup.ref('password1')], '다시 입력해주세요.'),
  mobile: yup.string().required('휴대폰 번호를 입력하세요.').length(11, '번호 전체를 입력해주세요'),
  // code: yup.string().length(4, "").required("인증 번호를 입력하세요."),
  email: yup.string().email('형식에 맞게 입력해주세요').required('이메일을 입력하세요.'),
  address: yup.string().required('거주지를 입력하세요.'),
  // kind: yup.string().required("고객 타입을 선택하세요.")
});

/* 회원 가입시 차량 정보 validation */

export const carInfoSchema = yup.object().shape({
  // promotionPackage: yup.string().required('프로모션 패키지를 선택하세요.'),
  plateNumber: yup.string().required('차량 번호를 입력하세요.'),
  modelName: yup.string().required('차량 이름을 입력하세요.'),
  modelYear: yup.string().length(4, '4자리로 입력해주세요').required('연식을 입력하세요.'),
  vin: yup.string().length(17, '17자리로 입력해주세요').required('차대 번호를 입력해주세요.'),
  purchaseDate: yup.string().required('구매일자를 입력하세요.').length(10, ''),
  mileage: yup
    .number()
    .typeError('계약 시 주행거리를 입력하세요.')
    .lessThan(140000, '14만 Km 이내 차량만 가입이 가능합니다.'),
  // contractImg: yup.string().required('계약서 사진을 등록하세요.'),
  // dashboardImg: yup.string().required('계기판 사진을 등록하세요.'),
  // trader: yup.string(),
  // businessNumber: yup.string(),
});

/* 회원 가입 후 차량 정보 수정하기  validation*/

export const carInfoSchemaAfterSignUp = yup.object().shape({
  plateNumber: yup.string().required('차량 번호를 입력하세요.'),
  modelName: yup.string().required('차량 이름을 입력하세요.'),
  modelYear: yup.string().length(4, '4자리로 입력해주세요').required('연식을 입력하세요.'),
  vin: yup.string().length(17, '17자리로 입력해주세요').required('차대 번호를 입력해주세요.'),
  purchaseDate: yup.string().required('구매일자를 입력하세요.'),
  mileage: yup
    .number()
    .typeError('계약 시 주행거리를 입력하세요.')
    .lessThan(140000, '14만 Km 이내 차량만 가입이 가능합니다.'),
  // trader: yup.string(),
  // businessNumber: yup.string(),
});

/* 정보 수정 전, 비밀번호 validation */

export const passwordSchema = yup.object().shape({
  password: yup.string().required('비밀번호를 입력하세요.'),
});

/* 로그인 validation */

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required('이메일을 입력해주세요.')
    .email('형식에 맞게 입력해주세요.')
    .default(''),
  password: yup.string().required('비밀번호를 입력하세요.').default(''),
});

/* 비밀번호 찾기 validation */

export const findPasswordSchema = yup.object().shape({
  email: yup.string().required('이메일을 입력해주세요.').default(''),
  fullName: yup.string().required('이름을 입력하세요.').default(''),
});

/* 문의하기 validation */

export const infoDeskSchema = yup.object().shape({
  fullName: yup.string().required('이름을 입력하세요.').default(''),
  mobile: yup
    .string()
    .required('휴대폰 번호를 입력하세요.')
    .length(11, '번호 전체를 입력하세요.')
    .default(''),
  email: yup
    .string()
    .required('이메일을 입력하세요.')
    .email('형식에 맞게 입력해주세요')
    .default(''),
  title: yup.string().required('제목을 입력하세요.').default(''),
  body: yup.string().required('내용을 입력하세요.').default(''),
});

export const privateInfoSchemaInChange = yup.object().shape({
  fullName: yup.string().required('이름을 입력하세요.'),
  birthDate: yup.string().length(8, '8자리로 입력해주세요').required('생년월일을 입력하세요.'),
  gender: yup
    .string()
    .equals(['male', 'female'], '성별을 선택하세요.')
    .required('성별을 선택하세요.'),
  email: yup.string().email('형식에 맞게 입력해주세요').required('이메일을 입력하세요.'),
  // id: yup
  //     .string()
  //     .required("아이디를 입력해주세요.")
  //     .min(4, "4자 이상 입력해주세요.")
  //     .max(12, "12자 이하로 입력해주세요.")
  //     .matches("(?=.*[a-z])", "영문 소문자를 포함해주세요.")
  //     .matches("(?=.*?[0-9])", "숫자를 포함해주세요."),

  // password1: yup
  //     .string()
  //     // .required("비밀번호를 입력하세요")
  //     .min(8, "8자 이상 입력해주세요.")
  //     .max(16, "16자 이하로 입력해주세요.")
  //     .matches("(?=.*[!@#$%^&*])", "특수문자를 포함해주세요.")
  //     .matches("(?=.*[0-9])", "숫자를 포함해주세요.")
  //     .matches("(?=.*[a-z])", "영문 소문자를 포함해주세요."),
  password1: yup.string().when({
    is: (value) => !!value,
    then: yup
      .string()
      .min(8, '8자 이상 입력해주세요.')
      .max(16, '16자 이하로 입력해주세요.')
      .matches('(?=.*[!@#$%^&*])', '특수문자를 포함해주세요.')
      .matches('(?=.*[0-9])', '숫자를 포함해주세요.')
      .matches('(?=.*[a-z])', '영문 소문자를 포함해주세요.'),
  }),
  password2: yup
    .string()
    // .required("비밀번호를 확인해주세요.")
    .equals([yup.ref('password1')], '비밀번호가 일치하지 않습니다. 다시 입력해주세요.'),
  mobile: yup.string().required('휴대폰 번호를 입력하세요.').length(11, '번호 전체를 입력해주세요'),
  // code: yup.string(),
  address: yup.string().required('거주지를 입력하세요.'),
  kind: yup.string().required('고객 타입을 선택하세요.'),
});
