import React from 'react'
import { useNavigate } from 'react-router-dom'
import PathBar from './PathBar'
import styles from './signup.module.css'
import resultIcon from '../../../@assets/signup/reult-deskIcon.svg'

function SignUp5() {
  const navigate = useNavigate()
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <PathBar />

        <div className={styles.signUp5_wrap}>
          <img src={resultIcon} alt="" />
          <h1>축하합니다!</h1>
          <h2>
            스타안심보증 가입 신청이
            <br /> 완료되었습니다.
          </h2>
          <p>승인 완료 후 회신드리겠습니다.</p>
        </div>

        <button
          type="button"
          onClick={() => navigate('/for-check/step1')}
          className={styles.singup5_btn1}
        >
          보증서비스 견적보기
        </button>
        <button
          type="button"
          onClick={() => navigate('/')}
          className={styles.singup5_btn2}
        >
          확인
        </button>
      </div>
    </section>
  )
}

export default SignUp5
