import React, { useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './data-change.module.css';
import { carInfoSchemaAfterSignUp } from '../../../config/schemaInfo';
import { setProfile } from '../../../store/user/profileSlice';
import { requestPostWithFormData } from '../../../utils/apiFunctions';
import Constants from '../../../common/Constants';

function CarDataChange() {
  const navigate = useNavigate();
  const defaultValues = useSelector(({ user }) => user.profile.data);
  const onSubmit = async () => {
    if (isDirty || contractImg || dashboardImg) {
      const response = await submitModifyInfoCar(getValues());
      if (response && response.data) {
        dispatch(setProfile(getValues()));
        alert('개인 정보 수정이 완료되었습니다.');
        navigate('/my-page', { replace: true });
      } else {
        alert('개인 정보에 실패하였습니다.');
      }
    } else {
      alert('수정된 개인 정보가 없습니다.');
    }
  };
  const dispatch = useDispatch();
  const { control, formState, handleSubmit, setError, getValues, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(carInfoSchemaAfterSignUp),
  });
  const { isValid, errors, isDirty } = formState;
  const contractImgRef = useRef(null);
  const dashboardImgRef = useRef(null);
  const [contractImg, setContractImg] = useState(null);
  const [dashboardImg, setDashboardImg] = useState(null);
  const contractImgRefClick = () => {
    contractImgRef.current.click();
  };
  const dashboardImgRefClick = () => {
    dashboardImgRef.current.click();
  };
  const submitModifyInfoCar = async (changedCarData) => {
    // console.log(defaultValues)
    let formData = new FormData();
    formData.append('id', defaultValues.carId);
    formData.append('userId', changedCarData.id);
    formData.append('plateNumber', changedCarData.plateNumber);
    formData.append('modelName', changedCarData.modelName);
    formData.append('modelYear', changedCarData.modelYear);
    formData.append('vin', changedCarData.vin);
    formData.append('purchaseDate', changedCarData.purchaseDate);
    formData.append('mileage', changedCarData.mileage);
    formData.append('contractImage', changedCarData.contractImg);
    formData.append('dashboardImage', changedCarData.dashboardImg);
    formData.append('trader', changedCarData.trader);
    formData.append('businessNumber', changedCarData.businessNumber);
    // for (let key of formData.keys()) {
    //     console.log(key);
    // }
    // for (let value of formData.values()) {
    //     console.log(value);
    // }
    return await requestPostWithFormData(Constants.API.car.modify, formData);
    // const data = await requestPostWithFormData(Constants.API.car.register, formData)
    // if (data) {
    //     console.log(data)
    //     // dispatch(saveSignUp3(null));
    //     dispatch(saveIsDoneSignUp(true))
    //     navigate("/sign-up5");
    // } else {
    // }
  };
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <h1 className={styles.title}>차량정보 수정</h1>
        <p className={styles.subTitle}>가입할 때에 입력하신 정보를 수정하세요.</p>

        {/* 폼 박스 */}
        <form className={styles.formBox} onSubmit={handleSubmit(onSubmit)}>
          {/* 차량번호 */}
          <label className={styles.label} style={{ marginTop: 0 }}>
            <p>
              차량번호 <span>(필수)</span>
            </p>
            {errors.plateNumber?.message && (
              <p className={styles.errorMsg}>{errors.carNumber?.message}</p>
            )}
          </label>
          <Controller
            name="plateNumber"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className={styles.input_field}
                placeholder="차량번호 입력 예)123가4567"
              />
            )}
          />

          {/* 차량명 */}
          <label className={styles.label}>
            <p>
              차량명 <span>(필수)</span>
            </p>
            {errors.modelName?.message && (
              <p className={styles.errorMsg}>{errors.modelName?.message}</p>
            )}
          </label>
          <Controller
            name="modelName"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className={styles.input_field}
                placeholder="차량명 입력 예)SM5"
              />
            )}
          />

          {/* 연식 */}
          <label className={styles.label}>
            <p>
              연식 <span>(필수)</span>
            </p>
            {errors.modelYear?.message && (
              <p className={styles.errorMsg}>{errors.modelYear?.message}</p>
            )}
          </label>
          <Controller
            name="modelYear"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                minLength="4"
                maxLength="4"
                inputMode="decimal"
                value={getValues('modelYear')}
                className={styles.input_field}
                placeholder="최초등록일 입력 예)20220101"
                onChange={(e) => {
                  if (e.target.value.match('^[0-9]*$')) {
                    field.onChange(e);
                  }
                }}
              />
            )}
          />

          {/* 차대번호*/}
          <label className={styles.label}>
            <p>
              차대번호 <span>(필수)</span>
            </p>
            {errors.vin?.message && <p className={styles.errorMsg}>{errors.vin?.message}</p>}
          </label>
          <Controller
            name="vin"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                maxLength="17"
                type="text"
                value={getValues('vin')}
                className={styles.input_field}
                onChange={(e) => {
                  if (e.target.value.match('^[0-9a-zA-Z]*$')) {
                    field.onChange(e);
                  }
                }}
                placeholder="차대번호를 입력하세요."
              />
            )}
          />

          {/* 구입일자*/}
          <label className={styles.label}>
            <p>
              구매일자 <span>(필수)</span>
            </p>
            {errors.purchaseDate?.message && (
              <p className={styles.errorMsg}>{errors.purchaseDate?.message}</p>
            )}
          </label>
          <Controller
            name="purchaseDate"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                inputMode="decimal"
                value={getValues('purchaseDate')}
                className={styles.input_field}
                placeholder=""
                onChange={(e) => {
                  if (e.target.value.match('^[0-9]*$') || e.target.value.match('[-]')) {
                    setValue('purchaseDate', e.target.value);
                    // field.onChange(e);
                  }
                }}
              />
            )}
          />

          {/* 계약시 주행거리*/}
          <label className={styles.label}>
            <p>
              계약시 주행거리(km) <span>(필수)</span>
            </p>
            {errors.mileage?.message && (
              <p className={styles.errorMsg}>{errors.mileage?.message}</p>
            )}
          </label>
          <Controller
            name="mileage"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                prefix="km"
                inputMode="decimal"
                value={getValues('mileage')}
                className={styles.input_field}
                placeholder="km단위로 정확히 입력하세요."
                onChange={(e) => {
                  if (e.target.value.match('^[0-9]*$')) {
                    field.onChange(e);
                  }
                }}
              />
            )}
          />

          {/* 계약서 사진 */}
          <label className={styles.label} htmlFor="button-file">
            <p>계약서 사진</p>
            {errors.contractImg?.message && (
              <p className={styles.errorMsg}>{errors.contractImg?.message}</p>
            )}
          </label>
          <div className={styles.two_field}>
            <div
              className={styles.input_field2}
              style={contractImg ? { color: '#333' } : { color: '#ccc' }}
            >
              <Controller
                name="contractImg"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <input
                    accept="image/*"
                    className="hidden"
                    id="button-file"
                    ref={contractImgRef}
                    type="file"
                    onChange={async (e) => {
                      function readFileAsync() {
                        return new Promise((resolve, reject) => {
                          const file = e.target.files[0];
                          if (!file) {
                            return;
                          }
                          const reader = new FileReader();
                          reader.onload = () => {
                            resolve(file);
                          };
                          reader.onerror = reject;
                          reader.readAsBinaryString(file);
                        });
                      }

                      const newImage = await readFileAsync();
                      onChange(newImage);
                      setContractImg(newImage);
                    }}
                  />
                )}
              />
              {contractImg ? contractImg.name : '계약서 사진을 등록하세요.'}
            </div>
            <button type="button" onClick={contractImgRefClick}>
              찾아보기
            </button>
          </div>

          {/* 계기판 사진 */}
          <label className={styles.label}>
            <p>계기판 사진</p>
            {errors.dashboardImg?.message && (
              <p className={styles.errorMsg}>{errors.dashboardImg?.message}</p>
            )}
          </label>
          <div className={styles.two_field}>
            <div
              className={styles.input_field2}
              style={dashboardImg ? { color: '#333' } : { color: '#ccc' }}
            >
              <Controller
                name="dashboardImg"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <input
                    accept="image/*"
                    className="hidden"
                    id="button-file"
                    ref={dashboardImgRef}
                    type="file"
                    onChange={async (e) => {
                      function readFileAsync() {
                        return new Promise((resolve, reject) => {
                          const file = e.target.files[0];
                          if (!file) {
                            return;
                          }
                          const reader = new FileReader();
                          reader.onload = () => {
                            resolve(file);
                          };
                          reader.onerror = reject;
                          reader.readAsBinaryString(file);
                        });
                      }

                      const newImage = await readFileAsync();
                      onChange(newImage);
                      setDashboardImg(newImage);
                    }}
                  />
                )}
              />
              {dashboardImg ? dashboardImg.name : '계기판 사진을 등록하세요.'}
            </div>
            <button type="button" onClick={dashboardImgRefClick}>
              찾아보기
            </button>
          </div>

          {/* 매매상사명*/}
          <label className={styles.label}>매매상사명</label>
          <Controller
            name="trader"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className={styles.input_field}
                placeholder="매매상사명 입력"
              />
            )}
          />

          {/* 사업자번호 */}
          <label className={styles.label}>사업자번호</label>
          <Controller
            name="businessNumber"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                inputMode="decimal"
                maxLength="10"
                value={getValues('businessNumber')}
                className={styles.input_field}
                onChange={(e) => {
                  if (e.target.value.match('^[0-9]*$')) {
                    field.onChange(e);
                  }
                }}
                placeholder="사업자번호 '-' 제외 입력"
              />
            )}
          />

          {/* 버튼 */}
          <div className={styles.btn_wrap}>
            <button type="button" className={styles.btn1} onClick={() => navigate('/my-page')}>
              취소
            </button>
            <button
              className={styles.btn2}
              style={
                isValid && (isDirty || contractImg || dashboardImg)
                  ? { backgroundColor: '#6CBED7' }
                  : { backgroundColor: '#ccc' }
              }
            >
              수정완료
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default CarDataChange;
