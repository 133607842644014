export const FAKE_USER_INFO = {
  fullName: "홍길동",
  birthDate: "19700226",
  gender: "male",
  id: "sunny123",
  password: "Qq123123!",
  password1: "Qq123123!",
  password2: "Qq123123!",
  mobile: "01000000000",
  code: "",
  email: "sunny123@sunny.com",
  address: "서울시 양천구 신정로 119",
  carNumber: "391가2222",
  modelName: "XM3",
  modelYear: "20210202",
  idNumber: "123123",
  purchaseDate: "20210503",
  mileage: "3000",
  contractImg: "",
  dashboardImg: "",
  trader: "",
  businessNumber: "",
};
