import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from './find.module.css'
import backIcon from '../../../../@assets/backIcon.svg'

function FindId() {
  const navigate = useNavigate()
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <img
          src={backIcon}
          alt=""
          className={styles.back}
          onClick={() => navigate(-1)}
        />
        <h1>아이디 찾기</h1>
        <p>
          입력하신 정보는 본인 및 회원가입여부 확인 용도로 사용되고, 이외
          목적으로 사용되거나 저장되지 않습니다. 번거롭게 느껴지시더라도 소중한
          개인정보를 보호하기 위한 절차이므로 이해 부탁드립니다.
        </p>
        <span>아래 인증수단을 이용하여 인증을 받으시기 바랍니다.</span>

        <button type="button" className={styles.send_btn}>
          <h2>휴대폰 인증을 받으려면?</h2>
          <spna>여기를 클릭하세요.</spna>
        </button>

        <div className={styles.find_password_box}>
          <h2>비밀번호가 기억나지 않으세요?</h2>
          <Link to="/find-password" className={styles.find_password_btn}>
            비밀번호 찾기
          </Link>
        </div>

        <div className={styles.info_desk}>
          <h2>꼭 알아두세요!</h2>
          {infoData.map((item, i) => {
            return (
              <li key={i}>
                <p>{item.p}</p>
              </li>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default FindId

const infoData = [
  {
    p: '고객님 명의로 등록된 인증수단과 정보를 입력해 주셔야합니다.',
  },
  {
    p: '본인인증 시 발생되는 고객님의 비용부담은 없습니다.',
  },
]
