import {createSlice} from "@reduxjs/toolkit";

const initialState = {toggleLogin: false, userMenu: false};

const toggleSlice = createSlice({
    name: "app/toggle",
    initialState,
    reducers: {
        onToggleLogin: (state, action) => {
            state.toggleLogin = !state.toggleLogin;
        },
        openUserMenu: (state, action) => {
            state.userMenu = !state.userMenu;
        },
        closeUserMenu: (state, action) => {
            state.userMenu = false;
        },
    },
});

export const {onToggleLogin, openUserMenu, closeUserMenu} =
    toggleSlice.actions;

export default toggleSlice.reducer;
