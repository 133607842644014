import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import store from "./app/store";
import {Provider} from "react-redux";
import App from "./app/App";
import {BrowserRouter} from "react-router-dom";

import {persistStore} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";

const persistor = persistStore(store);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <PersistGate loading={null} persistor={persistor}>
                    <App/>
                </PersistGate>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
