import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Constants from '../../common/Constants';
import styles from './pay.module.css';
import img1 from '../../../@assets/pay/payImg1.png';
import img2 from '../../../@assets/pay/payImg2.png';
import { numberWithCommas, getQueryValue } from '../../utils/utilityFunctions';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { requestPost } from '../../utils/apiFunctions';
import { setProfile } from '../../store/user/profileSlice';
import moment from 'moment';

function Pay() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [authorizationOrderId, setAuthorizationOrderId] = useState('');
  let profileData = useSelector(({ user }) => user.profile.data);

  // 첫 진입시 얻는 값
  const paymentAmount = (location.state && location.state.paidAmount) || '';
  const paymentOrderId = `${moment().format('yyyyMMDDHHmmSS')}_${profileData.id}`;
  const paymentOrderName = 'Star 안심보증 서비스';
  const paymentCustomerName = (location.state && location.state.fullName) || '';

  // 결제 창에서 요청후 받는 값
  const requestOrderId = getQueryValue('orderId');
  const requestKey = getQueryValue('paymentKey');
  const requestAmount = getQueryValue('amount');
  const requestErrorCode = getQueryValue('code');

  // 서버에서 승인후 받는 값
  let authorizationErrorMessage = '';

  useEffect(async () => {
    if (requestOrderId && requestKey && requestAmount) {
      await requestPaymentAuthorization(requestOrderId, requestKey, requestAmount);
    }
  }, []);

  const payHandler = async () => {
    // const clientKey = 'test_ck_4vZnjEJeQVxRQ2nBX5q8PmOoBN0k'
    const clientKey = 'live_ck_jkYG57Eba3GkQxJGLyz3pWDOxmA1';
    const tossPayments = await loadTossPayments(clientKey);
    await tossPayments.requestPayment('카드', {
      amount: paymentAmount,
      orderId: paymentOrderId,
      orderName: paymentOrderName,
      customerName: paymentCustomerName,
      successUrl: `${window.location.protocol}//${window.location.host}/pay`,
      failUrl: `${window.location.protocol}//${window.location.host}/pay`,
    });
  };

  const requestPaymentAuthorization = async (orderId, key, amount) => {
    let formData = new FormData();
    formData.append('orderId', orderId);
    formData.append('key', key);
    formData.append('amount', amount);

    const response = await requestPost(Constants.API.payment.authorization, formData);
    if (response && response.data) {
      if (response.data === orderId) {
        await savePaymentResults(response.data);
      }
    } else {
      authorizationErrorMessage = 'valid';
    }
  };

  const savePaymentResults = async (orderId) => {
    const params = {
      email: profileData.email,
      id: profileData.id,
      state: 3,
      orderId: orderId,
      modifiedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      paymentDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    };

    const response = await requestPost(Constants.API.user.modify, params);
    if (response && response.data) {
      setAuthorizationOrderId(orderId);
      profileData.state = 3;
      dispatch(setProfile(profileData));
    } else {
    }
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        {/*결제 전*/}
        {paymentAmount && !requestKey && !requestErrorCode && (
          <>
            <img src={img1} alt="" className={styles.img} />
            <h1>서비스 가입</h1>
            <p>
              Star 안심보증서비스의
              <br /> 결제를 진행하시겠습니까?
            </p>

            <div className={styles.btnBox}>
              <button className={styles.btn1} onClick={payHandler}>
                {numberWithCommas(paymentAmount)} 원을 결제하겠습니다.
              </button>
              <button className={styles.btn2} onClick={() => navigate(-1)}>
                나중에 할게요.
              </button>
            </div>
          </>
        )}
        {/*성공*/}
        {!!authorizationOrderId && (
          <>
            <img src={img2} alt="" className={styles.img} />
            <h1>결제가 완료되었습니다.</h1>
            <p>
              Star 안심보증서비스를
              <br /> 정상적으로 이용가능합니다.
            </p>

            <div className={styles.btnBox}>
              <button className={styles.btn1} onClick={() => navigate('/my-page')}>
                가입정보 확인
              </button>
              <button className={styles.btn2} onClick={() => navigate('/')}>
                메인으로 이동
              </button>
            </div>
          </>
        )}
        {/*실패*/}
        {(!!requestErrorCode || !!authorizationErrorMessage) && (
          <>
            <img src={img1} alt="" className={styles.img} />
            <h1>결제에 실패하였습니다.</h1>
            <p>
              Star 안심보증서비스의
              <br /> 결제를 다시 진행하시겠습니까?
            </p>

            <div className={styles.btnBox}>
              <button className={styles.btn1} onClick={payHandler}>
                {numberWithCommas(paymentAmount)} 원을 결제하겠습니다.
              </button>
              <button className={styles.btn2} onClick={() => navigate(-1)}>
                나중에 할게요.
              </button>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default Pay;
