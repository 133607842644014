import React, {useState} from "react";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import styles from "./data-change.module.css";
import {privateInfoSchemaInChange,} from "../../../config/schemaInfo";
import {setProfile} from "../../../store/user/profileSlice";
import {requestPost} from "../../../utils/apiFunctions";
import Constants from "../../../common/Constants";

function UserDataChange() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const defaultValues = useSelector(({user}) => user.profile.data);
    const onSubmit = async () => {
        if (isDirty) {
            const response = await submitModifyInfoUser(getValues())
            if (response && response.data) {
                dispatch(setProfile(getValues()));
                alert("개인 정보 수정이 완료되었습니다.");
                navigate("/my-page", {replace: true});
            } else {
                alert("개인 정보에 실패하였습니다.");
            }
        } else {
            alert("수정된 개인 정보가 없습니다.");
        }
    };
    const {control, formState, handleSubmit, setError, getValues, setValue} =
        useForm({
            mode: "onChange",
            defaultValues: {...defaultValues, password1: '', password2: ''},
            resolver: yupResolver(privateInfoSchemaInChange),
        });
    const {isValid, errors, isDirty, dirtyFields} = formState;

    const submitModifyInfoUser = async (changedUserData) => {
        const params = {
            id: defaultValues.id,
            email: changedUserData.email,
            password: changedUserData.password1 || undefined,
            mobile: changedUserData.mobile,
            fullName: changedUserData.fullName,
            birthDate: changedUserData.birthDate,
            gender: changedUserData.gender,
            address: changedUserData.address,
            kind: changedUserData.kind,
            marketingAgree: changedUserData.marketingAgree ? 1 : 0
        }

        return await requestPost(Constants.API.user.modify, params)
        // const response = await requestPost(Constants.API.user.register, params)
        // if (response && response.data) {
        //     // dispatch(saveSignUp3(null));
        //     signupCarData.userId = response.data.id
        //     // dispatch(submitSignupCar(signupCarData))
        //     // submitSignupCar(signupCarData)
        // } else {
        // }
    }

    return (
        <section className={styles.wrapper}>
            <div className={styles.container}>
                <h1 className={styles.title}>개인정보 수정</h1>
                <p className={styles.subTitle}>
                    가입할 때에 입력하신 정보를 수정하세요.
                </p>

                {/* 폼 박스 */}
                <form className={styles.formBox} onSubmit={handleSubmit(onSubmit)}>
                    {/* 이름 */}
                    <label className={styles.label} style={{marginTop: 0}}>
                        <p>
                            이름 <span>(필수)</span>
                        </p>
                        {errors.fullName?.message && (
                            <p className={styles.errorMsg}>{errors.fullName?.message}</p>
                        )}
                    </label>
                    <Controller
                        name="fullName"
                        control={control}
                        render={({field}) => (
                            <input
                                {...field}
                                type="text"
                                placeholder="이름 입력"
                                className={styles.input_field}
                            />
                        )}
                    />

                    {/* 생년월일 */}
                    <label className={styles.label}>
                        <p>
                            생년월일 <span>(필수)</span>
                        </p>
                        {errors.birthDate?.message && (
                            <p className={styles.errorMsg}>{errors.birthDate?.message}</p>
                        )}
                    </label>
                    <Controller
                        name="birthDate"
                        control={control}
                        render={({field}) => (
                            <input
                                {...field}
                                type="text"
                                minLength="8"
                                maxLength="8"
                                inputMode="decimal"
                                value={getValues("birthDate")}
                                className={styles.input_field}
                                placeholder="생년월일 입력 예) 20220301"
                                onChange={(e) => {
                                    if (e.target.value.match("^[0-9]*$")) {
                                        field.onChange(e);
                                    }
                                }}
                            />
                        )}
                    />
                    {/* 성별 */}
                    <label className={styles.label}>
                        <p>
                            성별 <span>(필수)</span>
                        </p>
                        {errors.gender?.message && (
                            <p className={styles.errorMsg}>{errors.gender?.message}</p>
                        )}
                    </label>
                    <div className={styles.genderBox}>
                        <Controller
                            name="gender"
                            control={control}
                            render={({field}) => (
                                <>
                                    <label htmlFor="male">
                                        <input
                                            {...field}
                                            type="radio"
                                            value="male"
                                            id="male"
                                            checked={getValues("gender") === "male"}
                                            /*     onChange={(e) => {
                                              field.onChange(e);
                                            }} */
                                        />
                                        <span>남</span>
                                    </label>
                                    <label htmlFor="female">
                                        <input
                                            {...field}
                                            type="radio"
                                            value="female"
                                            id="female"
                                            checked={getValues("gender") === "female"}
                                            /*   onChange={(e) => {
                                              field.onChange(e);
                                            }} */
                                        />
                                        <span>여</span>
                                    </label>
                                </>
                            )}
                        />
                    </div>

                    {/* 이메일 */}
                    <label className={styles.label}>
                        <p>
                            이메일 <span>(필수)</span>
                        </p>
                        {errors.email?.message && (
                            <p className={styles.errorMsg}>{errors.email?.message}</p>
                        )}
                    </label>
                    <Controller
                        name="email"
                        control={control}
                        render={({field}) => (
                            <input
                                {...field}
                                type="email"
                                placeholder="이메일 입력"
                                className={styles.input_field}
                            />
                        )}
                    />

                    {/*/!* 아이디 *!/*/}
                    {/*<label className={styles.label}>*/}
                    {/*    <p>*/}
                    {/*        아이디 <span>(필수)</span>*/}
                    {/*    </p>*/}
                    {/*    {errors.id?.message && (*/}
                    {/*        <p className={styles.errorMsg}>{errors.id?.message}</p>*/}
                    {/*    )}*/}
                    {/*</label>*/}
                    {/*<div className={styles.two_field}>*/}
                    {/*    <Controller*/}
                    {/*        name="id"*/}
                    {/*        control={control}*/}
                    {/*        render={({field}) => (*/}
                    {/*            <input*/}
                    {/*                {...field}*/}
                    {/*                type="text"*/}
                    {/*                placeholder="영문 소문자와 숫자 조합 4~12자리"*/}
                    {/*                className={styles.input_field2}*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    if (!e.target.value.match(/[^0-9a-z]/g)) field.onChange(e);*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        )}*/}
                    {/*    />*/}
                    {/*    <button*/}
                    {/*        type="button"*/}
                    {/*        disabled={!dirtyFields.id}*/}
                    {/*        onClick={(e) => {*/}
                    {/*            alert("사용 가능한 아이디입니다.");*/}
                    {/*        }}*/}
                    {/*        style={*/}
                    {/*            !dirtyFields.id*/}
                    {/*                ? {backgroundColor: "#ccc"}*/}
                    {/*                : {backgroundColor: "#6CBED7"}*/}
                    {/*        }*/}
                    {/*    >*/}
                    {/*        중복확인*/}
                    {/*    </button>*/}
                    {/*</div>*/}

                    {/* 비밀번호 */}
                    <label className={styles.label}>
                        <p>
                            비밀번호
                        </p>
                        {errors.password1?.message && (
                            <p className={styles.errorMsg}>{errors.password1?.message}</p>
                        )}
                    </label>
                    <Controller
                        name="password1"
                        control={control}
                        render={({field}) => (
                            <input
                                {...field}
                                type="password"
                                placeholder="영문 대/소문자,특수문자 8~16자로 입력"
                                className={styles.input_field}
                            />
                        )}
                    />

                    {/* 비밀번호 확인*/}
                    <label className={styles.label}>
                        <p>
                            비밀번호 확인
                        </p>
                        {errors.password2?.message && (
                            <p className={styles.errorMsg}>{errors.password2?.message}</p>
                        )}
                    </label>
                    <Controller
                        name="password2"
                        control={control}
                        render={({field}) => (
                            <input
                                {...field}
                                type="password"
                                placeholder="영문 대/소문자,특수문자 8~16자로 입력"
                                className={styles.input_field}
                            />
                        )}
                    />

                    {/* 휴대폰 번호 */}
                    <label className={styles.label}>
                        <p>
                            휴대폰번호 <span>(필수)</span>
                        </p>
                        {errors.mobile?.message && (
                            <p className={styles.errorMsg}>{errors.mobile?.message}</p>
                        )}
                    </label>
                    <div className={styles.two_field}>
                        <Controller
                            name="mobile"
                            control={control}
                            render={({field}) => (
                                <input
                                    {...field}
                                    type="text"
                                    maxLength="11"
                                    inputMode="decimal"
                                    placeholder="'-' 제외하고 숫자만 입력"
                                    className={styles.input_field2}
                                    value={getValues("mobile")}
                                    onChange={(e) => {
                                        if (e.target.value.match("^[0-9]*$")) {
                                            field.onChange(e);
                                        }
                                    }}
                                />
                            )}
                        />
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    disabled={!dirtyFields.mobile}*/}
                        {/*    onClick={(e) => {*/}
                        {/*        if (!errors.mobile?.message) {*/}
                        {/*            alert("인증 코드를 보냈습니다.");*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*    style={*/}
                        {/*        dirtyFields.mobile && !errors.mobile?.message*/}
                        {/*            ? {backgroundColor: "#6CBED7"}*/}
                        {/*            : {backgroundColor: "#ccc"}*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    인증요청*/}
                        {/*</button>*/}
                    </div>

                    {/* 인증번호 */}

                    {/*<label className={styles.label}>*/}
                    {/*    <p>인증번호</p>*/}
                    {/*    {errors.code?.message && (*/}
                    {/*        <p className={styles.errorMsg}>{errors.code?.message}</p>*/}
                    {/*    )}*/}
                    {/*</label>*/}
                    {/*<Controller*/}
                    {/*    name="code"*/}
                    {/*    control={control}*/}
                    {/*    render={({field}) => (*/}
                    {/*        <input*/}
                    {/*            {...field}*/}
                    {/*            type="text"*/}
                    {/*            maxLength="4"*/}
                    {/*            inputMode="decimal"*/}
                    {/*            placeholder="인증번호 입력"*/}
                    {/*            className={styles.input_field}*/}
                    {/*            value={getValues("code")}*/}
                    {/*            onChange={(e) => {*/}
                    {/*                if (e.target.value.match("^[0-9]*$")) {*/}
                    {/*                    field.onChange(e);*/}
                    {/*                }*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*/>*/}

                    {/* 거주지 */}
                    <label className={styles.label}>
                        <p>
                            거주지 <span>(필수)</span>
                        </p>
                        {errors.address?.message && (
                            <p className={styles.errorMsg}>{errors.address?.message}</p>
                        )}
                    </label>

                    <Controller
                        name="address"
                        control={control}
                        render={({field}) => (
                            <input
                                {...field}
                                type="text"
                                className={styles.input_field}
                                placeholder="예) 서울특별시 강남구 테헤란로 1길 11 xx빌딩 0동 000호"
                            />
                        )}
                    />

                    {/* 고객 형태 */}
                    <label className={styles.label}>
                        <p>
                            고객 타입 <span>(필수)</span>
                        </p>
                        {errors.kind?.message && (
                            <p className={styles.errorMsg}>{errors.kind?.message}</p>
                        )}
                    </label>
                    <div className={styles.genderBox}>
                        <Controller
                            name="kind"
                            control={control}
                            render={({field}) => (
                                <>
                                    <label htmlFor="person">
                                        <input
                                            {...field}
                                            type="radio"
                                            value="1"
                                            id="person"
                                            checked={parseInt(getValues("kind")) === 1}
                                            /*     onChange={(e) => {
                                              field.onChange(e);
                                            }} */
                                        />
                                        <span>개인고객</span>
                                    </label>
                                    <label htmlFor="company">
                                        <input
                                            {...field}
                                            type="radio"
                                            value="2"
                                            id="company"
                                            checked={parseInt(getValues("kind")) === 2}
                                            /*   onChange={(e) => {
                                              field.onChange(e);
                                            }} */
                                        />
                                        <span>판매상사</span>
                                    </label>
                                </>
                            )}
                        />
                    </div>

                    <div className={styles.check_box} style={{marginTop: 30}}>
                        <div className={styles.check_title}>
                            <Controller
                                name="marketingAgree"
                                control={control}
                                render={({field}) => (
                                    <>
                                        <input
                                            {...field}
                                            type="checkbox"
                                            id="marketingAgree"
                                            checked={getValues("marketingAgree")}
                                            className={styles.checkBox}
                                        />
                                        <label htmlFor="marketingAgree">
                                            마케팅 정보 수신 및 활용
                                        </label>
                                    </>
                                )}
                            />
                        </div>

                        <p className={styles.check_info}>
                            동의를 거부할 수 있습니다. 단, 미동의시 안심보증 서비스가 제공하는 혜택
                            알림을 받으실 수 없습니다.
                        </p>
                    </div>

                    {/* 버튼 */}
                    <div className={styles.btn_wrap}>
                        <button
                            type="button"
                            className={styles.btn1}
                            onClick={() => navigate(-1)}
                        >
                            취소
                        </button>
                        <button
                            type="submit"
                            className={styles.btn2}
                            style={
                                isValid
                                    ? {backgroundColor: "#6CBED7"}
                                    : {backgroundColor: "#ccc"}
                            }
                        >
                            수정완료
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default UserDataChange;
