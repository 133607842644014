/* 헤더가 필요하지 않음 */
export const NO_HEADER = [
  '/sign-up1',
  '/sign-up2',
  '/sign-up3',
  '/sign-up4',
  '/sign-up5',
  '/kbc/sign-up1',
  '/kbc/sign-up2',
  '/kbc/sign-up3',
  '/kbc/sign-up4',
  '/kbc/sign-up5',
  '/find-id',
  '/find-password',
  '/for-check',
  '/for-check/step1',
  '/for-check/step2',
  '/for-check/step3',
  '/for-check/step4',
  '/kb-outSide-pages',
  '/pay',
];

export const NO_FOOTER = [
  '/sign-up1',
  '/sign-up2',
  '/sign-up3',
  '/sign-up4',
  '/sign-up5',
  '/kbc/sign-up1',
  '/kbc/sign-up2',
  '/kbc/sign-up3',
  '/kbc/sign-up4',
  '/kbc/sign-up5',
  '/for-check',
  '/for-check/step1',
  '/for-check/step2',
  '/for-check/step3',
  '/for-check/step4',
  '/kb-outSide-pages',
  '/pay',
];
