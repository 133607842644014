import React, {useState} from "react";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {checkPassword} from "../../../store/user/profileSlice";
import styles from "./myChange.module.css";
import {passwordSchema} from "../../../config/schemaInfo";

function MyChange() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileData = useSelector(({user}) => user.profile.data);
    const [isMatched, setIsMatched] = useState(true);
    const {control, formState, handleSubmit, getValues, setValue} = useForm({
        mode: "onChange",
        defaultValues: {password: ""},
        resolver: yupResolver(passwordSchema),
    });
    const onSubmit = () => {
        let loginInfo = getValues()
        loginInfo.id = profileData.email
        dispatch(checkPassword(loginInfo)).then((val) => {
            if (val) {
                navigate("/userdata-change");
            } else {
                setIsMatched(false);
            }
        });
    };
    const {isValid, errors, isSubmitted, dirtyFields, su} = formState;
    return (
        <section className={styles.wrapper}>
            <div className={styles.container}>
                <h1 className={styles.title}>정보수정</h1>
                <p className={styles.subTitle}>
                    입력하신 정보를 수정하기 위해 비밀번호를 입력해 주세요.
                </p>

                <form className={styles.formBox} onSubmit={handleSubmit(onSubmit)}>
                    {/* 비밀번호 */}
                    <label className={styles.label}>
                        <p>비밀번호</p>
                        {errors.password?.message && (
                            <p className={styles.errorMsg}>{errors.password?.message}</p>
                        )}
                    </label>
                    <Controller
                        name="password"
                        control={control}
                        render={({field}) => (
                            <input
                                {...field}
                                type="password"
                                className={styles.input_field}
                                onChange={(e) => {
                                    field.onChange(e);
                                    // setIsMatched(true);
                                }}
                            />
                        )}
                    />
                    {!isMatched && isSubmitted && (
                        <div className={styles.errorBox}>
                            <p>다시 확인해주세요</p>
                        </div>
                    )}
                    {/* 버튼 */}
                    <div className={styles.btn_wrap}>
                        <button
                            type="button"
                            className={styles.btn1}
                            onClick={() => navigate(-1)}
                        >
                            취소
                        </button>
                        <button
                            className={styles.btn2}
                            style={
                                !isValid
                                    ? {backgroundColor: "#ccc"}
                                    : {backgroundColor: "#6CBED7"}
                            }
                        >
                            수정하기
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default MyChange;
