import React, {useEffect, useRef, useState} from "react";
import styles from "./banner.module.css";
import car1 from "../../../../@assets/banner/car1.svg";
import car2 from "../../../../@assets/banner/car2.png";
import car3 from "../../../../@assets/banner/car3.png";
import userImg from "../../../../@assets/banner/userImg.svg";

function Banner() {
    const contentB = [
        {
            img: car1,
            t1: "믿고 맡깁니다!",
            t2: '"KB가 보증하는 지정업체라\n믿고 맡길 수 있습니다."',
            t3: "홍x환 30대 G80차주",
        },
        {
            img: car2,
            t1: "극강의 가성비!",
            t2: '"큰 고장이라도 날까 걱정했는데 \n이 금액으로 안심이 됩니다."',
            t3: "김x정 20대 쏘렌토 차주",
        },
        {
            img: car3,
            t1: "빠르네요!",
            t2: '"고장 접수 7일 만에 완료되는 \n신속함에 놀랐습니다."',
            t3: "박x정 40대 530i 차주",
        },
    ];

    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () => setIndex((prev) => (prev === contentB.length - 1 ? 0 : prev + 1)),
            5000
        );

        return () => {
            resetTimeout();
        };
    }, [index]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.titleBox}>
                    <p className={styles.p1} data-aos="fade-left">
                        오랜 경험의 <span>스타오토케어</span>가 보증하는 서비스입니다.
                    </p>
                    <h1 data-aos="fade-left" data-aos-delay="300">
                        스타오토케어가 제공하는
                        <br/>최고 가성비의 보증 서비스를
                        <br/> 만나세요!
                        <span className={styles.line}/>
                    </h1>

                    <p className={styles.p2} data-aos="fade-left" data-aos-delay="500">
                        자동차 5대 주요계통
                        <br/> (엔진/미션/제동장치/조향장치/일반부품)의 보증연장 프로그램
                    </p>

                    <div className={styles.slidesDots}>
                        {contentB.map((_, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className={
                                        index === idx
                                            ? `${styles.slideshowDot} ${styles.active}`
                                            : `${styles.slideshowDot}`
                                    }
                                    onClick={() => {
                                        setIndex(idx);
                                    }}
                                ></div>
                            );
                        })}
                    </div>
                </div>

                {/* 카드박스 */}
                <div className={styles.card_wrapper}>
                    <div
                        className={styles.card_container}
                        style={{transform: `translate3d(${-index * 100}%, 0, 0)`}}
                    >
                        {contentB.map((item, i) => {
                            return (
                                <div className={styles.card_Box_wrap} key={i}>
                                    <div className={styles.card_Box}>
                                        <img
                                            src={item.img}
                                            alt=""
                                            className={styles.carImg}
                                            data-aos="zoom-in-up"
                                        />
                                        <div className={styles.b_card_wrapper} data-aos="zoom-in">
                                            <div className={styles.b_card_container}>
                                                <img src={userImg} alt="" className={styles.cardImg}/>
                                                <div className={styles.card_doc}>
                                                    <h1>{item.t1}</h1>
                                                    <p>{item.t2}</p>
                                                    <span>{item.t3}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                {/* // */}
            </div>
        </div>
    );
}

export default Banner;
