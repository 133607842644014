import React, {useCallback, useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import styles from "./assurance-info.module.css";
import linkIcon from "../../../../@assets/myPage/linkIcon.svg";
import n1Icon from "../../../../@assets/myPage/n1.svg";
import n2Icon from "../../../../@assets/myPage/n2.svg";
import n3Icon from "../../../../@assets/myPage/n3.svg";
import n4Icon from "../../../../@assets/myPage/n4.svg";
import n5Icon from "../../../../@assets/myPage/n5.svg";

function AssuranceInfo() {
    const textRef = useRef();
    const [textVal, setTextVal] = useState("");
    const navigate = useNavigate();

    const handleResizeHeight = useCallback((e) => {
        if (textRef === null || textRef.current === null) {
            return;
        }
        textRef.current.style.height = "50px";
        textRef.current.style.height = textRef.current.scrollHeight + "px";
        setTextVal(e.target.value);
    }, []);

    const submitHandler = () => {
        alert("보증수리 접수가 완료되었습니다.");
        navigate("/my-page");
    };

    return (
        <section className={styles.wrapper}>
            <div className={styles.container}>
                <h1 className={styles.title}>보증수리 접수</h1>
                <p className={styles.subTitle}>
                    아래 가입정보로 보증수리를 접수합니다.
                </p>
                <Link to="#" className={styles.titleLink}>
                    보장범위 자세히 보기 <img src={linkIcon} alt=""/>
                </Link>

                {/* 보증 정보 값 */}
                <ul className={styles.valBox1}>
                    {userData.dataBox1.map((item, i) => {
                        return (
                            <li key={i}>
                                <p>{item.key}</p>
                                <h1>
                                    {item.val} {item.spanVal && <span>({item.spanVal})</span>}
                                </h1>
                            </li>
                        );
                    })}
                </ul>

                {/* 보증 서비스 절차 */}
                <h2 className={styles.sec_title}>
                    보증서비스는
                    <br/>
                    아래 절차로 이루어 집니다.
                </h2>

                <ul className={styles.sec2_wrap}>
                    {userData.dataBox2.map((item, i) => {
                        return (
                            <li
                                key={i}
                                data-aos="zoom-in-up"
                                data-aos-duration="1200"
                                data-aos-delay={item.delay}
                            >
                                <img src={item.img} alt=""/>
                                <p>
                                    {item.p1}&nbsp;
                                    <span>{item.p2}</span>
                                </p>
                            </li>
                        );
                    })}
                </ul>

                {/* 보증 서비스 부품 */}
                <h2 className={styles.sec_title}>
                    보증 서비스 받을 부품을 선택해 주세요.
                </h2>
                <p className={styles.sec3_subTitle}>(복수선택 가능)</p>
                <ul className={styles.check_wrap}>
                    {userData.dataBox3.map((item, i) => {
                        return (
                            <li key={i}>
                                <input
                                    type="checkbox"
                                    id={item.label}
                                    className={styles.checkBox}
                                />
                                <label htmlFor={item.label}>{item.title}</label>
                            </li>
                        );
                    })}
                </ul>

                {/* 보증 서비스 부품 */}
                <h2 className={styles.sec_title}>고장 증상을 상세히 입력해 주세요.</h2>
                <p className={styles.subTitle} style={{fontSize: 16}}>
                    접수하신 후 24시간 이내에 콜센터에서 연락드린후 절차를 안내해
                    드립니다.
                </p>
                <p className={styles.sec4_subTitle}>대표 콜센터 1661-0850</p>
                <textarea
                    rows={1}
                    className={styles.input_field}
                    placeholder="고장 증상을 입력하세요."
                    ref={textRef}
                    value={textVal}
                    onChange={handleResizeHeight}
                />

                {/* 버튼 */}
                <div className={styles.btn_wrap}>
                    <button className={styles.btn1} onClick={() => navigate("/my-page")}>
                        취소
                    </button>
                    <button
                        type="submit"
                        className={styles.btn2}
                        disabled={!textVal}
                        style={
                            textVal
                                ? {backgroundColor: "#6cbed7"}
                                : {backgroundColor: "#ccc"}
                        }
                        onClick={submitHandler}
                    >
                        접수하기
                    </button>
                </div>
            </div>
        </section>
    );
}

export default AssuranceInfo;

const userData = {
    dataBox1: [
        {key: "보장기간", val: "6개월", spanVal: "1만5천km"},
        {key: "보장한도", val: "2백만원"},
        {key: "자기부담금", val: "1십만원"},
    ],
    dataBox2: [
        {img: n1Icon, p2: "고장발생 유무"},
        {
            img: n2Icon,
            p1: "콜센터 (1661-0850)",
            p2: "고장내용 접수",
            delay: "200",
        },
        {
            img: n3Icon,
            p1: "서비스 가입내역 및 보증범위 확인",
            p2: "정비제휴업체 안내",
            delay: "400",
        },
        {
            img: n4Icon,
            p1: "상담원의 안내에 따라",
            p2: "지정정비 제휴정비업체 입고",
            delay: "600",
        },
        {
            img: n5Icon,
            p1: "고장진단 후",
            p2: "수리진행",
            delay: "800",
        },
    ],
    dataBox3: [
        {id: 1, title: "엔진", label: "check1"},
        {id: 2, title: "미션", label: "check2"},
        {id: 3, title: "제동장치", label: "check3"},
        {id: 4, title: "조향장치", label: "check4"},
        {id: 5, title: "일바부품", label: "check5"},
        {id: 6, title: "기타", label: "check6"},
    ],
};
