import { createSlice } from '@reduxjs/toolkit';
import { requestPost, requestPostWithFormData } from '../../utils/apiFunctions';
import Constants from '../../common/Constants';
import { onToggleLogin } from '../app/toggleSlice';
import { setProfile } from '../user/profileSlice';
import { FAKE_USER_INFO } from '../../config/profileInfo';
import { loginSuccess } from './loginSlice';
import { useNavigate } from 'react-router-dom';

export const submitSignup2 = (signupUserData, signupCarData) => async (dispatch) => {
  const params = {
    email: signupUserData.email,
    password: signupUserData.password1,
    mobile: signupUserData.mobile,
    name: signupUserData.fullName,
    birthDate: signupUserData.birthDate,
    gender: signupUserData.gender,
    address: signupUserData.address,
  };

  const response = await requestPost(Constants.API.user.register, params);
  if (response && response.data) {
    signupCarData.userId = response.data.id;
    dispatch(submitSignupCar2(signupCarData));
  } else {
  }
};

export const submitSignupCar2 = (signupCarData) => async (dispatch) => {
  let formData = new FormData();
  formData.append('userId', signupCarData.userId);
  formData.append('plateNumber', signupCarData.carNumber);
  formData.append('modelName', signupCarData.modelName);
  formData.append('modelYear', signupCarData.modelYear);
  formData.append('vin', signupCarData.idNumber);
  formData.append('purchaseDate', signupCarData.purchaseDate);
  formData.append('mileage', signupCarData.mileage);
  formData.append('contractImage', signupCarData.contractImg);
  formData.append('dashboardImage', signupCarData.dashboardImg);
  formData.append('trader', signupCarData.trader);
  formData.append('businessNumber', signupCarData.businessNumber);
  // for (let key of formData.keys()) {
  //     console.log(key);
  // }
  // for (let value of formData.values()) {
  //     console.log(value);
  // }
  const data = await requestPostWithFormData(Constants.API.car.register, formData);
  if (data) {
    // console.log(data)
    // dispatch(saveSignUp3(null));
    // const navigate = useNavigate();
    dispatch(saveIsDoneSignUp(true));
    // navigate("/sign-up5");
  } else {
  }
};

const initialState = {
  userData: {
    fullName: '',
    birthDate: '',
    gender: '',
    password1: '',
    password2: '',
    mobile: '',
    // code: "",
    email: '',
    address: '',
  },
  carData: {
    carId: '',
    promotionPackage: '',
    plateNumber: '',
    modelName: '',
    modelYear: '',
    vin: '',
    purchaseDate: '',
    mileage: '',
    contractImg: '',
    dashboardImg: '',
    trader: '',
    businessNumber: '',
  },
};

const signUpSlice = createSlice({
  name: 'auth/signUp',
  initialState,
  reducers: {
    saveUserData: (state, action) => {
      state.userData = action.payload;
    },
    saveCarData: (state, action) => {
      state.carData = action.payload;
    },
    saveIsDoneSignUp: (state, action) => {
      state.doneSignUp = action.payload;
    },
  },
  extraReducers: {},
});

export const { saveUserData, saveCarData, saveIsDoneSignUp } = signUpSlice.actions;

export default signUpSlice.reducer;
