import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import styles from './app.module.css';
import Home from './pages/home/Home';
import SignUp1 from './pages/sign-up/SignUp1';
import SignUp2 from './pages/sign-up/SignUp2';
import SignUp3 from './pages/sign-up/SignUp3';
import SignUp4 from './pages/sign-up/SignUp4';
import SignUp5 from './pages/sign-up/SignUp5';
import SignUpKbc1 from './pages/sign-up-kbc/SignUp1';
import SignUpKbc2 from './pages/sign-up-kbc/SignUp2';
import SignUpKbc3 from './pages/sign-up-kbc/SignUp3';
import SignUpKbc4 from './pages/sign-up-kbc/SignUp4';
import SignUpKbc5 from './pages/sign-up-kbc/SignUp5';
import ScrollToToap from '../module/ScrollToToap';
import Footer from './common/Footer';
import Header from './common/Header';
import Aos from 'aos';
import 'aos/dist/aos.css';
import FindId from './pages/login/find/FindId';
import FindPassword from './pages/login/find/FindPassword';
import ServiceIntro from './pages/introduce/ServiceIntro';
import Qna from './pages/qna/Qna';
import Q1 from './pages/qna/p/Q1';
import Q2 from './pages/qna/p/Q2';
import Q3 from './pages/qna/p/Q3';
import Q4 from './pages/qna/p/Q4';
import Info from './pages/info-desk/Info';
import Announce1 from './pages/announce/Announce1';
import Announce2 from './pages/announce/Announce2';
import Announce3 from './pages/announce/Announce3';
import ForCheck from './pages/for-check/ForCheck';
import CheckStep1 from './pages/for-check/step/CheckStep1';
import CheckStep2 from './pages/for-check/step/CheckStep2';
import CheckStep3 from './pages/for-check/step/CheckStep3';
import CheckStep4 from './pages/for-check/step/CheckStep4';
import OutSideCheckPage from './pages/for-check/step/OutSideCheckPage';
import Pay from './pages/pay/Pay';
import MyPage from './pages/my-page/MyPage';
import MyChange from './pages/my-page/my-change/MyChange';
import UserDataChange from './pages/my-page/data-change/UserDataChange';
import CarDataChange from './pages/my-page/data-change/CarDataChange';
import AssuranceInfo from './pages/my-page/assurance-desk/AssuranceInfo';
import Assurance from './pages/my-page/assurance-desk/Assurance';
import { NoPageFound } from './pages/404/NoPageFound';

function App() {
  useEffect(() => {
    Aos.init({ duration: 1200, easing: 'ease-in-out' });
  }, []); //단일 애니메어션 통제

  return (
    <div className={styles.wrapper}>
      <Header />
      <main className={styles.main}>
        <ScrollToToap />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* 아이디 및 비번 찾기 */}
          <Route path="find-id" element={<FindId />} />
          <Route path="find-password" element={<FindPassword />} />

          {/* 회원가입 필드 */}

          <Route path="sign-up1" element={<SignUp1 />} />
          <Route path="sign-up2" element={<SignUp2 />} />
          <Route path="sign-up3" element={<SignUp3 />} />
          <Route path="sign-up4" element={<SignUp4 />} />
          <Route path="sign-up5" element={<SignUp5 />} />

          {/* KBC 회원가입 필드 */}

          <Route path="kbc/*">
            <Route path="sign-up1" element={<SignUpKbc1 />} />
            <Route path="sign-up2" element={<SignUpKbc2 />} />
            <Route path="sign-up3" element={<SignUpKbc3 />} />
            <Route path="sign-up4" element={<SignUpKbc4 />} />
            <Route path="sign-up5" element={<SignUpKbc5 />} />
          </Route>

          {/* 푸터 부가 페이지 */}
          <Route path="service-info" element={<ServiceIntro />} />
          <Route path="qna/*" element={<Qna />}>
            <Route path="q1" element={<Q1 />} />
            <Route path="q2" element={<Q2 />} />
            <Route path="q3" element={<Q3 />} />
            <Route path="q4" element={<Q4 />} />
          </Route>
          <Route path="info" element={<Info />} />
          <Route path="announce1" element={<Announce1 />} />
          <Route path="announce2" element={<Announce2 />} />
          <Route path="announce3" element={<Announce3 />} />

          {/* 견적보기 */}
          <Route path="for-check/*" element={<ForCheck />}>
            <Route path="step1" element={<CheckStep1 />} />
            <Route path="step2" element={<CheckStep2 />} />
            <Route path="step3" element={<CheckStep3 />} />
            <Route path="step4" element={<CheckStep4 />} />
          </Route>

          {/* 견적보기 외부 접근 페이지 */}
          <Route path="kb-outSide-page" element={<OutSideCheckPage />} />

          {/* 결제 페이지 */}
          <Route path="pay" element={<Pay />} />

          {/* 내정보 페이지 */}
          <Route path="my-page" element={<MyPage />} />
          {/* 정보 수정 */}
          <Route path="my-change" element={<MyChange />} />
          {/* 개인정보 수정 */}
          <Route path="userdata-change" element={<UserDataChange />} />
          {/* 차량정보 수정 */}
          <Route path="cardata-change" element={<CarDataChange />} />
          {/* 보증수리 접수 */}
          <Route path="assurance-info" element={<AssuranceInfo />} />
          {/* 보증서 */}
          <Route path="assurance" element={<Assurance />} />
          <Route path="*" element={<NoPageFound />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
