import React from 'react'
import {Link} from 'react-router-dom'
import styles from '../forCheck.module.css'
import {getQueryValue} from "../../../utils/utilityFunctions";

function CheckStep3() {
    const carFrom = getQueryValue('from')
    const carType = getQueryValue('type')
    const mileage = getQueryValue('mileage')
    return (
        <div className={styles.step_wrap}>
            <h2>
                <span>STEP3</span>
                <br/>
                보상한도를 선택하세요.
            </h2>

            <div className={styles.step3_type_wrap}>
                <Link to={`/for-check/step4?from=${carFrom}&type=${carType}&mileage=${mileage}`} className={ carFrom === '국산차' ? styles.type_abled : styles.type_disabled }>
                    보상한도 3백만원
                    <span>자기부담금 건당 10만원</span>
                </Link>

                <Link to={`/for-check/step4?from=${carFrom}&type=${carType}&mileage=${mileage}`} className={ carFrom === '수입차' ? styles.type_abled : styles.type_disabled }>
                    보상한도 5백만원
                    <span>자기부담금 건당 20만원</span>
                </Link>
            </div>
        </div>
    )
}

export default CheckStep3
