import React from 'react';
import styles from './announce.module.css';

function Announce1() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <h1>이용약관</h1>
        <p>
          스타오토케어 주식회사의 “스타연장보증” 웹사이트의 이용과 관련 한 제반 서비스의 이용에 대한
          필요한 사항을 규정합니다. <br /> <br />
          제1조 ( 목 적 ) 이 약관은 스타오토케어 주식회사가 운영하는 스타연장보증
          웹사이트(https://www.starautoews.co.kr, 이하 “스타연장보증”라 한다)에서 제공하는 인터넷
          관련 서비스 및 기타 부대서비스의 이용조건 및 절차에 관한 기본적인 사항을 정함을 목적으로
          합니다.
          <br />
          <br />
          제2조 ( 정 의 ) 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <br />
          1. "스타연장보증"이란 스타오토케어 주식회사가 운영하는
          온라인사이트(https://www.starautoews.co.kr)로서 중고자동차 및 관련 제반 상품 서비스를
          제공하는 사이트를 의미합니다.
          <br />
          2. "서비스"라 함은 구현되는 단말기(PC, 휴대형 단말기 등의 각종 유무선 장치)와 상관 없이
          “이용자”가 이용할 수 있는 "스타연장보증"이 제공하는 재화의 공급 및 용역이행을 말합니다.
          <br />
          3. "이용자"란 "스타연장보증"에 접속하여 이 약관에 따라 "스타연장보증"이 제공하는 서비스를
          받는 회원 및 비회원을 말합니다.
          <br />
          4. "회원"이라 함은 "스타연장보증"에 개인정보를 제공하여 회원등록을 한 자로서,
          "스타연장보증"의 정보를 지속적으로 제공받으며, "스타연장보증"이 제공하는 서비스를
          계속적으로 이용할 수 있는 자를 말합니다.
          <br />
          5. "비회원"이라 함은 회원에 가입하지 않고 "스타연장보증"이 제공하는 서비스를 이용하는 자를
          말합니다.
          <br />
          6. "쿠폰"이라 함은 회원에게 “스타연장보증”에서 제공되는 유료 “서비스” 결제 시 일정금액
          또는 일정비율을 할인 받을 수 있는 사이버 쿠폰을 말합니다.
          <br />
          7. “포인트”라 함은 회원이 “스타연장보증”에서 제공되는 유료 “서비스” 결제 시 현금처럼
          사용할 수 있는 회사 전용의 사이버 화폐를 말합니다.
          <br />
          <br />
          제3조 ( 약관의 명시와 개정 )
          <br />
          1. 스타오토케어 주식회사는 “스타연장보증” 약관의 내용과 상호, 영업소 소재지, 대표자의
          성명, 연락처(전화, 팩스, 전자우편 주소등) 등을 이용자가 알 수 있도록 스타연장보증의
          초기화면에 게시합니다.
          <br />
          2. 스타오토케어 주식회사는 “약관의규제에관한법률”, “전자거래기본법”, “전자서명법”,
          “정보통신망이용촉진등에관한법률”, “방문판매등에관한법률”, “소비자보호법” 등 관련법을
          위배하지 않는 범위에서 약관을 개정할 수 있습니다.
          <br />
          3. 스타오토케어 주식회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
          현행약관과 함께 스타연장보증의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지
          공지합니다. 다만, 회원에게 불리한 약관 개정의 경우 공지 외에 일정기간 서비스내 전자우편,
          전자쪽지, 로그인시 동의창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.
          <br />
          4. 스타오토케어 주식회사 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 7일 기간
          내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는
          통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에
          동의한 것으로 봅니다.
          <br />
          5. 스타오토케어 주식회사는 회원이 개정약관의 적용에 동의하지 않는 경우 개정 약관의 내용을
          적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할
          수 없는 특별한 사정이 있는 경우에는 스타오토케어 주식회사는 이용계약을 해지할 수 있습니다.
          <br />
          6. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한
          “전자거래소비자보호지침” 및 관계법령 또는 상관례에 따릅니다.
          <br />
          <br />
          제4조 ( 주의사항 )
          <br />
          1. 본 상품은 단순변심에 의한 취소 및 중도해지가 불가합니다.
          <br />
          2. 상품의 가입은 당사의 가입 기준 조건에 따릅니다. (안심보증상품가입조건에 대한 증적자료는
          소비자가 제공합니다.)
          <br />
          3. 프로모션 패키지중 1.(방문점검)은 국산차에 한하여 제공합니다.
          <br />
          4. 가입시 소비자가 제공한 정보의 허위사실 확인될 경우 서비스 제공이 불가합니다.
        </p>
      </div>
    </section>
  );
}

export default Announce1;
