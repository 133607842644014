import Constants from '../common/Constants';
import axios from '../common/Axios';

export const requestPost = async (url, params) => {
  try {
    const response = await axios.post(url, params);
    return response.data;
  } catch (error) {
    return (
      error.response?.data || {
        success: false,
        code: -9999,
        msg: '알수 없는 오류가 발생하였습니다.',
      }
    );
  }
};

export const requestPostWithFormData = async (url, formData) => {
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    return (
      error.response?.data || {
        success: false,
        code: -9999,
        msg: '알수 없는 오류가 발생하였습니다.',
      }
    );
  }
};
