import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import styles from "./find.module.css";
import backIcon from "../../../../@assets/backIcon.svg";
import { useNavigate } from "react-router-dom";
import { findPasswordSchema } from "../../../config/schemaInfo";
import { submitFindPassword } from "../../../store/auth/findSlice";

function FindPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control,
    formState,
    handleSubmit,
    resetField,
    getValues,
    reset,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(findPasswordSchema),
  });
  const { isValid, errors, isDirty, dirtyFields } = formState;
  const error = useSelector(({ auth }) => auth.find.error);
  const onSubmit = () => {
    if (dirtyFields.id && dirtyFields.fullName) {
      dispatch(submitFindPassword(getValues()));
    } else {
    }
  };
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <img
          src={backIcon}
          alt=""
          className={styles.back}
          onClick={() => navigate(-1)}
        />
        <h1>비밀번호 찾기</h1>

        <p>
          입력하신 정보는 본인 및 회원가입여부 확인 용도로 사용되고, 이외
          목적으로 사용되거나 저장되지 않습니다. 번거롭게 느껴지시더라도 소중한
          개인정보를 보호하기 위한 절차이므로 이해 부탁드립니다.
        </p>
        <span>아이디와 이름 입력 후 확인 버튼을 클릭해주세요.</span>
        <form className={styles.formBox} onSubmit={handleSubmit(onSubmit)}>
          <label className={styles.label}>아이디</label>
          <Controller
            name="id"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                placeholder="아이디를 입력하세요."
                className={styles.input_field}
              />
            )}
          />
          <label className={styles.label}>이름</label>
          <Controller
            name="fullName"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                placeholder="이름을 입력하세요."
                className={styles.input_field}
              />
            )}
          />
          {/* 에러메시지 */}
          {(errors.id || errors.fullName || error) && (
            <div className={styles.errorBox}>
              <p>다시 확인해주세요</p>
            </div>
          )}

          <button
            type="submit"
            style={
              dirtyFields.id && dirtyFields.fullName
                ? { backgroundColor: "#5caec7" }
                : { backgroundColor: "#ccc" }
            }
            onClick={() => {
              navigate("#");
            }}
          >
            비밀번호 찾기
          </button>
        </form>

        <div className={styles.info_desk}>
          <h2>꼭 알아두세요!</h2>
          {infoData.map((item, i) => {
            return (
              <li key={i}>
                <p>{item.p}</p>
              </li>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default FindPassword;

const infoData = [
  {
    p: "고객님 명의로 등록된 인증수단과 정보를 입력해 주셔야합니다.",
  },
  {
    p: "본인인증 시 발생되는 고객님의 비용부담은 없습니다.",
  },
  {
    p: "본인인증에 관한 문의는 NICE 신용평가정보 고객지원센터 (1600-1522)로 연락 주시기 바랍니다.",
  },
  {
    p: "1년간 로그인 하지 않은 휴먼 회원은 개인정보 보호를 위해 자동탈퇴되오니 신규 가입해주세요. (기존 ID 사용불가)",
  },
];
